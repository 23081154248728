.select {
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  &.selected {
    .title {
      color: var(--main-text);
    }
  }

  &:hover {
    .title {
      color: var(--main-text);
    }
  }
}

.title {
  display: flex;
  padding: 0 10px 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 102.9%;
  color: var(--transparent-color);
  cursor: pointer;
}

.infoBtn {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.popup {
  max-width: 300px;
}

.popup__wrapper {
  display: grid;
  &:hover {
    color: var(--primary-pink);
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  top: 170%;
  right: 0;
  width: 250px;
  z-index: 111;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;

  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .descriptionBlock {
    top: 150%;
  }
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 18px;
  }

  .descriptionBlock {
    width: 285px;
  }
}

.label {
  @media (max-width: 576px) {
    font-size: 15px;
  }
}
