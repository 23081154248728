.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
}

.info_block_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 16px;
}

.info_block_item {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  &.editable {
    transition: linear 0.1s;
    border-radius: 16px;

    .item_info {
      max-width: 49%;
    }

    &:hover {
      background: var(--primary-light-purple);
      padding: 6px 16px;
      margin: 0 -16px;

      .item_name {
        opacity: 1;
      }

      .item_info {
        color: var(--primary-purple);
      }

      .edit_btn {
        color: var(--primary-purple);
      }
    }
  }

  .item_name {
    line-height: 113%;
    letter-spacing: -0.64px;
    color: var(--primary-dark);
    opacity: 0.5;

    width: 49%;
    margin-bottom: unset;

    word-break: break-word;
  }

  .item_info {
    display: flex;
    align-items: center;

    line-height: 113%;
    letter-spacing: -0.64px;

    gap: 8px;

    img {
      width: 20px;
      height: auto;
    }

    &.price_info {
      gap: 0;
      flex-wrap: wrap;
    }

    .autoprolong_info {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0 16px;
      flex-wrap: wrap;
    }
  }
}
