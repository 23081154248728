.step {
  margin-bottom: 20px;
  &_header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 24px 12px 12px;
    gap: 10px;
    border-radius: 24px;
    background-color: var(--primary-white);
    color: var(--primary-dark);
    font-weight: 500;
    text-align: left;

    &:disabled,
    &__completed {
      pointer-events: none;
      color: var(--transparent-color);
      .step {
        &_number {
          border-color: var(--transparent-color);
        }
        &_shevron {
          stroke: var(--transparent-color);
        }
      }
    }
    @media (min-width: 768px) {
      gap: 20px;
    }
  }
  &_icon {
    margin-left: auto;
  }
  &_shevron {
    stroke: var(--main-text);
    transform: rotate(180deg);
    width: 23px;
    height: 12px;
    .opened & {
      transform: none;
    }
  }
  &_number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--main-text);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    font-weight: 500;
  }
  &_body {
    display: none;
    flex-direction: column;
    margin-top: 20px;
    .opened & {
      display: flex;
    }
    &__content {
      margin-bottom: 20px;
    }
    @media (min-width: 768px) {
      padding: 0 20px;
      border-left: 2px solid var(--primary-pink);
    }
  }
  &_next {
    align-self: flex-end;
    margin-bottom: 20px;
  }
  &_loader {
    margin: 0;
    width: 30px;
    div {
      width: 7px;
      height: 7px;
    }
  }
}
