@import '@scss/placeholders.scss';

.pageItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  .servperpage {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: var(--main-text);
  }
}

.input {
  width: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--main-text);
  background-color: transparent !important;
  padding: 6px 10px !important;
  text-align: center;
  line-height: 1;
}

.arrow_left {
  transform: rotate(90deg);
}
.arrow_right {
  transform: rotate(-90deg);
}

.paginationContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  list-style-type: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);

  .totalPages {
    padding: 0 8px;
  }
}

// .input {
//   padding: 11px 15px;
//   border: none;
//   max-width: 55px;
//   text-align: center;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 18px;
//   outline: none;
//   line-height: 70.9%;
//   max-height: 34px;
//   color: var(--main-text);
//   background: none;
// }

.blockPagination {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  @media (min-width: 400px) {
    flex-direction: row;
  }
}

.total {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
}

.btn_desktop,
.btn_mobile {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70.9%;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 20px;
}

.btn_desktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .pageItemContainer {
    .servperpage {
      font-size: 16px;
    }
  }

  .total {
    margin-bottom: unset;
  }

  .btn_mobile {
    display: none;
  }
  .btn_desktop {
    margin-top: 0;
    display: block;
  }
}

.disabled {
  pointer-events: none;
}
