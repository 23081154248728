@import '@scss/typography.scss';

.container {
  display: flex;
  gap: 4px;
  width: fit-content;
  align-items: center;
  text-align: start;
  max-width: 500px;

  border-radius: 16px;
  padding: 4px 10px 4px 6px;

  @media screen and (min-width: 1024px) {
    padding: 8px;
    gap: 6px;

    &.column {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.iconButtonWrap {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: start;

  @media screen and (min-width: 1024px) {
    gap: 6px;

    &.column {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 4px;

  &.withCrossBtn {
    width: 100%;
  }
}

.wrapBtn:hover .container,
.iconButtonWrap:hover {
  text-decoration: underline;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.closeButton {
  place-self: flex-start;
  opacity: 0.5;
  margin-left: auto;
  margin-top: 2px;

  &:hover {
    opacity: 1;
  }
}

.error {
  color: var(--system-red-dark);
  background-color: var(--system-red-light);
}

.success {
  color: var(--system-green-dark);
  background-color: var(--system-green-light);
}

.warning {
  color: var(--system-orange-dark);
  background-color: var(--system-orange-light);
}

.info {
  color: var(--system-dark-blue);
  background-color: var(--system-light-blue);
}

.notes {
  color: var(--other-dark-50);
  background-color: var(--other-dark-5);
  padding: 16px;

  & .title {
    color: var(--primary-dark);

    & > p {
      @extend .body_s_medium;
    }
  }
}
