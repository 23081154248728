.modal {
  max-width: 815px;
}

.qrInstruction {
  display: flex;
  flex-direction: column;
  position: relative;
}

.instruction {
  position: relative;
  height: 94px;
  overflow-y: hidden;
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgb(0, 0, 0) 40%);

  &.showMore {
    height: auto;
    mask-image: unset;
  }

  @media screen and (min-width: 768px) {
    mask-image: unset;
  }
}

.readMore {
  margin-top: 15px;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.important {
  color: var(--system-error);
  margin-top: 10px;
}

.qrBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
}

.qrImage {
  width: 144px;
  height: 144px;
  display: none;
}

.qrCodeBtn {
  @media screen and (max-width: 767px) {
    margin-left: 20px;
  }
}

.fieldsRow,
.timeRow {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  max-width: 260px;
}

.saveBtn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .instruction {
    padding-right: 15px;
    height: auto;
  }

  .qrInstruction {
    flex-direction: row;
  }

  .saveBtn {
    width: 240px;
  }

  .qrImage {
    display: block;
  }
}
