.services_list {
  display: flex;
}

.services_swiper_wrapper {
  position: relative;
}

.services_swiper {
  padding-top: 15px;
  width: 100vw;
  position: absolute;
  left: -30px;
  top: 0;
}

.swiper-wrapper {
  width: 100%;
  padding-bottom: 55px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 237px;
  height: 289px;
  margin-left: 30px;

  &:last-child {
    margin-right: 30px;
  }
}

.swiper-pagination.swiper-pagination-bullets {
  left: calc(50%);
  transform: translate(-50%);
  width: 150px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: var(--carousel-dots-elements-bgc);
  opacity: 1;

  &.swiper-pagination-bullet-active {
    background: var(--accent-gradient);
  }
}

.swiper-wrapper {
  & .swiper-slide:nth-child(1) {
    margin-left: 30px;
  }
}

@media screen and (min-width: 768px) {
  .services_list {
    position: static;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(306px, 306px));
    gap: 30px;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .services_list {
    justify-content: start;
  }
}

@media screen and (min-width: 1550px) {
  .services_list {
    gap: 40px;
    grid-template-columns: repeat(3, 339px);
  }
}
