.modal {
  max-width: 500px;
}

.body {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .submit_btn {
    width: 200px;
  }
}
