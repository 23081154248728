@import '@scss/placeholders.scss';

.field_wrapper {
  width: 100%;
  position: relative;
  height: fit-content;
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 5px;
}

.input_wrapper {
  position: relative;
  width: 100%;
  height: 47px;
}

.checkBox {
  border: 1px solid var(--transparent-color);
  box-sizing: border-box;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  margin-right: 12px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  &.selected {
    border-color: var(--main-text);
  }
}

.input {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 3px;
  padding: 6px 40px 6px 16px;
  caret-color: transparent;
  cursor: pointer;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: #e72690;
  }

  span {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.checkImg {
  path {
    stroke: var(--main-text);
  }
}

.selected_item {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  padding: 0 5px;
  background-color: var(--select_option_bgc);
  border-radius: 3px;
  height: fit-content;

  span {
    max-width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--main-text);
    margin-right: 6px;
  }
}

.cross {
  flex: none;
  fill: var(--transparent-color);
}

.placeholder {
  color: #ccc7d2;
}

.right_icon {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  stroke: var(--main-text);
  transform: translateY(-50%) rotateZ(180deg);
  transition: transform 0.15s;

  &.opened {
    transform: translateY(-50%) rotate(0);
  }
}
.shadow {
  border: none;
  box-shadow: var(--forms_shadow);
}

.dropdown {
  position: absolute;
  display: none;
  top: calc(100% + 4px);
  width: 100%;
  padding-top: 12px;
  height: fit-content;
  border-radius: 3px;
  z-index: 1;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--nearby-elements-bgc);

  &.opened {
    display: block;
    z-index: 9;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 15px;
    background-image: linear-gradient(
      0,
      var(--nearby-elements-bgc) 0%,
      rgba(255, 255, 255, 0.001%)
    );
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
  padding-left: 16px;
  padding-bottom: 12px;
  max-height: 260px;

  &::-webkit-scrollbar {
    width: 27px;
  }

  &::-webkit-scrollbar-track {
    border-top: 10px transparent solid;
    border-bottom: 10px transparent solid;
    border-right: 24px transparent solid;
    background-clip: padding-box;
    background-color: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    min-height: 35px;
    border-top: 10px transparent solid;
    border-bottom: 10px transparent solid;
    border-right: 24px transparent solid;
    background-clip: padding-box;
    background-color: #e72690;
  }
}

.list_item {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.name {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-text);
  &:hover {
    font-weight: 600;
  }
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .input {
    &.iconLeft {
      padding-left: 36px;
    }
    &.iconLeft {
      padding-right: 36px;
    }
  }
}

@media screen and (min-width: 1281px) {
  .input_wrapper {
    height: 52px;
  }
}
