.description_title {
  font-weight: 600;
  font-size: 18px;
}

.btn_more {
  margin-bottom: 10px;
  font-size: 18px;
  border-bottom: 1px solid;
}

.description_wrapper {
  position: relative;
  height: 80px;
  overflow: hidden;
  padding-top: 16px;
  transition: height 0.15s;
}

.paragraph {
  font-size: 16px;
  font-weight: 300;

  &:first-child {
    margin-bottom: 10px;
  }
}

.tablet_wrapper {
  margin-bottom: 15px;
}

.percent {
  flex: none;
  font-weight: 900;
  font-size: 22px;
  background: var(--accent-gradient);
  margin-top: 10px;

  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.percents_item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividing_line);
  }
}

.percents_categories {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.link_title {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 18px;
}

.label {
  display: block;
  margin-left: 15px;
  margin-bottom: 7px;
  font-weight: 400;
  font-size: 14px;
}

.copy_field {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: var(--forms3_shadow);
  background-color: var(--forms_bgc_1);
  cursor: initial;

  &.selected {
    cursor: pointer;
    color: var(--main-text);
  }
}

.field_text {
  width: 100%;
  pointer-events: none;
  background-color: transparent;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  color: var(--transparent-color);

  &.selected {
    color: var(--main-text);
  }
}

.copy_icon {
  flex: none;
  fill: var(--inactive_icon_color);

  &.selected {
    fill: var(--transparent-color);
  }
}

.copy_prompt {
  position: absolute;
  top: calc(100% - 5px);
  right: 0;
  pointer-events: none;
  padding: 12px 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  z-index: 1;
  background-color: var(--popup_prompt);
}

.prompt_pointer {
  position: absolute;
  bottom: 100%;
  right: 17px;
  border: 10px solid transparent;
  border-bottom-color: var(--popup_prompt);
}

.archiveBtn {
  width: 100%;
}

.fields_list {
  position: relative;
  display: grid;
  gap: 16px;
  max-width: 1000px;

  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .tablet_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 650px;
  }

  .description_wrapper {
    height: fit-content;
    flex-basis: 60%;

    &::after {
      display: none;
    }
  }

  .percents_item {
    max-width: 172px;
  }

  .fields_list {
    grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
  }

  .percents_categories {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1300px) {
  .tablet_wrapper {
    flex-direction: column;
    max-width: 980px;
    width: fit-content;
  }

  .percents_list {
    display: flex;
  }

  .percents_item {
    margin-right: 30px;
    padding-right: 30px;
    width: fit-content;
    max-width: fit-content;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 40px;
        width: 1px;
        background-color: var(--dividing_line);
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
      }

      border-bottom: none;
    }
  }
}

@media screen and (min-width: 1550px) {
  .fields_list {
    margin-bottom: 100px;
  }

  .notebook_wrapper {
    display: flex;
    column-gap: 60px;
    max-width: 1300px;
    margin-bottom: 50px;
  }

  .description_title {
    font-size: 20px;
  }

  .description_wrapper {
    padding: 0;
    flex: none;
  }

  .link_title {
    margin-bottom: 35px;
    font-size: 20px;
  }

  .paragraph {
    font-size: 18px;

    &:first-child {
      margin-bottom: 25px;
    }
  }

  .percent {
    font-size: 25px;
  }

  .percents_categories {
    font-size: 16px;
  }

  .field_text {
    font-size: 18px;
  }
}
