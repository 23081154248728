.status {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 10px;

  display: inline-block;
  border-radius: 10px;
  line-height: 1;
  position: relative;

  &.status_big {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  &.with_icon {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 5px 22px 5px 10px;

    &.status_big {
      padding-right: 25px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    svg {
      position: absolute;
      right: 5px;
      width: 14px;
      height: 14px;
      circle {
        fill: #fff;
      }
      path {
        fill: var(--system-orange-dark);
      }
    }

    &.warn {
      fill: var(--system-orange-dark);
    }
    &.error {
      fill: var(--system-red-dark);
    }
  }

  &.warn {
    background-color: var(--system-orange-light);
    color: var(--system-orange-dark);
  }
  &.success {
    background-color: var(--system-green-light);
    color: var(--system-green-dark);
  }
  &.error {
    background-color: var(--system-red-light);
    color: var(--system-red-dark);
  }
}

.popup {
  width: max-content;
  max-width: 300px;
  text-wrap: initial;
  font-size: 14px;
  text-align: center;
}
