.wrapper {
  min-height: 50px;
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  min-height: 26px;
  flex-wrap: wrap;
}

.checking {
  text-transform: uppercase;
  color: var(--accent-color-light);
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  position: relative;
  color: var(--system-red-dark);
  background-color: var(--system-red-light);

  &.success {
    color: var(--system-green-dark);
    background-color: var(--system-green-light);
  }
}
.status_icon {
  width: 8px;
}

.warn {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #f79649;
}

.warn_icon {
  flex-shrink: 0;
  fill: #f79649;
}

.recheck {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--accent-color-light);
  svg {
    width: 18px;
    fill: var(--accent-color-light);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1s infinite;
}
