@import '@scss/placeholders.scss';

.tariff_item {
  height: 302px;
  position: relative;

  &.modal {
    height: 240px;
    @media screen and (min-width: 768px) {
      height: 302px;
    }
  }

  &.selected {
    outline: 1px solid var(--primary-pink);
    background: var(--primary-white);
    pointer-events: none;

    &.cardColor {
      background: var(--primary-light-pink);
    }
  }

  &.disabled {
    pointer-events: none;
    filter: opacity(0.6);
  }

  &.cardColor {
    background: var(--primary-light-purple);

    &:hover {
      outline: 1px solid var(--primary-pink);
    }
  }

  .limited_label {
    position: absolute;
    pointer-events: none;
    right: -6px;
    bottom: -6px;
    width: 143px;
    height: 123px;
    background: url('../../../../images/limited_label.png') 50% / contain no-repeat;
    span {
      position: absolute;
      transform: rotate(-45deg);
    }
  }
  .limited_label__subtext {
    @extend %accent-text-gradient;
    bottom: 33px;
    left: 15px;
    font-weight: 600;
    font-size: 12px;
  }
  .limited_label__text {
    color: #fff;
    bottom: 37px;
    left: 45px;
    font-weight: 900;
    font-size: 14px;
  }
}

.tariff_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  border-radius: 24px;
}

.tariff_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
  text-align: start;
  font-weight: 500;
  font-size: 18px;

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
}

.tariff_parameters {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tariff_row {
  display: flex;
  align-items: center;
  gap: 16px;
}

.parameter_label {
  display: block;
  text-align: start;
  width: 72px;
  color: var(--other-dark-50);
}

.infinity {
  fill: var(--main-text);
}

.tariff_price {
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-pink);
  text-align: left;
  margin-right: 6px;
}

.hour_price {
  font-weight: 400;
  font-size: 14px;
  color: var(--other-dark-50);
}

.warn_icon {
  width: 24px;
  height: 24px;
}

.tooltip_bg {
  background-color: #fff !important;
  box-shadow: var(--forms2_shadow);
}

.tooltip_html {
  display: flex;
  gap: 8px;
  max-width: 400px;
}

.icon_triangle {
  flex: none;
  width: 18px;
  height: 18px;
}

.tooltip_message {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  text-transform: initial;
  color: var(--red-text_2);
}

.tooltip_description {
  font-size: 14px;
  font-weight: 400;
  text-transform: initial;
}

.link {
  @extend %link;
  font-size: inherit;
}

.soldout_label {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 2px;
  border-radius: 18px;
  padding: 0 6px;
  width: 80px;
  height: 20px;
  background: var(--system-orange-dark);
  color: var(--primary-white);
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  text-transform: uppercase;
}

.sold_out_text {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--system-orange-dark);

  svg {
    fill: currentColor;
    fill-opacity: 1;
  }
}

@media screen and (min-width: 768px) {
  .tariff_btn {
    gap: 16px;
  }

  .tariff_price {
    font-size: 20px;
  }

  .hour_price {
    font-size: 16px;
  }
}
