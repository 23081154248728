.submit__btn {
  max-width: 260px;
  height: 55px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: var(--main-text);
}

.alert_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  margin-bottom: 25px;
  margin-top: 25px;
}

.control_btns_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
  }
}
