.item {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  padding-top: 76px;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
}

.value {
  font-size: 15px;
  word-break: break-all;
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.tools_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}
.check_box {
  box-shadow: var(--checkbox_shadow);
  background-color: var(--forms_bgc_4);
}
