.server_item {
  display: grid;

  width: fit-content;

  position: relative;
  border-radius: 24px;
  padding: 16px;
  min-height: 60px;

  background-color: var(--primary-light-purple);

  grid-template-columns: auto 1fr;
}

.main_info_wrapper {
  display: flex;
  flex-direction: column;
}

.control_bts_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
}

.shevron {
  transition: transform 150ms;
  transform: rotateZ(0);

  width: 24px;

  &.opened {
    transform: rotateZ(180deg);
  }
}

.tariff_name {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.countItem {
  font-weight: 400;
  font-size: 18px;
  color: var(--main-text);
  margin-left: 10px;
}

.btn_delete:hover {
  color: var(--primary-pink);
}

.percent {
  font-size: 16px;
  color: var(--red-text);
}

.discount_wrapper {
  margin-bottom: 10px;
}

.old_price {
  font-size: 16px;
  color: var(--transparent-color);
  text-decoration: line-through;
}

.price {
  font-weight: 600;
  font-size: 18px;
}

.dropdown {
  height: 0;
  transition: height 150ms;
  overflow: hidden;
  grid-column: 1/3;

  display: flex;
  flex-direction: column;
  gap: 8px;

  &.opened {
    margin-top: 16px;
  }
}

.label {
  color: var(--other-dark-50);

  &:not(:last-child) {
    padding-right: 16px;
  }
}

.periodInfo {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  span {
    color: var(--other-dark-50);

    &:not(:last-child) {
      padding-right: 5px;
      border-right: 1px solid var(--other-dark-20);
    }
  }
}
