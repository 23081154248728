@import '@scss/placeholders.scss';
@import '@scss/common.scss';

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  margin-bottom: 8px;
}

.ordered_list {
  padding-bottom: 50px;
  counter-reset: section;

  & > * {
    position: relative;

    &:nth-child(3) {
      z-index: 1;
    }

    &::before {
      @extend .body_s;
      position: absolute;
      left: 24px;
      top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--primary-dark);
      color: var(--primary-white);
      counter-increment: section;
      content: counter(section);
    }
  }
}

.section_title {
  padding-left: 32px;
  line-height: 24px;
}

.limit_wrapper {
  text-align: center;

  * {
    font-size: 18px;
  }
}

.limit_title {
  font-size: 20px;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: var(--grid);
  gap: 8px;

  &.params_grid {
    gap: 24px;
  }

  &.cards_grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 300px));
  }
}

.checkbox_label {
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
  cursor: pointer;
}

.ip_discount {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  width: fit-content;
  padding: 0 6px;
  height: 24px;
  color: var(--primary-white);
  background-image: var(--accent-gradient);
}

.period_bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
}

.period_select {
  max-width: 250px;
}

.footer_row {
  display: flex;
  justify-content: space-between;
  gap: 34px;
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

.icon {
  display: block;
}

.caption_icon {
  margin-bottom: -3px;
  margin-right: 3px;
}

.period_description {
  display: block;
  width: 100%;
  text-align: start;
  font-size: 14px;
  margin-bottom: 16px;
  cursor: pointer;

  &.truncated {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:disabled {
    text-overflow: initial;
    white-space: initial;
    overflow: initial;
    cursor: initial;
  }
}

.warning {
  margin-bottom: 24px;
}

.link {
  @extend %link;
}

.backup_description {
  color: var(--other-dark-50);
}

.create_schedule_btn {
  text-transform: uppercase;
}

.backup_text_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hintPopup {
  max-width: 400px;
}

.ip_wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.check_ip {
  width: 100%;
  align-self: flex-end;
  @media (min-width: 1200px) {
    width: auto;
  }
}

.dc_link {
  margin-top: 10px;
  font-size: 14px;
}

.access_section {
  position: relative;
  z-index: 2;
}

.schedule_type_wrapper {
  display: grid;
  gap: 10px;
  max-width: 1480px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 300px));
}

.schedule_type {
  display: flex;
  gap: 18px;
  position: relative;
  padding: 16px;
  z-index: 0;

  &:first-child {
    z-index: 1;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--other-dark-20);
    border-radius: 24px;
    background: var(--primary-white);
    transition: transform 0.2s;
  }

  &.selected::before {
    border: 1px solid var(--primary-pink);
  }

  &:not(.selected) {
    cursor: pointer;
    &:hover::before {
      transform: scale(1.01);
    }

    &:active::before {
      transform: scale(0.99);
    }
  }
}

.backup_price {
  flex-shrink: 0;
}

.pink {
  color: var(--primary-pink);
}

.backup_btn {
  width: fit-content;
}

.marketplace_select {
  max-width: 300px;
}

@media screen and (min-width: 768px) {
  .checkbox_label {
    font-size: 18px;
  }

  .input {
    font-size: 18px;
  }

  .ip_wrapper {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .period_bar {
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 32px;
  }

  .notice_wrapper {
    max-width: 50%;
  }
}

.os_tooltip {
  text-transform: capitalize;
}
