.options {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;

  background-color: var(--primary-white);
  width: fit-content;
  border-radius: 100px;
  padding: 2px 4px;
}

.btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  align-content: center;
  justify-items: center;

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--primary-white);
    color: var(--primary-pink);
  }
}

.option__btn_text {
  white-space: normal;
  min-width: 220px;
}

button:disabled {
  cursor: default;
}
