.balance_item {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.header_btn {
    display: none;
  }

  &:hover,
  &:active {
    .balance_wrapper {
      background: var(--primary-pink);
      color: var(--primary-white);
    }
  }
}

.balance_wrapper {
  cursor: pointer;
  border-radius: 100px;
  background: var(--primary-white);
  padding: 16px 20px;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.wallet_icon {
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 768px) {
  .balance_item {
    &.header_btn {
      display: flex;
    }
    &.burger_btn {
      display: none;
    }
  }
}

@media screen and (min-width: 1024px) {
  .balance_wrapper {
    gap: 7px;
    padding: 18px 20px;
  }

  .wallet_icon {
    width: 20px;
    height: 20px;
  }
}
