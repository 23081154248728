@use '@scss/media.scss' as media;

.container {
  width: 100%;
}

.table {
  th {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 113%;
  }
}

.list {
  gap: 10px;
}

.grid {
  grid-template-columns: 1fr;
}
