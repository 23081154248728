.server_item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  position: relative;
  border-radius: 24px;
  padding: 16px;
  min-height: 60px;

  background-color: var(--primary-light-purple);
}

.periodInfo {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  span {
    color: var(--other-dark-50);

    &:not(:last-child) {
      padding-right: 5px;
      border-right: 1px solid var(--other-dark-20);
    }
  }
}

.btn_delete {
  width: 20px;
  height: 20px;

  &:hover {
    color: var(--primary-pink);
  }
}
