@use '@scss/media.scss' as media;

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.cardBlock {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  @include media.minw-768 {
    gap: 16px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 16px;
  gap: 8px;
  max-width: 48%;
  width: 100%;

  @include media.minw-768 {
    max-width: 180px;
    gap: 20px;
    padding: 20px;
  }

  .iconBlock {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;

      svg {
        color: var(--primary-pink);
      }
    }
  }

  .cardTitle {
    text-align: start;
    font-weight: 500;
    font-size: 14px;

    @include media.minw-768 {
      font-size: 18px;
    }
  }
}
