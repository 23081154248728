.sort {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.sort_select {
  width: 215px !important;
}

.sort_order_btns {
  padding: 4px;
  background-color: var(--primary-white);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 110px;
}

.label {
  display: block;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.btn_active {
  border-color: var(--primary-pink);
  color: var(--primary-pink);
  pointer-events: none;
}
