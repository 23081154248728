.rSelect {
  position: relative;
  z-index: 5;

  & > div:has(.rSelect__menu) {
    z-index: 5;
  }

  .rSelect__control {
    width: 100%;
    position: relative;
    height: 48px;
    border: 1px solid var(--other-dark-20);
    background-color: var(--primary-white);
    box-shadow: unset;
    padding: 0 20px;
    border-radius: 100px;

    @media (min-width: 1024px) {
      height: 56px;
    }
    cursor: pointer;
    &:hover {
      border-color: var(--other-dark-20);

      .rSelect__indicator {
        color: var(--primary-pink);
      }
    }
  }

  &.error {
    .rSelect__control {
      border-color: var(--system-error);
    }
  }

  .rSelect__label {
    display: block;
    margin-left: 20px;
    margin-bottom: 6px;
    text-align: left;
  }

  .rSelect__indicator {
    color: var(--primary-dark);
    padding: 0;
    pointer-events: none;
    transition: 0.15s;
    svg {
      width: 16px;
      height: 16px;
      transform: scale(0.9);
      opacity: 0.7;
    }
  }

  .rSelect__indicator-separator {
    display: none;
  }

  .rSelect__menu {
    background-color: var(--primary-light-purple);
    border-radius: 0 0 24px 24px;
    width: calc(100% - 2px);
    margin-left: 1px;
    padding-top: 30px;
    margin-top: -30px;
    overflow: hidden;
    z-index: -1;
    text-align: left;
  }

  .rSelect__value-container {
    padding: 0;
  }

  .rSelect__single-value,
  .rSelect__input-container {
    font-weight: 300;
    color: var(--primary-dark);
    overflow: hidden;
  }

  .rSelect__input-container {
    grid-template-columns: 0 1fr !important;
  }

  .rSelect__option,
  .rSelect__single-value {
    font-size: 18px;
    border-radius: 24px;
    cursor: pointer;
  }

  .rSelect__option--is-focused {
    &,
    &:active {
      background-color: var(--primary-light-purple);
      color: var(--primary-pink);
    }
  }
  .rSelect__option--is-selected {
    &,
    &:active {
      background-color: var(--primary-light-purple);
      color: var(--primary-pink);
    }
  }

  .rSelect__control--menu-is-open {
    &,
    &:hover {
      border-color: var(--primary-dark);
    }
    .rSelect__single-value {
      display: none;
    }
    .rSelect__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  .rSelect__placeholder,
  .rSelect__single-value {
    text-align: left;
  }
}
