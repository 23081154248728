@import '@scss/typography.scss';
@import '@scss/common.scss';

.formFieldsBlock {
  display: grid;
  gap: 8px;

  &.first {
    align-items: center;
  }
}

.paymentMethodBtn {
  @extend .input_base_styles;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  height: 48px;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }

  .descrWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .methodDescr {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }

  &.withHint {
    overflow: unset;
    color: var(--other-dark-20);
  }

  &.selected {
    border: 1px solid var(--primary-pink);

    &:hover {
      background-color: transparent;
    }
  }

  &.account {
    justify-content: flex-start;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: var(--primary-dark);
    max-width: 280px;
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .balance {
    text-align: left;
    margin-left: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: var(--transparent-color);
  }

  &:hover {
    background-color: var(--other-dark-5);
  }

  @media screen and (min-width: 620px) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 33%;
    align-items: center;

    .descrWrapper {
      overflow: hidden;
      max-width: unset;
      flex-basis: unset;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 56px;
  }
}

.infoBtnCard:hover {
  color: var(--primary-pink);
}

.wallet_icon {
  color: var(--primary-pink);
}

.wallet_icon_wrapper {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary-light-pink);
}

.infoText_wrapper {
  border-radius: 24px;
  padding: 16px;
  gap: 4px;
  background-color: var(--other-dark-5);
}

.infotext {
  display: flex;
  flex-direction: column;
  gap: 4px;

  max-height: 68px;
  overflow-y: hidden;
  transition: max-height 0.3s ease;

  span {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    color: var(--primary-purple);
  }

  p {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
  }

  &.showMore {
    max-height: 100vh;

    p {
      white-space: wrap;
    }
  }
}
