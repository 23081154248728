@use '@scss/media.scss' as media;
@use '@scss/mixin.scss' as mixin;

.domainsZone {
  margin-top: 40px;
}

.domainsZoneTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);

  word-break: break-word;
}

.domainItem {
  padding: 20px 10px 20px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-height: 95px;

  &.selected {
    outline: 1px solid var(--primary-pink);
    background: var(--primary-white);
  }

  &:hover {
    background-color: var(--domains_hover_bgc);
  }

  &.notAvailable {
    cursor: default;
  }
}

.domainsBlock {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  margin-top: 10px;
  gap: 20px;
}

.domainName {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  margin-left: 25px;

  &.selected {
    background: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &.notAvailable {
    margin-left: 0px;
  }
}

.domainPrice {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 70.9%;
  color: var(--main-text);
}

.saleEur {
  position: relative;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 70.9%;
  // text-decoration-line: line-through;
  color: var(--transparent-color);
  margin-top: 11px;

  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--transparent-color);
    position: absolute;
  }

  .selected & {
    color: var(--red-text);
    text-decoration-color: var(--red-text);
  }
}

.selectBtn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100.9%;
  text-decoration-line: underline;
  color: var(--main-text);
  text-align: right;

  &.notAvailable {
    text-decoration-line: none;
    color: var(--system-error);
  }
}

.selectedDomainsBlock {
  margin-top: 20px;
  gap: 20px;
  max-width: 540px;
}

.sum {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: var(--main-text);

  display: flex;
  align-items: center;

  flex-wrap: wrap;

  gap: 10px;

  span {
    background: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.selectedDomains {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 24px;
  border-radius: 24px;
  background: var(--primary-white);
  backdrop-filter: blur(50px);

  flex: 1;
}

.selectedItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--other-dark-20);
  }

  .selectedDomainName {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 113%;
    color: var(--main-text);

    max-width: 55%;

    @include media.minw-374 {
      max-width: 65%;
    }

    @include mixin.truncate-text;
  }

  div {
    font-size: 17px;
    font-weight: 500;
  }

  .cross {
    cursor: pointer;
    transform: scale(0.85);
    margin-left: 10px;

    path {
      stroke: var(--primary-pink);
    }
  }
}

.btnBlock {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 40px;

  gap: 10px;
}

.checkbox {
  background-color: var(--forms_bgc_4);
}

.prolongBlock {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;

  span {
    font-size: 14px;
  }
}

.priceDescr {
  margin-top: 10px;

  font-weight: 300;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
}

.pricesBlock {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row-reverse;
  gap: 10px;
}

@media screen and (max-width: 449px) {
  .btnBlock {
    flex-direction: column;
    gap: 10px;
  }
}

@media screen and (min-width: 768px) {
  .domainsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .domainPrice {
    font-size: 17px;
    font-weight: 500;
  }

  .saleEur {
    font-size: 14px;
  }
}
