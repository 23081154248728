.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.placeholder {
  color: var(--transparent-color);
}

.text {
  max-width: calc(100% - 25px);
  &.grow {
    flex-grow: 1;
  }
}

.edit_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 39px;
  // padding: 10px;
  text-align: left;
  cursor: text;
  &:hover {
    color: var(--primary-pink);
  }
}

.editBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 7px 0 7px 10px;
  border-bottom: 1px solid var(--accent-color-light);
  height: 39px;
  max-height: 39px;
  width: 100%;

  input {
    position: relative;
    flex-grow: 1;
    border: none;
    outline: none;
    font-style: normal;
    background: transparent;
    overflow-x: auto;
    z-index: 1;
  }

  & .edit_input {
    height: 100%;
    max-width: calc(100% - 30px);
  }
}

.btn {
  display: none;
  position: absolute;
  right: 10px;

  &_show {
    display: block;
  }

  &__check {
    cursor: pointer;
    z-index: 2;
    position: relative;
    height: 100%;
    width: 30px;
    right: unset;
    // border-left: 1px solid var(--access_rights_bg_item_title);

    & svg path {
      stroke: var(--primary-pink);
    }

    &:hover svg path {
      stroke: var(--primary-purple);
    }
  }
}

.edit_icon {
  fill: var(--accent-color-light);
}
