.select_btn {
  position: relative;
  z-index: 100;
}

.wrapper {
  position: fixed;
  top: -8px;
  left: -8px;

  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 16, 25, 0.3);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.3s ease;

  &.opened {
    opacity: 1;
    pointer-events: fill;
  }

  &.scrolled {
    top: -12px;
  }

  @media screen and (min-width: 768px) {
    top: 0;
    left: 0;

    &.scrolled {
      top: -17px;
      left: -9px;
    }
  }

  @media screen and (min-width: 1024px) {
    &.scrolled {
      top: -25px;
      left: -130px;
    }
  }
}

.input_wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
  border: 1px solid var(--primary-purple);
  border-radius: 100px;
  padding: 16px 24px;
  transition: background-color 0.1s, transform 0.2s ease-in-out;
  cursor: auto;

  &:hover,
  &:active {
    .arrow_icon {
      stroke: var(--primary-white);
    }

    .btn_text {
      color: var(--primary-white);
    }
  }

  &:hover {
    background-color: var(--primary-purple);
  }

  &:active {
    background-color: var(--primary-dark-purple);
    transform: scale(0.95);
  }

  &:not(:active) {
    transform: scale(1);
  }

  &.opened {
    background-color: var(--primary-dark-purple);

    .btn_text {
      color: var(--primary-white);
    }
    .arrow_icon {
      stroke: var(--primary-white);
    }
  }
}

.dropdown {
  display: grid;
  position: absolute;
  border: 1px solid var(--primary-purple);
  left: 0;
  top: 56px;
  gap: 10px;
  padding: 16px;
  background: var(--primary-white);
  width: 290px;
  z-index: -1;
  border-radius: 24px;

  @media screen and (min-width: 1024px) {
    top: 64px;
    width: 340px;
  }
}

.arrow_icon {
  stroke: var(--primary-purple);
  height: 16px;
  width: 16px;
  margin-left: 5px;
  transition: transform 0.2s ease-in;

  &.active {
    transform: rotate(180deg);
  }
}

.btn_text {
  text-transform: uppercase;
  color: var(--primary-purple);
}

.list_item_link {
  display: flex;
  gap: 10px;

  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--other-dark-5);
  }

  &:hover {
    color: var(--primary-purple);

    .active_page_circle {
      background-color: var(--primary-purple);
    }

    .icon_block {
      color: var(--primary-light-purple);
    }
  }
}

.service_info {
  color: var(--other-dark-50);
}

.active_page_circle {
  display: grid;
  place-items: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-color: var(--primary-light-purple);
  border-radius: 50%;
}

.icon_block {
  color: var(--primary-purple);
  width: 24px;
  height: 24px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

@media screen and (min-width: 1024px) {
  .input_wrapper {
    padding: 16px 32px;
    gap: 6px;
  }
}
