.modal {
  max-width: 900px;
}

.modal_body {
  display: grid;
  gap: 20px;
}

.label {
  display: block;
  margin-left: 20px;
  margin-bottom: 6px;
  margin-top: 20px;
}

.error_message {
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
}

.error_department {
  margin-left: 0px;
}

.submit_btn {
  height: 56px;
  width: 100%;
  text-transform: uppercase;
}

.departmentSelect {
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    margin: 0 -10px;
  }
}

.select {
  margin-top: 20px;
  z-index: 9;

  div {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) {
  .departmentSelect {
    flex-direction: row;
  }

  .submit_btn {
    max-width: 255px;
    width: 255px;
  }
}

.notif_message {
  width: 100%;
}
