.modal {
  width: 90%;
  background-color: var(--main-bgc);
  max-width: 1024px;
}

.table {
  td {
    font-size: 14px;
  }
  thead {
    top: -20px !important;
  }
}

.item {
  background-color: var(--primary-pink-16);
}

.grid {
  grid-template-columns: 1fr;
}
