.img {
  &.without {
    width: 17px;
  }
}

.image_name {
  max-width: 180px;
  text-align: start;
  color: var(--transparent-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name_wrapper {
  text-align: start;
}

.flex_col {
  display: flex;
  flex-direction: column;
}

.os_name {
  color: var(--other-dark-50);
}

.details {
  display: flex;
  align-items: center;
  padding: 2px 2px 2px 6px;
  gap: 2px;
  color: var(--primary-purple);

  background: var(--primary-light-purple);
  border-radius: 100px;

  &:hover {
    background: var(--primary-purple);
    color: var(--primary-white);
  }
}

.marketplaceBtn {
  position: relative;
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 16px;
  border-radius: 24px;
  border: 1px solid var(--other-dark-20);

  background-color: var(--primary-white);

  .flex_col {
    align-items: center;
    margin-bottom: 12px;
    gap: 4px;
  }

  &.selected {
    border: 1px solid var(--primary-pink);

    .decoratedRadio {
      opacity: 1;
    }
  }

  & .img {
    margin-bottom: 8px;
  }

  &:hover:not(.selected) {
    .name_wrapper {
      color: var(--primary-pink);
    }

    .decoratedRadio {
      opacity: 0.2;
    }
  }
}

.decoratedRadio {
  position: absolute;
  left: 16px;
  top: 16px;

  display: grid;
  place-items: center;

  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--primary-pink);
  opacity: 0;

  & span {
    width: 6px;
    height: 6px;
    background-color: var(--primary-white);
    border-radius: 50%;
  }
}
