.push-enter {
  transform: translate(150%);
}

.push-enter-active {
  transform: translate(0);
  transition: 0.3s;
}

.push-exit {
  transform: translate(0);
}

.push-exit-active {
  transform: translate(150%);
  transition: 0.3s;
}
