.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}

.error {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 130.5%;
  color: var(--system-error);
}

.errorText {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-align: center;
  color: var(--main-text);
  margin-top: 20px;
}
