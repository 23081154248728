.prolong_modal {
  max-width: 430px;
}

.prolong_modal_body {
  padding-bottom: 200px;
  overflow-x: hidden;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.prolong_info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 20px;
  justify-content: space-between;

  :nth-child(even) {
    justify-self: right;
  }
}

.cancel_autoprolong {
  align-self: flex-start;
}
