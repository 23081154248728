@import '@scss/placeholders.scss';

.icon_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 100ms;

  &.large {
    width: 48px;
    height: 48px;
  }
  &.small,
  &.medium {
    width: 40px;
    height: 40px;
  }
  &.xsmall {
    width: 36px;
    height: 36px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
  }
}

.primary {
  background-color: var(--primary-pink);
  color: var(--primary-white);
  &:hover {
    background-color: var(--primary-purple);
  }
  &:active {
    background-color: var(--primary-dark-purple);
  }
}

.secondary {
  background-color: var(--primary-white);
  border: 1px solid var(--primary-white);
  color: var(--primary-dark);

  &:hover {
    border-color: var(--primary-pink);
  }
  &:active {
    background-color: var(--primary-pink);
    color: var(--primary-white);
  }
}
.third {
  background-color: var(--primary-white);
  // border: 1px solid var(--primary-white);
  color: var(--primary-dark);

  &:hover {
    background-color: var(--primary-pink);
    color: var(--primary-white);
  }
}
.fourth {
  background-color: var(--primary-white);
  border: 1px solid var(--primary-pink);
  color: var(--primary-pink);

  &:hover {
    background-color: var(--primary-pink);
    color: var(--primary-white);
  }
}

@media screen and (min-width: 1024px) {
  .icon_btn {
    &.large {
      width: 56px;
      height: 56px;
    }
    &.medium {
      width: 48px;
      height: 48px;
    }
  }
}
