.notifList {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  gap: 8px;

  & > :not(:last-child):not(:nth-last-child(2)) {
    border-bottom: 1px solid var(--other-dark-20);
  }
}

.clear_btn {
  display: grid;
  text-decoration: underline;
  align-self: end;
  color: var(--other-dark-50);
}
