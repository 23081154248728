.wrapper {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 100vh;
}

// .scroll_container {
//   overflow-y: auto;
//   width: 100%;
// }

.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0 24px;
  }
}
