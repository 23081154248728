%accent-text-gradient {
  background-image: var(--accent-text-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

%backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: var(--backdrop);
  transition: opacity 0.15s;
  pointer-events: none;
  opacity: 0;
}

%input_type_number {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-pink);
  }
}

%server_items {
  display: flex;
  flex-direction: column;

  width: 100%;

  background: var(--gradient_cards_static);
  box-shadow: var(--shadow_cards_static);

  &:not(:first-child) {
    margin-top: 10px;
  }
}

%whois_style {
  background: var(forms_bgc_4);
  box-shadow: var(--forms_shadow);
  margin: 30px 15px;

  max-height: 65vh;
  overflow-y: auto;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: #e72690;
    width: 4px;
    height: 4px;
  }
}

%link {
  color: var(--link-text);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 5px;
}
