.table {
  width: 100%;
  text-align: left;
  border-spacing: 0 8px;
  &.v_align_top {
    td {
      vertical-align: top;
    }
  }
  thead,
  tbody {
    position: relative;
    z-index: 1;
  }
  &.sticky_header {
    thead {
      @media (min-width: 1024px) {
        position: sticky;
        top: 0;
        z-index: 3;
      }
    }
  }

  &.item_clickable {
    tbody {
      tr {
        &:not([data-disabled='true']) {
          cursor: pointer;

          &:hover {
            td {
              background: var(--primary-white);
            }
            [data-option-btn] {
              background-color: var(--primary-pink);
              color: var(--primary-white);
            }
          }
        }

        &:hover {
          [data-option-wrapper] {
            background-color: var(--primary-pink);
            color: var(--primary-dark);
          }
        }
      }
    }
  }

  tr {
    position: relative;
  }
  td,
  th {
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
    padding: 17px 5px;

    @media (min-width: 1550px) {
      padding: 17px 10px;
      &:last-child {
        padding-right: 32px;
      }
    }
  }

  th,
  td {
    font-size: 14px;
    white-space: nowrap;
    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
    }
  }

  th {
    font-weight: 400;
    color: var(--other-dark-50);
    background: var(--primary-white);
  }

  td {
    padding-top: 24px;
    padding-bottom: 18px;
    background: var(--primary-white-40);

    &[data-target='name'] {
      padding-top: 14px;
      max-width: 130px;
      width: 130px;
    }
    &[data-target='checkbox'] {
      width: 40px;
      max-width: 40px;
      vertical-align: middle;
    }
    &[data-target='options'] {
      width: 60px;
    }
  }

  tr[data-tr-transparent] td {
    background-color: transparent;
  }

  [data-wrap] {
    white-space: initial;
    word-break: break-word;
  }

  &.secondary {
    th {
      color: var(--primary-white);
      background: var(--primary-pink);
    }
    td {
      background: var(--primary-pink-16);
    }
    &.item_clickable {
      tbody {
        tr {
          &:not([data-disabled='true']) {
            cursor: pointer;

            &:hover {
              td {
                background: var(--primary-pink);
              }
              [data-option-btn] {
                background-color: var(--primary-white);
                color: var(--primary-dark);
              }
            }
          }
        }
      }
    }
  }

  [data-td-value-top] {
    min-height: 29px;
  }
}
