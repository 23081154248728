.banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 544px;
  margin-bottom: 40px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: var(--forms3_shadow);
  color: #fff;
  background: url('../../images/banners/promotion_banner.webp') 100% 100% no-repeat,
    linear-gradient(63deg, #8f1ed0 0%, #a611d8 100%);

  @media (min-width: 768px) {
    padding: 20px 18px 18px;
  }
  @media (min-width: 1550px) {
    max-width: 645px;
  }

  &.second {
    background: url('../../images/banners/promotion_banner.webp') 100% 100% no-repeat,
      linear-gradient(59deg, #6c1ed0 0%, #cc10a3 100%);
    max-width: 383px;
    padding: 20px 8px 20px 12px;
    @media (min-width: 768px) {
      padding: 20px 10px 18px 18px;
    }
    @media (min-width: 1550px) {
      max-width: 426px;
      padding: 20px 18px;
    }
    .banner {
      &__title {
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
  &.third {
    background: url('../../images/banners/promotion_banner.webp') 100% 100% no-repeat,
      linear-gradient(60deg, #47465b 3.35%, #575268 100%);
    max-width: 400px;
    padding: 12px 10px 12px 12px;
    @media (min-width: 1550px) {
      max-width: 411px;
    }
    @media (min-width: 768px) {
      padding: 14px 14px 12px 14px;
    }
    .banner {
      &__title {
        font-size: 18px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      &__description {
        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
  }
  &__title {
    margin-bottom: 12px;
    font-size: 17px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 1550px) {
      font-size: 22px;
    }
    &_link {
      font-weight: 400;
      text-decoration: underline;
    }

    & + .banner__footer {
      margin-top: 27px;
    }
  }
  &__description {
    max-width: 415px;
    font-size: 15px;
    margin-right: -30px;
    @media (min-width: 768px) {
      font-size: 16px;
    }
    @media (min-width: 1550px) {
      font-size: 18px;
    }
  }
  &__btn {
    background-color: #fff;
    max-width: max-content;
  }
  &__close {
    width: 26px;
    height: 26px;
    margin: -5px 0 0 5px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    svg {
      width: 12px;
      fill: #fff;
    }
    @media (min-width: 1550px) {
      width: 30px;
      height: 30px;
      svg {
        width: 14px;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  &__counter {
    display: flex;
    align-items: center;
    margin-right: -35px;
    font-size: 18px;
    font-weight: 500;
    font-variant-numeric: tabular-nums;
    svg {
      margin-right: 5px;
    }
  }
}
