@import '@scss/placeholders.scss';

.wrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% - 32px);
  width: 100%;
  padding-top: 30px;
  border-radius: 0 0 24px 24px;
  z-index: 1;
  overflow: hidden;
  background: var(--primary-light-purple);
  border: 1px solid var(--other-dark-10);

  &.withError {
    top: calc(100% - 50px);
    padding-top: 30px;
  }
}

.suggestion_btn {
  width: 100%;
  padding: 8px 20px;
  text-align: start;
  cursor: pointer;

  &.active,
  &:hover {
    color: var(--primary-pink);
  }
}

.suggestion_main_text {
  font-weight: 500;
}

.adressHint {
  @media screen and (max-width: 500px) {
    max-width: 240px;
    text-wrap: balance;
  }
}
