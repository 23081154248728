@use '@scss/media.scss' as media;

.form {
  margin-top: auto;

  .error_text {
    position: absolute;
    margin-top: 0;
    padding-left: 20px;
    bottom: 0;
    @include media.minw-1024 {
      top: calc(100% + 2px);
    }
  }
}
.submit_btn {
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: 48px;
  height: 48px;
}

.fieldsBlock {
  position: relative;
  border-radius: 24px;
  background-color: var(--primary-white);
  .field_wrapper {
    border: none;
    padding-right: 0;
  }

  .field {
    padding-right: 66px;
  }
}

.btnsBlock {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-align: center;
  text-decoration-line: underline;
  color: var(--main-text);
  @media (max-width: 767px) {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .btnsBlock {
    margin: 20px 0px;
    display: block;
  }

  .link {
    margin-left: 25px;
    margin-top: 0px;
  }
}

@media screen and (min-width: 1550px) {
  .btnsBlock {
    margin: 20px 100px;
  }
}
