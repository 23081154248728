.modal {
  max-width: 870px !important;
  .modal__body {
    padding-bottom: 100px;
    @media (min-width: 768px) {
      padding-bottom: 260px;
    }
  }
}

.form {
  display: grid;
  width: 100%;
  gap: 20px 15px;
  -webkit-overflow-scrolling: touch;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.input {
  max-width: 100%;
  margin-right: 0px;
  padding-bottom: 0;

  @media screen and (max-width: 767px) {
    &:first-child {
      margin-top: 0;
    }
  }
}

.select {
  max-width: 100%;
  margin-right: 0px;

  &.dateSelect {
    width: 195px;
    margin-right: 20px;
  }
}

.filter {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-self: flex-end;
  gap: 15px;
}

.checkbox_field {
  display: flex;
  gap: 7px;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 10px;
}

.filter__clouds {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 6px;
  padding-top: 8px;
  margin-bottom: 10px;
}

.filter__cloud {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 24px;
  border: 1px solid var(--other-dark-20);
  line-height: 1;
}

.filter__cloud_name {
  color: var(--other-dark-50);
}

.filter__cloud_btn {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    svg {
      fill: var(--main-text);
    }
  }
}
