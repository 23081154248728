.buying_panel {
  display: flex;
  justify-content: space-between;
  gap: 16px 20px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  z-index: 8;
  padding: 16px;
  border-radius: 24px;
  background-color: var(--primary-white);

  &.fade {
    transform: none;
  }
}

@media screen and (min-width: 1024px) {
  .buying_panel {
    padding: 24px;
  }
}
