.card {
  position: relative;
}

.tr {
  &.selected {
    td {
      background-color: var(--primary-white);
    }
  }
}

.options {
  position: absolute;
  display: flex;
  align-items: center;
  right: 9px;
  top: 9px;
}

.dot {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: var(--main-text);
  }

  &.active {
    &::after {
      background: var(--accent-gradient);
    }
  }
}

.list {
  position: absolute;
  min-width: 150px;
  background-color: var(--forms_bgc_4);
  top: 35px;
  right: -19px;
  padding: 22px 22px;
  box-shadow: var(--forms2_shadow);
  border-radius: 5px;
  display: none;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--forms_bgc_4);
    transform: rotate(45deg);
    top: -7px;
    right: 24px;
    box-shadow: -1px -1px 3px -2px rgba(0, 0, 0, 0.5);
  }

  &.opened {
    display: block;
  }
}

.btn {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover {
    & .btn_text {
      font-weight: 400;
    }
    & .icon {
      fill: url(#gradient);
    }
  }
}

.btn_text {
  font-weight: 300;
  font-size: 18px;
  line-height: 70.9%;
  margin-left: 16px;
  color: var(--main-text);
  transition: font-weight 0.1s ease;
}

.card_item_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 11px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.item_title {
  font-size: 14px;
  line-height: 120%;
  color: var(--transparent-color);
}

.item_text {
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

.item_title,
.item_text {
  word-break: break-all;
}

.status {
  color: var(--system-success);
}

@media screen and (min-width: 1024px) {
  .card {
    &:hover::before,
    &.selected::before {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      background: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
      left: 0;
      top: 0;
      box-shadow: 0px 1px 8px rgb(65 38 114 / 15%);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}

@media screen and (min-width: 1550px) {
  .card_item_wrapper {
    display: flex;
    align-items: center;

    &:nth-child(n) {
      margin-bottom: 0;
    }

    &:nth-child(2) {
      flex-basis: 6%;
    }
    &:nth-child(3) {
      flex-basis: 9%;
      word-break: break-all;
    }
    &:nth-child(4) {
      flex-basis: 9%;
    }
    &:nth-child(5) {
      flex-basis: 30%;
    }
    &:nth-child(6) {
      flex-basis: 10%;
    }
    &:nth-child(7) {
      flex-basis: 20%;
    }
    &:nth-child(8) {
      flex-basis: 8%;
    }
  }

  .item_text {
    font-size: 16px;
    line-height: 130%;
  }
}
