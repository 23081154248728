@import '@scss/placeholders.scss';

.submit__btn {
  max-width: 260px;
  height: 55px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: var(--main-text);
}

.alert_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  margin-bottom: 25px;
  margin-top: 25px;
}

.control_btns_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
  }
}

.hidden {
  opacity: 0;
}

.item_name {
  padding-right: 70px;
  flex-grow: 1;
  text-align: left;
}

.item_wrapper {
  position: relative;
  .list & {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &:hover,
  &.opened {
    > .item {
      &.item_clickable {
        > .item_name {
          @extend %accent-text-gradient;
        }
      }
      .group_icon {
        path {
          fill: url(#gradient);
        }
      }
      & > .shevron {
        stroke: url(#gradient);
      }
    }
  }
  &.opened {
    > .item {
      & > .shevron {
        transform: rotate(180deg);
      }
    }
  }
}

.item {
  padding: 5px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: default;
  &.item_clickable {
    cursor: pointer;
  }

  &:not(.group_head) ~ .sublist {
    padding: 5px 10px 5px 20px;
    position: relative;

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 20px;
      background-color: var(--center-border-color-pink);
    }
  }
}

.sublist {
  padding: 5px 0;
}

.group_head {
  background: var(--primary-light-purple);
  width: 100%;
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 12px;
  > .item_name {
    padding-right: 0;
  }
}

.part {
  [class*='circle'] {
    background: var(--accent-color-light);
  }
}

.checkbox_wrapper {
  position: absolute;
  top: 5px;
  right: 40px;
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 5px;
}

.info_tooltip {
  height: 18px;
}
