.list {
  // max-height: 700px;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
}

.sub_list {
  margin-left: 23px;
}

.list_item_wrapper {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.title_inside_wrapper {
  display: flex;
}

.arrow_show_more {
  margin-left: 40px;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .access_rights {
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .access_rights {
    width: 100%;
  }
}
