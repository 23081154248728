.hint {
  z-index: 160;
  opacity: 1;
  font-size: 100%;
  border-radius: 8px;
  padding: 12px;

  &.default_theme {
    background: var(--primary-dark);
    color: var(--primary-white);
    border-radius: 16px;
  }
}

@media (max-width: 767px) {
  .hint {
    width: fit-content !important;
  }
}

.wrapper {
  width: max-content;
}
