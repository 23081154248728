.autoPayTitle {
  white-space: pre-line;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
  max-width: 920px;
}

.form {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  &:not(.visible) {
    display: none;
  }
}

.configureBtn {
  height: 53px;
  max-width: 258px;
  text-transform: uppercase;
  margin-top: 36px;

  @media screen and (max-width: 499px) {
    max-width: none;
  }
}

.formFieldsBlock {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 15px 20px;
  align-items: end;
}

.error_message_addpayer {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 10px;
}

.select {
  max-width: 100%;
  margin-top: 20px;

  button {
    height: 57px;
  }
}

.buttonTitle {
  display: block;
  margin-left: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
  text-align: start;
  font-weight: 400;
  font-size: 12px;
}

.addNewPayerBtn {
  margin: 20px 15px;
  display: flex;
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 70.9%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: var(--link-color);
  cursor: pointer;
}

.selectedItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    span {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 130.5%;
      color: var(--main-text);
    }
    span:first-child {
      font-weight: 400;
      font-size: 18px;
    }
  }
}

.btn_more {
  margin-bottom: 10px;
  font-size: 18px;
  border-bottom: 1px solid;
}

.description_wrapper {
  position: relative;
  height: 135px;
  overflow: hidden;
  padding-top: 16px;
  transition: height 0.15s;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0) 50px);

  &[style*='height'] {
    mask-image: unset;
  }

  @media screen and (min-width: 768px) {
    mask-image: unset;
    height: fit-content;
    flex-basis: 60%;
  }

  @media screen and (min-width: 1550px) {
    padding: 0;
    max-width: 920px;
    flex: none;
  }
}

.paragraph {
  white-space: pre-line;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);

  &:first-child {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .addNewPayerBtn {
    width: auto;
    margin: 33px 0 5px 0;
  }

  .select {
    max-width: 280px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .payersList,
  .formFieldsBlock {
    max-width: 70%;
  }
}
