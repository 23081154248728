@use '@scss/media.scss' as media;

.btn_more {
  margin-bottom: 30px;
  font-size: 18px;
  border-bottom: 1px solid;
}

.paragraph {
  font-size: 16px;
  font-weight: 400;
}

.filter_wrapper {
  position: relative;
  display: flex;
  column-gap: 15px;
}

.icon_btn {
  width: 45px;
  height: 45px;
  // background-color: var(--forms_bgc_1);
  // box-shadow: var(--forms3_shadow);
}

.calendarBlock {
  position: relative;
}

.calendarModal {
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  z-index: 1;
}

.calendar_pointer {
  right: 24px;
  top: auto !important;
  bottom: 100% !important;
  transform: translateX(50%) rotate(180deg) !important;
}

.btn_search {
  width: 100%;
  height: 50px;
  max-width: 280px;
  margin: 30px 0;
  font-size: 18px;
  text-transform: uppercase;
}

.table_title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
}

.error_msg {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  text-align: center;

  color: var(--system-error);
  font-size: 16px;
}

.chart_wrapper {
  padding: 24px;
  border-radius: 24px;
  background: var(--primary-white);
  backdrop-filter: blur(50px);
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  gap: 24px;

  @include media.minw-1024 {
    gap: 40px;
  }

  canvas {
    width: 100% !important;
    max-height: 85%;
  }
}

.chart_placeholder {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  width: max-content;
  max-width: 80%;
  color: var(--transparent-color);
  font-size: 16px;
  text-align: center;
  background-color: var(--main-bgc);
}

@media screen and (min-width: 768px) {
  .description_wrapper {
    height: fit-content;
    max-width: 1240px;
    margin-bottom: 38px;
  }

  .filter_wrapper {
    width: 345px;
  }

  .paragraph {
    font-weight: 300;
  }

  .max_content_width {
    position: relative;
    max-width: 930px;
  }

  .tablet_wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 40px;
  }

  .btn_search {
    width: 230px;
    margin: 0;
  }

  .select_wrapper {
    width: 280px;
  }
  .calendarModal {
    right: 50%;
    transform: translateX(50%);
  }

  .calendar_pointer {
    right: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .description_wrapper {
    padding-top: 30px;
  }

  .chart_placeholder {
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .icon_btn {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: 1550px) {
  .paragraph {
    font-size: 18px;
  }
}
