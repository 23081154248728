@use '@scss/media.scss' as media;

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box {
  padding: 16px;

  &:first-child {
    z-index: 1;
  }

  @include media.minw-1024 {
    padding: 24px;
  }
}

.software_OS_List {
  display: grid;
  grid-template-columns: var(--grid);

  grid-auto-rows: 1fr;
  gap: 12px;

  @media (min-width: 768px) {
    gap: 20px;
  }
}

.section_title {
  margin-bottom: 16px;
}

.parameters_list {
  display: grid;
  grid-template-columns: var(--grid);

  gap: 12px;

  @media screen and (min-width: 768px) {
    gap: 20px;
  }
}

.input_field_wrapper {
  padding: unset;
}

.selectWithSale {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;

  .sale55Icon {
    background: linear-gradient(180.08deg, #ff6f66 12.11%, #fa487d 98.37%);
    margin-right: 5px;
    padding: 3px;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    color: #ffffff;
  }
}

.saleSpan {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.memorySale {
  text-decoration: line-through;
  color: var(--red-text);
  margin-right: 5px;
  overflow: initial !important;
}
