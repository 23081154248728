.title {
  font-weight: 600;
  font-size: 18px;
}

.buy_btn {
  height: 50px;
  max-width: 250px;
  margin: 0 auto 0;
  text-transform: uppercase;
  font-size: 16px;
  text-wrap: balance;
}

@media screen and (min-width: 768px) {
  .buy_btn {
    font-size: 18px;
  }
}
