.modal {
  max-width: 500px;
}


.alert_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .alert_text {
    font-size: 18px;
  }
}
