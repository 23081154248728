@import '@scss/placeholders.scss';
@import '@scss/common.scss';

.phone_label {
  margin-left: 20px;
  margin-bottom: 6px;
  font-size: 12px;
  text-transform: uppercase;
}

.container {
  position: relative;
  z-index: 6;
  width: 100%;

  &.opened {
    z-index: 8;

    & .react_phone_input.lang {
      border: 1px solid var(--primary-dark);
    }

    .error_message {
      position: relative;
      z-index: -2;
    }
  }
}

.lang_btn {
  [class*='selected-flag'] {
    width: 45px;
    height: 100%;
    padding-left: 15px;
  }
  &.extra_style.opened {
    z-index: unset;
    border: none;
    background: unset;
    &.opened {
      & > div {
        background: unset;
      }
    }

    // & > div[aria-expanded] {
    //  background-color: var(--forms_bgc_4);
    // }
    // &.lightTheme > div[aria-expanded] {
    // }

    &.lightTheme {
      &:hover > div {
        background-color: unset;
      }
    }

    &:hover > div {
      background-color: unset;
    }
  }
}

.required_star {
  color: var(--system-error);
  font-size: 14px;
}

.react_phone_input.lang {
  @extend .input_base_styles;

  width: 100%;
  height: 48px;
  padding-left: 50px;
  &:disabled {
    color: var(--transparent-color);
  }

  @media screen and (min-width: 1024px) {
    height: 56px;
  }
}

.drop_down {
  &.list {
    background-color: var(--primary-white);
    width: 100%;
    border-radius: 0 0 24px 24px;
    margin: unset;

    border: 1px solid var(--other-dark-10);
    box-shadow: unset;
    top: calc(100% - 25px);
    padding-top: 30px;

    &.background {
      background: var(--primary-light-purple);
    }
  }

  &.list {
    &.lightThemeDrop {
      &.list_hover {
        [class*='cartModal'] & {
          width: calc(90vw - 45px);
          max-width: 645px;
          @media (min-width: 768px) {
            width: 295px;
          }
        }
        // width: 250px;
        // &::-webkit-scrollbar {
        //   width: 4px;
        // }
        // &::-webkit-scrollbar-track {
        //   background: #e9e9e9;
        //   border-radius: 5px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   background: var(--accent-gradient);
        //   border-radius: 5px;
        // }

        & li[aria-selected],
        & li:hover {
          background: unset;
          span {
            color: var(--primary-pink);
          }
        }
      }
    }

    // &.list_hover {
    //   & li[aria-selected] {
    //     background-color: var(--forms_bgc_4);
    //   }
    //   & li:hover {
    //     background-color: var(--forms_bgc_4);
    //   }

    //   & span:last-child {
    //     color: var(--main-text);
    //   }
    // }
  }
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

.field_icon_right {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 2;

  &.interactive_icon {
    cursor: pointer;

    &:hover {
      color: var(--primary-pink);
    }
  }
}

.hint {
  max-width: 300px;
}

.info_btn {
  position: absolute;
  margin-left: auto;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  &:hover {
    svg {
      path {
        opacity: 1;
        color: var(--primary-pink);
      }
    }
  }
}
