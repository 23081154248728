.item {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  padding-top: 76px;
  user-select: text;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
  display: flex;
  align-items: center;
}

.value {
  font-size: 15px;
  word-break: break-word;
  &.withIcon {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &.value_edit {
    max-width: 150px;
    width: 150px;
  }
}

.hide_lont_text {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
  margin-top: 5px;
}

.HintWrapper {
  max-width: 300px !important;
  white-space: break-all;
}

.tools_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}
