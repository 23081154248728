.enter {
  opacity: 0;
  transform: scale(0);
}

.enterActive {
  opacity: 1;
  transform: scale(1);
  transition: opacity 150ms, transform 150ms;
}

.exit {
  opacity: 1;
  transform: scale(1);
}

.exitActive {
  opacity: 0;
  transform: scale(0);
  transition: opacity 150ms, transform 150ms;
}
