.item {
  min-height: 62px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  gap: 10px;
  position: relative;
  user-select: text;

  &.active_server > .value:first-child {
    color: var(--accent-color-light);
  }
}

.item_wrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 25px;
  align-items: center;
}

.value {
  font-size: 15px;
  text-align: start;

  &:nth-child(1) {
    flex-basis: 10%;
    width: 10%;
    flex-shrink: 0;
  }
  &:nth-child(2) {
    flex-basis: 7%;
    width: 7%;
    word-break: break-all;
  }
  &:nth-child(3) {
    flex-basis: 10%;
    width: 10%;
    flex-shrink: 0;

    span {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &:nth-child(4) {
    flex-basis: 10%;
    width: 10%;
  }
  &:nth-child(5),
  &:nth-child(6) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-break: break-word;
  }

  &:nth-child(5) {
    flex-basis: 10%;
    width: 10%;
  }
  &:nth-child(6) {
    flex-basis: 8%;
    width: 8%;
  }
  &:nth-child(7) {
    flex-basis: 8%;
    width: 8%;
  }
  &:nth-child(8) {
    flex-basis: 13%;
    width: 13%;
  }
  &:nth-child(9) {
    flex-basis: 8%;
    width: 8%;
  }

  &:nth-child(10) {
    flex-basis: 12%;
    width: 12%;
  }

  &.dedic {
    &:nth-child(1) {
      flex-basis: 10%;
      width: 10%;
      flex-shrink: 0;
    }
    &:nth-child(2) {
      flex-basis: 7%;
      width: 7%;
    }
    &:nth-child(3) {
      flex-basis: 10%;
      width: 10%;
      flex-shrink: 0;
      span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:nth-child(4) {
      flex-basis: 8%;
      width: 8%;
    }
    &:nth-child(5) {
      flex-basis: 10%;
      width: 10%;
    }
    &:nth-child(6) {
      flex-basis: 20%;
      width: 20%;
    }
    &:nth-child(7) {
      flex-basis: 8%;
      width: 8%;
    }
    &:nth-child(8) {
      flex-basis: 6%;
      width: 6%;
    }
    &:nth-child(9) {
      flex-basis: 8%;
      width: 8%;
    }
    &:nth-child(10) {
      flex-basis: 8%;
      width: 8%;
    }
    &:nth-child(11) {
      flex-basis: 22px;
      width: 22px;
    }
  }
}

.hint {
  width: 100%;
}

.HintWrapper {
  width: 130%;
  word-break: break-all;
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.options_dopdown {
  @media (min-width: 1024px) {
    &:after {
      content: '';
      width: 100%;
      height: 75px;
      position: absolute;
      left: 0;
      top: 100%;
      pointer-events: none;
    }
  }
}
