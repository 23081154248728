.footer {
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 24px;
  font-size: 16px;
  @media (max-width: 576px) {
    justify-content: center;
  }
  @media (min-width: 1024px) {
    position: absolute;
  }
}

.tools_footer_value {
  font-weight: 600;
}

.total_price {
  color: var(--primary-pink);
}

.buttons_wrapper {
  display: flex;
  gap: 8px;
  margin-left: auto;
  @media (max-width: 576px) {
    width: 100%;
    justify-content: center;
  }
}
