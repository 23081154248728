.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
  &.error {
    border: 1px solid var(--system-error);
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background-color: var(--primary-white);
  border-radius: 6px;
  border: 1px solid var(--other-dark-20);
  color: var(--primary-white);

  &.active {
    background-color: var(--primary-pink);
    border-color: var(--primary-pink);
  }
}

.switcher {
  height: 23px;
  width: 44px;
  border-radius: 25px;
  background-color: var(--switchers-bg);
  // box-shadow: var(--switchers-shadow);

  &.active {
    // box-shadow: var(--switchers-shadow-active);
    background: var(--accent-gradient);
  }

  .circle {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: var(--switchers-dot-bg);
    // box-shadow: var(--switchers-dot-shadow);
    transform: translateX(0px);
    transition: 0.4s ease;

    &.active {
      transform: translateX(22px);
      // box-shadow: var(--switchers-dot-shadow-active);
      background-color: var(--primary-white);
    }
  }
}

.circleCheck {
  border: 1px solid var(--other-dark-20);
  border-radius: 100px;
  padding: 8px 14px;

  &:hover {
    border-color: var(--primary-pink);
    color: var(--primary-pink);
  }

  &.active {
    border-color: var(--primary-pink);
    background: var(--primary-pink);
    color: var(--primary-white);
  }
}

.radio {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--primary-white);

  &.active {
    border: 1px solid #fa4bb4;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(270deg, #fa4bb4 0%, #ed32cf 100%);
    transform: scale(0);
    &.active {
      transition: 0.3s ease;
      transform: scale(1);
    }
  }
}
