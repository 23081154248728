.modal {
  background-color: var(--main-bgc);
}

.ownerForm {
  height: fit-content;
  padding-bottom: 18px;
}

.input {
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;
  padding-bottom: 0;
}

.saveBtn {
  height: 55px;
  text-transform: uppercase;
}

.formFieldsBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.category_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: var(--main-text);
  margin-top: 20px;
}

.select {
  max-width: 100%;
  margin-top: 10px;
  margin-right: 0px;

  button {
    height: 52px;
  }

  &.dateSelect {
    width: 195px;
    margin-right: 20px;
  }
}

.dates {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  color: var(--main-text);

  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
}

.formBlockTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 115%;
  color: var(--main-text);
  margin: 20px 0 0 0;
}

.phoneInput {
  width: 100%;
  max-width: 100%;
  margin: 13px 0 0 0;
}

.phoneInputLabel {
  margin: 0 0 5px 10px !important;
}

.useFirstCheck {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 5px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 70.9%;
    color: var(--main-text);
    margin-left: 18px;
  }
}

.profileWarn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--system-error);
}

.shevronIcon {
  margin-left: 16px;
  stroke: var(--main-text);
  transform: rotate(180deg);
  transition: linear 0.1s;

  &.isOpen {
    transform: rotate(0deg);
  }
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.namesBlock {
  padding: 10px 50px 0px;
}

.warning_text {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.hidden_area {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
  margin-top: 8px;
}

.item {
  color: var(--transparent-color);
  font-size: 14px;
}

.services_names_wrapper {
  display: inline;
  transition: max-height 1s;

  &.active {
    display: block;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(161, 161, 161, 23%);
    }

    &::-webkit-scrollbar-thumb {
      background: #e72690;
    }

    max-height: 75px;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 768px) {
  .input {
    width: 47%;

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }

  .select {
    max-width: 47%;
    margin-top: 10px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 220px;
      width: 220px;
      margin-right: 10px;
    }

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }

  .phoneInput {
    max-width: 47%;
    margin: 13px 15px 0 0;
  }

  .phoneInputLabel {
    margin: 0 0 4px 10px !important;
  }
}
