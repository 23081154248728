.table {
  .w_limit {
    width: 150px;
  }
}

.tableHeader {
  display: none;
  padding: 12px 23px;
  width: 100%;
  &Wrapper {
    margin-bottom: 40px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  &.first_item {
    color: var(--link-text);
  }
  &.third_item {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
  &.fourth_item {
    color: #45a884;
  }
  &.fifth_item {
    display: none;
  }
}

.openTicket {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--menu-text-3);
  align-items: center;
  margin-right: 20px;
  &.first_item {
    flex-basis: 17.8%;
  }
  &.second_item {
    flex-basis: 34.5%;
  }
  &.third_item {
    flex-basis: 22.19%;
  }
  &.fourth_item {
    flex-basis: 19.7%;
  }
  &.fifth_item {
    flex-basis: 5%;
  }
}

.item {
  user-select: none;
  flex-direction: column;
  padding: 40px 23px 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  &:hover::before,
  &.selected::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    background: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
    left: 0;
    top: 0;
    box-shadow: 0px 1px 8px rgb(65 38 114 / 15%);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;

    .checkbox {
      margin: 0 0 -45px 20px;
      z-index: 5;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 7px;
    padding-top: 20px;
    &_wrapper {
      align-items: center;
      flex-direction: row;
      gap: 25px;
      .checkbox {
        margin: 0;
      }
    }
  }
}

.tableCheckbox {
  &Wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 20px;
  }
  @media (min-width: 768px) {
    &Wrapper {
      margin-left: 0;
    }
    &Text {
      display: none;
    }
  }
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text_time {
  color: var(--transparent-color);
}

.icon_wrapper {
  width: 40px;
  height: 40px;
  background-color: var(--primary-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unread {
  color: var(--primary-pink);
}

.chatBtn {
  cursor: pointer;
  &:active {
    transform: scale(0.95);
  }
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourth {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
}

.tableBlockFifth {
  grid-column: span 2;
}

.line {
  border: 1px solid #e8e3f3;
  width: 100%;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .openTicket {
    display: none;
  }
  .item {
    flex-direction: row;
    align-items: center;
    padding: 23px 35px;
  }

  .tableHeader {
    display: flex;
    padding: 10px 35px;

    &Wrapper {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }

  .item_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    max-width: 150px;
    color: var(--main-text);
    align-items: center;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    &.first_item {
      color: var(--link-text);
    }
    &.second_item {
      min-width: 100%;
    }
    &.third_item {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;
    }
    &.fourth_item {
      color: #45a884;
    }
    &.fifth_item {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1024px) {
  .item {
    padding: 20px 40px;
  }
  .tableHeader {
    padding: 11px 40px;
  }
  .item_text {
    font-size: 16px;
    &.third_item {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 1550px) {
  .item {
    padding: 22px 45px;
  }
  .tableHeader {
    padding: 12px 45px;
  }
}

@media screen and (min-width: 1918px) {
  .item_text {
    font-size: 17px;
  }
  .item {
    padding: 25px 52px;
  }
  .tableHeader {
    padding: 12px 52px;
  }
}

.checkbox_wrapper {
  margin: -24px -24px 10px;
  padding: 24px 24px 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.mobile_list {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.body {
  margin-top: 16px;

  > * {
    &:nth-child(odd) {
      white-space: nowrap;
    }
  }
}

.abuse {
  path {
    fill: var(--system-error);
  }
}

.abuse_tooltip {
  max-width: 300px;
  text-align: center;
}

.line_clamp {
  white-space: initial;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
}

.check_all {
  margin: 0 0 15px 16px;
}
