.checkRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0;
}

.notifName {
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 130.5%;

  flex-basis: 100%;
  padding: 15px 20px;
  border-radius: 24px;
  background-color: var(--forms_bgc_2);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.columnBlock {
  flex-basis: 100%;
  width: 100%;
  display: flex;
  gap: 24px;
  padding: 15px 20px;
  border-radius: 24px;
  background-color: var(--forms_bgc_2);

  margin-top: 5px;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;

  gap: 16px;
  // flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-basis: 45%;
  }
}

.toggleName {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;

  // margin-bottom: 17px;
}

.shevron {
  stroke: var(--main-text);
  pointer-events: none;
  cursor: pointer;
  stroke: var(--eye-color);
  transform: rotateZ(180deg);
  transition: transform 0.15s;

  &.opened {
    transform: rotate(0);
  }
}

.mobileColumnBlock {
  opacity: 0;
  max-height: 0px;
  padding: 0;
  transition: all 0.15s;
}

.mobileColumnBlockOpened {
  opacity: 1;
  max-height: 300px;
  height: 100%;
  padding: 15px 20px;
  transition: all 0.15s;
}

@media screen and (min-width: 768px) {
  .shevron {
    display: none;
  }
  .columnBlock {
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    flex-basis: 57%;
    margin-top: 0;
  }
  .checkRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
  }

  .notifName {
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    flex-basis: 43%;
  }

  .toggleName {
    display: none;
  }
}
