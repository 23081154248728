.page_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: var(--main-text);
}
.order_id {
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
}

.status_wrapper {
  display: flex;
  margin-bottom: 20px;
}

.creation_date_wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.expiration_date_wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 800;
  font-size: 14px;
  line-height: 115%;
}

.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
}

.main_title,
.additional_title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: var(--main-text);
}

.select {
  margin-bottom: 12px;
}

.input_field_wrapper {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.input {
  height: 50px;
}

.total_amount {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.price {
  font-weight: 600;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.order_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  margin-bottom: 20px;
  color: var(--main-text);
}

.buy_btn {
  max-width: none;
  height: 55px;
  font-weight: 800;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .modal {
    width: 356px;
  }

  .page_title {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .additional_block {
    display: flex;
    justify-content: space-between;
  }
}
