.modal {
  max-width: 870px;
  &:not(.visible) {
    opacity: 0;
  }
}

.paymentMethodsList {
  display: grid;
  gap: 15px 20px;
}

.modalBg {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 149;
  overflow-y: hidden;
}

.payersList {
  margin-top: 15px;
}

.field_wrapper {
  width: 100%;
  position: relative;
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 10px;
  grid-column: 1/4;
}

.field_bg {
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms_shadow_site);
}

.paymentMethodBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-basis: 100%;
  height: 48px;
  padding: 8px 8px 8px 16px;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }

  .descrWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    height: 56px;
  }
}

.crossIcon {
  fill: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.clearFilters {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-decoration-line: underline;
  color: var(--main-text);
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  height: 55px;
}

.select {
  max-width: 100%;
  margin-top: 15px;
}

.warn {
  padding: 7px;
  background-color: var(--red-bg);
  margin-top: 25px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: var(--main-text);
  }
}

.error_message_addpayer {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 10px;
}

.inputAmountBlock {
  display: flex;
  align-items: center;
  margin-top: 15px;
  flex-basis: 100%;
  position: relative;

  .input {
    width: 100%;
  }

  .currency {
    position: absolute;
    right: 20px;
    top: 13px;
    z-index: 1;
    font-weight: 400;
    font-size: 17px;
    line-height: 130.5%;
    color: var(--main-text);
  }

  .inputHeight {
    input {
      padding-right: 70px;
    }
  }
}

@media screen and (min-width: 768px) {
  .field_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 20px;
  }

  .saveBtn {
    width: 254px;
  }
}

@media screen and (min-width: 1281px) {
  .infoBtn {
    top: 26px;
  }
}
