.item_wrapper {
  padding: 8px;

  &.profile {
    padding: 0;
  }
}

.list {
  position: relative;
  display: none;
  padding: 32px 8px 8px 34px;

  background: var(--primary-white);

  margin-top: -32px;
  border-radius: 0 0 32px 32px;

  &.opened {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &.profile {
    padding: 16px 16px 16px 80px;
  }

  & > .list_item:first-child {
    margin-top: 16px;
  }
}

.profile_wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user_name {
  font-size: 18px;
  font-weight: 500;
}

.list_name {
  &.opened {
    color: var(--primary-pink);
  }
}

.email {
  font-size: 14px;
  line-height: 70.9%;

  color: var(--other-dark-50);
}

.list_item_header {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--primary-white);
  border-radius: 100px;
  z-index: 2;
  gap: 10px;

  &.profile {
    padding: 8px 16px 8px 8px;
    gap: 16px;
  }
}

.open_list_icon {
  margin-left: auto;
  margin-right: 26px;
  transform: rotateZ(-90deg);

  &.closed {
    transform: rotateZ(90deg);
  }
}

.list_item_link {
  color: var(--other-dark-50);
}
