@use '@scss/media.scss' as media;

.formFieldsBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: flex-start;
}

.inputHeight {
  height: 55px;
}

.input {
  max-width: 80%;
  margin-top: 13px;

  @include media.minw-768 {
    max-width: 100%;
  }
}

.addNs {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100.5%;
  color: var(--primary-pink);
  border-bottom: 0.5px solid var(--primary-pink);
  cursor: pointer;
}

.useZomroNS {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100.9%;
  color: var(--main-text);

  button {
    color: var(--primary-pink);
    border-bottom: 0.5px solid var(--primary-pink);
    line-height: 70.9%;
    cursor: pointer;
  }
}

.inputBlock {
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  .deleteNs {
    position: absolute;
    right: 0px;
    bottom: 5px;

    cursor: pointer;
  }

  @include media.minw-768 {
    max-width: 48%;

    .deleteNs {
      right: -60px;
      bottom: 0px;
    }
  }
}

.formFieldsBlock > div:nth-child(2n) {
  @include media.minw-768 {
    margin-right: 0px;
  }
}
