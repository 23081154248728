.table {
  .amount_cell {
    width: 100px;
    text-align: right;
  }
  .total {
    font-weight: 600;
    font-size: 1.1em;
  }
}
