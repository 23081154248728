@use '@scss/media.scss' as media;
@import '@scss/placeholders.scss';

.wrapper {
  position: relative;
  width: max-content;
  height: fit-content;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.backdrop {
  @include media.minw-768-maxw-1024 {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: rgba(19, 16, 25, 0.3);
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    transition: opacity 0.4s ease;

    &.opened {
      opacity: 1;
      pointer-events: fill;
    }
  }
}

.btn {
  position: relative;
  background-color: var(--primary-white);
  color: var(--primary-dark);

  &:hover {
    color: var(--primary-white);
    background-color: var(--primary-pink);
  }

  &.opened {
    z-index: 6;
    color: var(--primary-white);
    background-color: var(--primary-pink);
  }
}

.dropdown {
  border-radius: 24px;
  background-color: var(--primary-pink-16);
  backdrop-filter: blur(35px);
  border: 1px solid var(--primary-pink);
  position: fixed;
  z-index: 20;
}

.tools__list {
  padding: 15px;
  display: grid;
  gap: 15px 5px;
}

.modal__list {
  display: grid;
  gap: 10px;
}

.tile_wrapper {
  grid-template-columns: repeat(6, 110px);
  grid-template-rows: repeat(2, 110px);

  padding: unset;
  gap: 10px 4px;
  flex-shrink: 0;
}

.tile_btn {
  display: flex;
  flex-direction: column;
  white-space: unset;
  height: 100%;
  svg {
    height: 24px;
  }

  @include media.minw-1024 {
    text-wrap: balance;
  }
}

.tool_item {
  white-space: nowrap;

  &.modal_item {
    list-style: none;
  }
}

.tool_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  padding: 6px 8px 6px 4px;
  gap: 8px;
  border-radius: 3px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 300;

  text-align: left;
  border-radius: 100px;

  &.whiteBackground {
    font-weight: 400;
    &.tool_btn_delete {
      .tool_icon__wrapper.mobile {
        background: var(--primary-pink);

        .tool_icon {
          color: var(--primary-white);
        }
      }
    }
  }

  .tool_icon__wrapper {
    background-color: var(--primary-white);

    &.mobile {
      background-color: var(--primary-light-purple);
    }
  }

  &.tile_btn {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px;
    gap: 4px;

    border-radius: 16px;
    background-color: var(--primary-white-30);

    @include media.maxw-1024 {
      justify-content: flex-start;
    }

    &.delete_white {
      .tool_icon__wrapper {
        background-color: transparent;
      }

      .tool_icon {
        color: var(--primary-pink);
      }
    }

    &:hover {
      background-color: var(--primary-white);
      color: var(--primary-pink);

      .tool_icon {
        color: var(--primary-pink);
      }

      &.delete_white {
        color: var(--primary-white);
        .tool_icon {
          color: var(--primary-white);
        }
      }
    }
  }

  &:hover {
    background-color: var(--primary-pink);
    color: var(--primary-white);
    .tool_icon__wrapper {
      background-color: transparent;
    }
    .tool_icon {
      color: var(--primary-white);
    }
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.tool_btn_delete {
  color: var(--primary-pink);
  background: var(--primary-white);

  .tool_icon {
    color: var(--primary-pink);
  }

  &.delete_white {
    .tool_icon__wrapper {
      background: var(--primary-pink);
    }

    .tool_icon {
      color: var(--primary-white);
    }

    &:hover {
      background: var(--primary-pink);
    }
  }
}

.tool_icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: var(--primary-light-purple);

  @include media.minw-1024 {
    background-color: unset;
  }

  &.services_icons {
    background: transparent;
  }
}

.tool_icon {
  width: 20px;
  height: 20px;
  color: var(--primary-pink);

  &:hover {
    color: var(--primary-pink);
  }

  @include media.minw-1024 {
    &.tile_icon {
      color: var(--other-dark-50);
    }
    width: 24px;
    height: 24px;
  }
}
