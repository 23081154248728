.tableHeader {
  display: none;
  padding: 20px 23px 13px;
  align-items: center;
  width: 100%;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
  align-items: center;
  margin-right: 20px;
  &.first_item {
    flex-basis: 12.6%;
  }
  &.second_item {
    flex-basis: 24.5%;
  }
  &.third_item {
    flex-basis: 11.3%;
  }
  &.fourth_item {
    flex-basis: 14.3%;
  }
  &.fifth_item {
    flex-basis: 19.1%;
  }
  &.sixth_item {
    flex-basis: 10.5%;
  }
}

.item {
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.columnsWithoutCheckBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
}

.headerColumnsWithoutCheckBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.checkBoxColumn {
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}

.dots {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;
}

.dotIcons {
  &.opened,
  &:hover {
    fill: url(#gradient);
  }
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourth,
.tableBlockFifth,
.tableBlockSixth,
.tableBlockSeventh,
.tableBlockEighth {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 5px;
  align-items: baseline;
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.line {
  border: 1px solid #e1daea;
  width: 100%;
  margin-bottom: 15px;
}

.mobileBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btnsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileBtn {
  flex-basis: 32%;
  padding: 10px 0;
  background: var(--forms_bgc_4);

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  color: var(--main-text);
  box-shadow: var(--forms2_shadow);

  svg {
    width: 16px;
    margin-bottom: 5px;
  }

  svg,
  path {
    fill: var(--transparent-color);
  }

  &:hover {
    svg,
    path {
      fill: url(#gradient);
    }
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
}

@media screen and (min-width: 600px) {
  .item_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1550px) {
  .checkBoxColumn {
    flex-basis: 3.5%;
    margin: 0;
    padding: 0;
    margin-right: 23px;
    border-bottom: none;
  }

  .headerColumnsWithoutCheckBox {
    padding: 0 15px;
  }

  .columnsWithoutCheckBox {
    flex-direction: row;
    padding: 21px 15px;
    border-radius: 3px;
    background-color: var(--forms_bgc_2);
    box-shadow: var(--forms_shadow);
  }

  .dots {
    position: relative;
    top: 0;
    right: 0;
    // display: none;
  }

  .tableHeader {
    display: flex;
    padding: 20px 0px 13px;
  }

  .tableBlockFirst,
  .tableBlockSecond,
  .tableBlockThird,
  .tableBlockFourth,
  .tableBlockFifth,
  .tableBlockSixth,
  .tableBlockSeventh,
  .tableBlockEighth {
    margin-top: 0px;
    margin-right: 20px;
  }

  .tableBlockFirst {
    flex-basis: 12.6%;
  }

  .tableBlockSecond {
    flex-basis: 24.5%;
  }

  .tableBlockThird {
    flex-basis: 11.3%;
  }

  .tableBlockFourth {
    flex-basis: 14.3%;
  }

  .tableBlockFifth {
    flex-basis: 19.1%;
  }

  .tableBlockSixth {
    flex-basis: 10.5%;
  }

  .item_text {
    font-size: 16px;

    &.first_item {
      max-width: 140px;
    }

    &.second_item {
      max-width: 160px;
    }

    &.third_item {
      max-width: 160px;
      word-break: break-word;
      white-space: break-spaces;
    }

    &.fourth_item {
      max-width: 160px;
    }

    &.fifth_item {
      max-width: 160px;
    }

    &.sixth_item {
      max-width: 160px;
    }

    &.seventh_item {
      max-width: 130px;
    }
  }
}

@media screen and (min-width: 1918px) {
  .headerColumnsWithoutCheckBox {
    padding: 0 41px;
  }

  .tableHeader {
    padding: 22px 0px 13px;
  }

  .item_text {
    font-size: 16px;
  }
}

.check_all {
  margin: 0 0 15px 16px;
}

.options_dopdown {
  @media (min-width: 1024px) {
    &:after {
      content: '';
      width: 100%;
      height: 75px;
      position: absolute;
      left: 0;
      top: 100%;
      pointer-events: none;
    }
  }
}

.tools_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}
