.crumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.linksBlock {
  &:not(:first-child) {
    margin-left: 5px;
  }

  &:not(:last-child) {
    .links {
      margin-right: 5px;
    }
  }

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

.slash {
  color: var(--primary-pink);
}

.links {
  color: var(--primary-pink);

  &.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
  &.hidden {
    &,
    & + .slash {
      display: none;
    }
  }
}

// @media screen and (min-width: 768px) {
//   .crumbs {
//     margin-bottom: 40px;
//   }
// }
