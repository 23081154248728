@import '@scss/placeholders.scss';

.field_wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.input_wrapper {
  width: 100%;
  height: 48px;
  position: relative;
}

.input {
  width: 100%;
  height: 100%;
  padding-left: 46px !important;

  &:focus {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    border-color: transparent;
  }

  &.disabled {
    opacity: 1;
    -webkit-text-fill-color: var(--transparent-color);
    color: var(--transparent-color);
  }
}

.field_icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
  border-radius: 50%;
}

.pass_show_btn {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  &.shown {
    display: flex;
  }
}

.right_icon {
  // width: 10px;
  // height: 6px;
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  stroke: var(--eye-color);
  transform: translateY(-50%) rotateZ(180deg);
  transition: transform 0.15s;

  &.opened {
    transform: translateY(-50%) rotate(0);
  }
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

.countries_dropdown {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  padding-top: 12px;
  height: fit-content;
  border-radius: 3px;
  z-index: 151;
  box-shadow: 0px 1px 8px rgba(65, 38, 114, 0.15);
  // background-color: var(--nearby-elements-bgc);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 15px;
    background-image: linear-gradient(
      0,
      var(--nearby-elements-bgc) 0%,
      rgba(255, 255, 255, 0.001%)
    );
  }
}

.countries_list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: fit-content;
  padding-left: 12px;
  padding-bottom: 12px;
  max-height: 132px;

  &::-webkit-scrollbar {
    width: 27px;
    // border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    border-top: 10px transparent solid;
    border-bottom: 10px transparent solid;
    border-right: 24px transparent solid;
    background-clip: padding-box;
    background-color: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    min-height: 35px;
    border-top: 10px transparent solid;
    border-bottom: 10px transparent solid;
    border-right: 24px transparent solid;
    background-clip: padding-box;
    background-color: var(--primary-pink);
  }
}

.country_item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.country_btn {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: start;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
}

.country_img {
  margin-right: 12px;
}

.country_name {
  font-size: 18px;
}

.backdrop {
  @extend %backdrop;
  background-color: transparent;
  pointer-events: initial;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .input {
    padding-left: 36px;
  }

  .countries_list {
    max-height: 162px;
  }
}

@media screen and (min-width: 1024px) {
  .input_wrapper {
    height: 56px;
  }
}
