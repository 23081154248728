.modal {
  background: var(--main-bgc);
  width: fit-content;
  max-width: 95vw;
}
.modal__header {
  padding: 5px 10px;
  box-shadow: none;
}
.modal__body {
  padding: 0 10px 10px;
}
