.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.check_icon {
  stroke: var(--forms_bgc_2);
}

.green_icon {
  fill: var(--system-success);
  width: 20px;
  height: 20px;
}

.delProgress {
  circle {
    fill: var(--system-error);
  }
}

.status {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 10px;

  color: var(--system-orange-dark);
  display: inline-block;
  border-radius: 10px;
  line-height: 1;
  position: relative;
  background-color: var(--system-orange-light);

  &.with_icon {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 5px 22px 5px 10px;

    svg {
      position: absolute;
      right: 5px;
      width: 14px;
      height: 14px;
      circle {
        fill: #fff;
      }
      path {
        fill: var(--system-orange-dark);
      }
    }

    &.error {
      fill: var(--system-red-dark);
    }
  }

  &.success {
    background-color: var(--system-green-light);
    color: var(--system-green-dark);
  }
  &.error {
    background-color: var(--system-red-light);
    color: var(--system-red-dark);
  }
}
