.list_item_title {
  margin-bottom: 10px;
  padding: 12px 20px;

  background: var(--access_rights_bg_item_title);

  display: flex;
  align-items: center;
  justify-content: space-between;

  .shevron {
    cursor: pointer;
  }

  &.opened {
    background: var(--access_rights_bg_item_title_gradient);
    .shevron {
      transform: rotateZ(0deg);
      stroke: url(#gradient);
    }

    .list_item_title_text {
      svg {
        path {
          fill: url(#gradient);
        }
      }
    }
  }
}

button {
  flex: none;
}

.list_item_title_text {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 113%;
  color: var(--main-text);

  display: flex;
  align-items: center;
  flex-direction: row;

  cursor: pointer;

  svg {
    margin-right: 10px;

    path {
      fill: var(--icon-color_2);
    }
  }
}

.list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 6px;
  margin-bottom: 12px;
  cursor: pointer;

  &.opened {
    margin-bottom: 10px;
    & .shevron {
      transform: rotateZ(0deg);
      stroke: url(#gradient);
    }
  }
}

.list_item_wrapper {
  display: none;
  &:nth-child(1) {
    padding: 0px 14px 10px 14px;

    &.inserted_item_wrapper {
      padding: 0px 14px 0px 14px;
    }
  }

  &.opened {
    &:nth-child(1) {
      padding-top: 12px;
      padding-bottom: 10px;
      margin-top: -18px;
    }
    background-color: var(--sublist-bgd);
    margin-bottom: 12px;
  }

  &.showed {
    display: block;
  }
}

.isToggleBlock {
  padding-right: 45px;

  &.inserted {
    padding-right: 50px;
  }
}

.isToggleBlockSelectAll {
  padding-right: 30px;
}

.withoutSubItems {
  margin-right: -10px;
}

.selectedAllBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  .insered {
    padding-right: 20px;
  }
}

.list_item_subtitle {
  font-weight: 300;
  width: 50%;
  font-size: 16px;
  line-height: 115%;
  text-align: start;
  color: var(--main-text);
  margin-right: 15px;
  word-wrap: break-word;
}

.sub_list {
  position: relative;
  display: none;

  padding-left: 8px;

  & .list_item_wrapper {
    &:nth-child(1) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  & .list_item {
    padding-left: 0;
    padding-right: 0;
  }

  &.selected {
    display: block;
  }

  & .list_item_wrapper {
    margin-left: 15px;
  }

  & .allow_all_item {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    margin-bottom: 22px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
    background-color: var(--center-border-color-pink);
    border-radius: 5px;
  }

  &.inserted_sub_list {
    padding-left: 0;
    margin-bottom: 13px;
    margin-left: 0px;
    &::before {
      background-color: var(--center-border-color);
    }
  }
}

.shevron {
  stroke: var(--main-text);
  transform: rotateZ(180deg);
  transition: transform 0.2s ease;

  &.inserted {
    padding-right: 15px;
  }
}

.shevronInserted {
  margin-right: 5px;
  min-height: 25px;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .list_item_subtitle {
    width: 100%;
  }

  .sub_list {
    padding-left: 8px;

    & .list_item_wrapper {
      margin-left: 30px;
    }

    &.inserted_sub_list {
      margin-left: 0px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .list_item_title_text {
    font-size: 18px;
  }
  .list_item_subtitle {
    font-size: 18px;
  }
}
