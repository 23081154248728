.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sort {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}

.sort_active {
  svg {
    fill: var(--sort_svg);
  }
}

.popup {
  color: var(--transparent-color_2);
}

.popup__wrapper {
  display: flex;
  align-items: center;
  width: max-content;
}

.status_wrapper {
  .popup {
    width: max-content;
    max-width: 300px;
    text-wrap: initial;
    font-size: 14px;

    @media (max-width: 767px) {
      left: -40px;
      transform: unset;

      & > div {
        left: 34%;
      }
    }
  }
}

.status {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  line-height: 1;
  position: relative;
  background-color: var(--system-orange-light);

  &.suspended {
    svg {
      path {
        fill: var(--red-text);
      }
    }
  }
  &.runing,
  &.active {
    background-color: var(--system-green-light);
  }
  color: var(--system-green-dark);
  &.stopped,
  &.deletion_in_progress,
  &.suspended {
    background-color: var(--system-red-light);
    color: var(--system-red-dark);
  }
  &.saving {
    background-color: var(--system-orange-light);
    color: var(--system-orange-dark);
  }
}

.mobile__list {
  padding-top: 20px;
}

.mobile_item__header {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.mobile_item__header_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin-top: -5px;
}
.mobile_item__name {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 5px;
}

.no_images_wrapper {
  text-align: center;
  margin: 40px auto;
  max-width: 500px;
}

.no_images_title {
  color: var(--primary-purple);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 16px 0;
}

.sort_select {
  max-width: 240px;
}

.name_wrapper {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 5px;
}

.name_field_wrapper {
  display: flex;
  align-items: center;
  max-width: 160px;
  gap: 10px;
  > svg {
    flex-shrink: 0;
  }
}

.name_field {
  flex-grow: 1;
  width: 100%;
}

.list_item_name {
  width: unset !important;
}
