.list_wrapper {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 50;
  padding-bottom: 50px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item_button {
  padding: 20px 35px 20px 20px;
  border-radius: 3px;
  box-shadow: var(--forms2_shadow);
  width: 330px;
  max-width: 90vw;
  background-color: var(--forms_bgc_2);
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--accent-gradient);
  }
}

.item_text {
  text-align: left;
  font-size: 14px;
}

.item_close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 13px;
  opacity: 0.3;

  :hover > & {
    opacity: 1;
    fill: url(#gradient);
  }
}

.hide_all {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 15px;
  border-radius: 15px;
  background: var(--accent-gradient);
  font-weight: 600;
  color: #fff;
}
