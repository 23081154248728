@import '@scss/placeholders.scss';

.backdrop {
  @extend %backdrop;
  z-index: 200;

  &.shown {
    pointer-events: initial;
    opacity: 1;
  }

  &.main_lt {
    background: var(--other-gradient);
    display: flex;
    flex-direction: column;
  }
  &.main_dt {
    background: var(--other-gradient);
    display: flex;
    flex-direction: column;
  }
  &.local {
    position: absolute;
    z-index: 8;
  }
  &.transparent {
    background-color: transparent;
  }
  &.static {
    position: relative;
  }
  &.halfScreen {
    margin: 0 -16px;
    @media screen and (min-width: 768px) {
      margin: 0 -24px;
    }
    @media screen and (min-width: 1024px) {
      margin: 0 -24px -24px;
    }
  }
}

.loader__logo {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.7s;
}
