@import '@scss/placeholders.scss';

.field_wrapper {
  width: 100%;
  position: relative;
}

.label {
  display: block;
  margin-left: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
  text-align: start;
  font-weight: 400;
  font-size: 12px;
}

.label__tooltip {
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
}

.hint {
  max-width: 300px;
}

.input_wrapper {
  width: 100%;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.required_star {
  color: #d93f21;
  font-size: 16px;
  line-height: 1;
}

.input {
  height: 100%;
  min-width: 100%;

  &.error {
    border: 1px solid var(--system-error);
  }

  &.icon_right {
    padding-right: 40px;
    // z-index: 2;
    &.icon_generate {
      padding-right: 65px;
    }
  }

  &.disabled {
    opacity: 1;
    -webkit-text-fill-color: var(--transparent-color);
    color: var(--transparent-color);
  }

  &.number {
    @extend %input_type_number;
  }

  &.tooltip_btn_right {
    text-overflow: ellipsis;
  }
}

.field_icon_right {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 2;

  &.interactive_icon {
    cursor: pointer;

    &:hover {
      color: var(--primary-pink);
    }
  }
}

.pass_show_btn,
.generate_pass_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 60%;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.generate_pass_btn {
  right: 40px;
}

.info_btn {
  position: absolute;
  margin-left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  &:hover {
    svg {
      path {
        opacity: 1;
        color: var(--primary-pink);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .input {
    &.iconRight {
      padding-right: 36px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .input_wrapper {
    height: 56px;
  }
}
