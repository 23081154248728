.item_bell {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.header_btn {
    display: none;
  }

  &:hover {
    .btn {
      background: var(--primary-pink);
    }
    .bell {
      fill: var(--primary-white);
    }

    .notification_counter_wrapper {
      background: var(--primary-purple);
      border: 2px solid var(--primary-purple);
    }
  }
}

.bell {
  fill: var(--primary-dark);
}

.notification_messages {
  position: relative;
  font-size: 12px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-white);

  &.opened {
    z-index: 110;
  }
}

.notification_counter_wrapper {
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  border-radius: 25px;
  position: absolute;
  background: var(--accent-gradient);
  border: 2px solid var(--primary-white);
  right: 8px;
  top: 5px;
  font-weight: 700;
  font-size: 7px;
  color: var(--main-text);
}

.notification_messages_counter {
  font-weight: 700;
  font-size: 7px;
  max-height: fit-content;
  line-height: 100%;
  color: white;
}

@media screen and (min-width: 768px) {
  .item_bell {
    &.header_btn {
      display: flex;
    }
    &.burger_btn {
      display: none;
    }
  }
}
@media screen and (min-width: 1024px) {
  .btn {
    width: 56px;
    height: 56px;
  }

  .notification_counter_wrapper {
    right: 12px;
    top: 8px;
  }
}
