.wrapper {
  display: flex;
  gap: 14px;
  align-items: flex-start;
  padding: 10px 15px;
  max-width: fit-content;
  color: var(--red-text);
  font-weight: 400;
  font-size: 16px;
  background: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  text-align: start;
  margin-bottom: 25px;
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    text-decoration: underline;
  }
}

.details_btn {
  color: var(--link-text);
  text-decoration: underline;
}

.icon {
  flex: none;
}

.modal {
  max-width: 370px;
  background: var(--forms_bgc_3);
  text-align: left;
}

.modal__body {
  margin-top: 15px;
}

.modal_title {
  font-weight: 600;
  font-size: 20px;
}

.modal_text {
  font-weight: 400;
  font-size: 16px;
}

.btn {
  width: 230px;
  height: 50px;
  margin: 0 auto;
}
