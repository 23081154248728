.modal {
  max-width: 700px;
}

.tariff_name {
  font-size: 0.8em;
}

.compare_table {
  display: grid;
  grid-template-columns: repeat(2, auto);

  gap: 8px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.tariffs_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 10px;
  margin-top: 24px;
}

.compare {
  padding: 16px;
  border: 1px solid var(--other-dark-20);
  border-radius: 24px;
}
