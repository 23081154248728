.server_item {
  display: grid;
  position: relative;
  border-radius: 24px;
  padding: 16px;

  background-color: var(--primary-light-purple);
  grid-template-columns: auto 1fr;
}

.domainInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.btn_delete {
  width: 20px;
  height: 20px;

  &:hover {
    color: var(--primary-pink);
  }
}

.periodInfo {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  grid-column: 1/3;
  grid-row: 2/3;

  span {
    color: var(--other-dark-50);

    &:not(:last-child) {
      padding-right: 5px;
      border-right: 1px solid var(--other-dark-20);
    }
  }
}

.deleteIcon {
  width: 20px;
  height: 20px;

  cursor: pointer;
  justify-self: flex-end;
  grid-column: 2/3;
  grid-row: 1/2;

  &:hover {
    color: var(--primary-pink);
  }
}

.domainName {
  word-break: break-word;
}
