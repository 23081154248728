.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  width: 259px;
  height: 184px;
  border-radius: 5px;
  box-shadow: var(--forms_shadow);
  background-image: url('../../../images/banner259x184.webp');
}

.banner_text {
  padding: 0 15px;
  margin-bottom: 27px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.download_link {
  height: 50px;
  width: 100%;
  background-color: #f9f2ff;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.3);
}

.btn_text {
  font-weight: 400;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .banner {
    width: 647px;
    height: 100px;
    padding: 0 35px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../../images/banner647x100.webp');
  }

  .banner_text {
    padding: 0;
    margin: 0;
    max-width: 260px;
  }

  .download_link {
    width: 255px;
  }
}

@media screen and (min-width: 1024px) {
  .banner {
    width: 768px;
    height: 100px;
    background-image: url('../../../images/banner768x100.webp');
  }
}

@media screen and (min-width: 1550px) {
  .banner {
    width: 311px;
    height: 234px;
    padding: 30px 25px 25px;
    flex-direction: column;
    background-image: url('../../../images/banner311x234.webp');
  }
}
