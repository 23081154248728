.error_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 44px;
}

.error_type {
  font-weight: 700;
  font-size: 90px;
  line-height: 66.3%;
  color: var(--404-color);
  text-shadow: var(--404-text-shadow);
  margin-bottom: 25px;
}

.error_title {
  font-weight: 600;
  font-size: 20px;
  line-height: 66.3%;
  color: var(--main-text);
  margin-bottom: 20px;
}

.error_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 130.8%;
  margin-bottom: 25px;
  text-align: center;
}

//ADAPTIVE

@media screen and (min-width: 1024px) {
  .error_page {
    padding-top: 60px;
    margin-right: 83px;
  }
  .error_type {
    font-size: 115px;
    margin-bottom: 28px;
  }
  .error_title {
    font-size: 25px;
    margin-bottom: 22px;
  }
  .error_text {
    font-size: 18px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1550px) {
  .error_page {
    margin-right: 160px;
    padding-top: 121px;
  }
  .error_type {
    font-size: 150px;
    margin-bottom: 36px;
  }
  .error_title {
    font-size: 30px;
    margin-bottom: 24px;
  }
  .error_text {
    font-size: 20px;
    margin-bottom: 54px;
  }

  .error_img {
    width: 304px;
    height: 204px;
  }
}
