.modal {
  display: flex;
  flex-direction: column;
  background: var(--primary-white);
  border-radius: 24px;
  max-width: 630px;
  width: 95%;
  max-height: 90vh;
  overflow: hidden;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    z-index: 2;
    border-radius: 24px 24px 0 0;

    background: var(--modal-header-gradient);
    color: var(--primary-pink);
    user-select: none;
    @media screen and (min-width: 768px) {
      padding: 18px 25px 18px 35px;
    }
    @media screen and (min-width: 1024px) {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }

  &__close {
    /* This makes the clickable area much smaller, but it is consistent with the design (row below): */
    // padding: 5px;
    align-self: flex-start;
    svg {
      vertical-align: middle;
    }

    &_icon {
      color: var(--primary-dark);
    }

    &:hover,
    &:active {
      .modal__close_icon {
        color: var(--primary-pink);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    height: 100%;
    overflow-y: auto;

    &_wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    @media screen and (min-width: 768px) {
      padding: 20px 35px;
    }
  }

  &__footer {
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    padding: 24px 32px;
    z-index: 2;

    background-color: var(--primary-white);
    border-top: 1px solid var(--primary-pink);

    @media screen and (max-width: 499px) {
      & > button {
        width: 100%;
        max-width: unset;
      }
    }

    @media screen and (min-width: 500px) {
      flex-direction: row;
    }

    &_column {
      flex-direction: column;
    }
  }
}

.simple {
  max-width: 580px;
  text-align: center;

  .modal {
    &__header,
    &__footer {
      background-color: transparent;
    }

    &__header {
      padding: 20px 25px 0 50px;
      margin-bottom: -15px;
    }

    &__body {
      padding: 15px 25px;
    }

    &__footer {
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 30px;
      border-color: transparent;
    }
  }
}

.noScroll {
  overflow: initial;
  .modal {
    &__body {
      &,
      &_wrapper {
        overflow: initial;
      }
    }
  }
}

.modal__header_content {
  max-width: calc(100% - 26px);

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > p {
      background: var(--other-gradient-2);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > span {
      line-height: 100%;
      color: var(--primary-dark);
    }
  }
}

.actionFooterWithPrice {
  display: grid;
  gap: 24px;
  background-color: var(--primary-light-purple);
  justify-items: center;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.footer_column {
  display: grid;
}
