.wrapper {
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 10px 15px;
  max-width: fit-content;
  color: var(--red-text);
  font-weight: 400;
  font-size: 16px;
  background: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  text-align: start;
  margin-bottom: 25px;
}

.icon {
  flex: none;
}

.close {
  display: block;
  margin-left: auto;
  color: var(--main-text);
  text-decoration: underline;
  text-underline-offset: 2px;
}

@media screen and (min-width: 1550px) {
  .text_container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
}
