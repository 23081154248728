@import '@scss/placeholders.scss';

.mw {
  max-width: 900px;
  padding: 16px;
  @media (min-width: 1024px) {
    padding: 24px;
  }
}

.backLink {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  gap: 5px;
  width: fit-content;

  svg {
    stroke: var(--main-text);
    transform: rotate(90deg);
  }
}

.service {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &_title {
    margin-bottom: 5px;
    color: var(--main-text);
    font-size: 20px;
    font-weight: 600;
  }
  &_subtitle {
    color: var(--transparent-color);
    font-size: 17px;
  }
  &_options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_price {
    display: flex;
    gap: 5px;
    margin-bottom: -60px;
    margin-top: 35px;
  }
  @media (min-width: 540px) {
    &_options {
      flex-direction: row;
    }
    &_option {
      width: 50%;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &_option {
      width: auto;
    }
    .period_select {
      width: 175px;
    }
  }
}

.blockWrapper {
  display: grid;
  gap: 16px;
  width: 100%;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 260px);
    gap: 14px 20px;
    align-items: flex-start;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 260px);
    gap: 14px 20px;
    align-items: flex-start;
  }
}

.service_wrapper {
  padding: 20px 20px 11px;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}

.field_bgs {
  background-color: var(--forms_bgc_3);
  height: 44px;
}

.count {
  &_wrapper {
    position: relative;
  }
  &_btn {
    position: absolute;
    width: 15px;
    height: 15px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    &_decrement {
      left: 15px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 1px);
        height: 2px;
        background-color: var(--main-text);
      }
    }

    &_increment {
      right: 15px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: var(--main-text);
      }
      &::before {
        left: 0;
        right: 0;
        top: calc(50% - 1px);
        height: 2px;
      }
      &::after {
        top: 0;
        bottom: 0;
        width: 2px;
        left: calc(50% - 1px);
      }
    }

    &:disabled {
      pointer-events: none;

      &::before,
      &::after {
        background-color: var(--transparent-color);
      }
    }
  }
  &_input {
    @extend %input_type_number;
    text-align: center;
    padding: 0 35px;
    width: 100%;
    border: none;
    outline: none;
    box-shadow: var(--forms2_shadow);
    font-size: 22px;
    border-radius: 3px;
    color: var(--main-texts);
    @media (min-width: 768px) {
      width: 122px;
    }
  }
}

.config_desc {
  margin-top: 20px;

  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    .config_desc_list {
      width: 50%;
      padding-right: 15px;
    }
  }
}

.config_desc_item {
  font-size: 14px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: nowrap;
}
.config_desc_item_bold {
  font-weight: 600;
}
.config_desc_item_autoprolong {
  margin: 0;
  @media (min-width: 1024px) {
    width: 100%;
  }
}

.config_hidden {
  display: none;
}

.config_error {
  color: var(--red-text);
}

.toggle_config_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

.toggle_config {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 160px;
  gap: 10px;
  padding: 10px 15px;
  line-height: 1;
  background-color: var(--forms_bgc_3);
  box-shadow: var(--forms2_shadow);
  font-weight: 600;
  @media (min-width: 768px) {
    width: auto;
  }

  svg {
    fill: var(--transparent-color);
  }
  &:hover {
    svg {
      fill: url('#gradient');
    }
  }
}

.label {
  display: block;
  font-size: 14px;
  line-height: 1.2;
  margin-left: 10px;
  margin-bottom: 10px;
}

.auth {
  &_tabs {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  &_tab {
    padding-bottom: 4px;
    border: none;
    background-color: transparent;
    position: relative;
    font-size: 20px;
    &_active {
      font-weight: 600;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: linear-gradient(270deg, #fa4bb4 0%, #ed32cf 100%);
      }
    }
  }
  &_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &_field {
    border: none;
    &_wrapper {
      margin-bottom: 10px;
    }
  }
  &_loader {
    position: absolute;
    &_block {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 99;
    }
  }
  &_captcha {
    display: flex;
    // justify-content: center;
  }
  &_divider {
    display: flex;
    align-items: center;
    justify-content: center;
    &_text {
      position: relative;
      display: inline-block;
      padding: 5px;
      &::before,
      &:after {
        content: '';
        width: 100px;
        height: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--transparent-color);
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
    }
  }
  &_social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    @media (min-width: 768px) {
      margin-bottom: 110px;
    }
    @media (min-width: 1024px) {
      margin-bottom: 120px;
    }
    &_link {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      gap: 25px;
      border-radius: 24px;
      font-size: 18px;
      font-weight: 600;
      color: var(--main-text);
    }
    &_google {
      background-color: var(--elements-bgc);
    }
    &_vk {
      background-color: #4c75a3;
      color: #fff;
    }
  }
  @media (min-width: 768px) {
    &_container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &_divider {
      height: 180px;
      align-self: flex-start;
      &_text {
        position: relative;
        display: inline-block;
        padding: 5px;
        &::before,
        &:after {
          content: '';
          width: 1px;
          height: 100px;
          position: absolute;
          left: 50%;
          top: initial;
          transform: translate(-50%);
          background-color: var(--transparent-color);
        }
        &::before {
          bottom: 100%;
        }
        &::after {
          top: 100%;
        }
      }
    }

    &_captcha {
      margin-top: 55px;
    }
  }
}

.auth_field_wrapper {
  margin-bottom: 25px;

  &:nth-child(2) {
    margin-bottom: 25px;
  }

  & > span {
    position: absolute;
    margin-top: 5px;
  }
}

.auth_captcha {
  transform: scale(0.8);
  transform-origin: 0;
}

.error_message {
  // position: absolute;
  margin-left: 2px;
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
}

.credentials_error {
  height: fit-content;
  padding: 5px 10px;
  margin-bottom: 15px;
  text-align: center;
  align-items: center;
  font-size: 15px;
  color: var(--system-error);
  background-color: rgba(255, 68, 102, 0.1);

  a {
    color: var(--link-color);
    text-decoration: underline;
  }
}

.section_title {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  @media screen and (min-width: 1550px) {
    font-size: 20px;
  }
}

////// LAst step

.formBlock {
  display: flex;
  flex-direction: column;

  &.promocodeBlock {
    padding-bottom: 15px;
  }
}

.actLine {
  width: 100%;
  height: 1px;
  background: rgba(179, 169, 193, 0.51);
}

.formFieldsBlock {
  display: flex;
  flex-wrap: wrap;

  // &.first {
  //   align-items: center;
  // }

  &.promocode {
    margin-top: 20px;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
}

.additionalPayMethodBlock {
  margin-top: 16px;
  display: grid;
  gap: 16px;
}

.select {
  max-width: 100%;
}

.inputBig {
  max-width: 100%;
  padding-bottom: 0;
}

.infotext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--main-text);
  margin-top: 20px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  span {
    color: var(--system-error);
  }

  &.showMore {
    -webkit-line-clamp: 50;
  }
}

.readMore {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: var(--main-text);
  text-align: start;
  cursor: pointer;

  margin-top: 5px;
}

.notAllowPayMethod {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.promocodeBtn {
  margin: 10px 15px 15px;
  display: flex;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 70.9%;
  letter-spacing: -0.01em;
  text-decoration-line: underline;

  color: var(--main-text);
  cursor: pointer;

  &:disabled {
    color: var(--transparent-color);
    cursor: default;
  }
}

/* Why it was used? With this styles error message near confirmation checkbox appears incorrectly */

// .offerBlock {
//   @media (min-width: 1024px) {
//     margin-bottom: -65px;
//   }
// }

.sale55Promo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--transparent-color);
  margin-top: 11px;
  margin-left: 11px;
}

.discItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-top: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600 !important;
  }
}

.checkbox {
  margin-right: 10px;
}

.inputPerson {
  max-width: 100%;
}

.priceBlock {
  margin-top: 9px;
  display: flex;
  gap: 5px;
}

.totalSum {
  font-style: normal;
  font-weight: 400;
  line-height: 130.5%;
  color: var(--main-text);
  display: flex;
  flex-direction: column;

  span {
    margin-top: 9px;
  }

  span:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .infoBtn {
    position: relative;
    margin-left: 7px;

    .descriptionBlock {
      top: auto;
      bottom: 130%;
      // left: 0;
    }
  }
}

.infoBtn {
  position: relative;
  top: 2px;
  margin-left: 7px;
  z-index: 2;
  cursor: pointer;

  &:hover {
    svg path {
      fill: var(--accent-color-light);
    }

    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.infoBtnCard {
  cursor: help;
  &:hover {
    path {
      fill: var(--accent-color-light);
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  bottom: 130%;
  width: 232px;
  z-index: 3;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

@media screen and (min-width: 768px) {
  .additionalPayMethodBlock {
    grid-template-columns: repeat(3, 260px);
    gap: 14px 20px;
    align-items: flex-start;
  }

  .inputPerson {
    max-width: 30%;
    margin-right: 20px;
  }

  .auth_captcha {
    transform: unset;
  }
}

@media (max-width: 767px) {
  .infoBtn .descriptionBlock {
    top: auto;
    bottom: 130%;
    left: -700%;
  }

  .auth_field_wrapper {
    margin-bottom: 25px;

    &:nth-child(2) {
      margin-bottom: 35px;
    }
  }
}

.close_compare {
  display: none;
}
