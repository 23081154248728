.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 8px;
}

.item {
  padding: 16px;
  border-radius: 24px;
  text-align: left;
  background: var(--primary-white-40);
  border: 1px solid var(--primary-white);
}

.grid {
  display: grid;
  grid-template-columns: minmax(auto, 40%) 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  align-items: center;
  font-size: 14px;
  word-break: break-word;
  > * {
    &:nth-child(odd) {
      color: var(--other-dark-50);
    }
  }
}

@media screen and (min-width: 1024px) {
  .item {
    padding: 24px;
  }
}
