.wrapper {
  width: 100%;
}

.cloud__toolbar {
  display: flex;
  border-radius: 99px;
  height: 50px;
  width: 100%;
  box-shadow: var(--forms_shadow);
  background-color: var(--forms_bgc_2);
  overflow: hidden;
  font-size: 16px;
}

.toolbar_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  flex: 1;
  text-transform: capitalize;
  font-size: 16px;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid var(--transparent_lines);
  }

  &:hover {
    color: var(--accent-color-light);
  }

  &.selected {
    background-color: var(--forms_bgc_3);
    color: var(--accent-color-light);
    font-weight: 600;
    box-shadow: var(--forms_shadow);
    pointer-events: none;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 10px;
}

@media screen and (min-width: 768px) {
  .wrapper {
    width: 250px;
  }

  .cloud__toolbar {
    height: 56px;
    font-weight: 600;
    font-size: 16px;
  }

  .toolbar_btn {
    font-size: 18px;
  }

  .label {
    font-size: 16px;
  }
}
