@use '@scss/media.scss' as media;

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
}

.buttonBlock {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-end;
  margin-top: auto;
}

.tbody {
  tr {
    td {
      @include media.maxw-428 {
        font-size: 10px;
      }
    }
  }

  .status {
    width: 150px;
  }
}
