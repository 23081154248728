@import '@scss/placeholders.scss';

.modal.details_modal {
  max-width: 900px;
}

.heading_wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  margin-bottom: 15px;
  padding: 0 18px;
}

.heading {
  font-weight: 600;
  font-size: 18px;
}

.date {
  white-space: nowrap;
}

.icon_cross {
  width: 19px;
  height: 19px;
  flex: none;
  fill: var(--main-text);
  cursor: pointer;
}

.list_item {
  display: grid;
  grid-template-columns: 80px 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  height: fit-content;
  padding: 15px 20px;
  background-color: var(--primary-pink-16);
  border-radius: 24px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.total {
  font-size: 16px;
  padding-left: 10px;
}

.total_label {
  font-weight: 600;
}

.label {
  font-size: 14px;
  color: var(--other-dark-50);
}

@media screen and (min-width: 768px) {
  .modal_window {
    width: 80vw;
    padding: 30px 0;
  }

  .heading_wrapper {
    padding: 0 30px;
    margin-bottom: 35px;
  }

  .total_label {
    margin-right: 5px;
  }

  .table_head_row {
    display: flex;
    gap: 10px;
    padding: 0 60px;
    margin-bottom: 8px;
  }

  .table_head {
    font-size: 14px;
    &:first-child {
      flex-basis: 40%;
    }
    &:nth-child(2) {
      flex-basis: 15%;
    }
    &:nth-child(3) {
      flex-basis: 25%;
    }
    &:nth-child(4) {
      flex-basis: 20%;
    }
  }

  .list {
    padding: 10px 30px 30px;
    max-height: 60vh;
  }

  .row_value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      flex-basis: 40%;
    }
    &:nth-child(2) {
      flex-basis: 15%;
    }
    &:nth-child(3) {
      flex-basis: 25%;
    }
    &:nth-child(4) {
      flex-basis: 20%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .modal_window {
    width: 900px;
    padding: 40px 0;
  }

  .heading_wrapper {
    padding: 0 40px;
  }

  .table_head_row {
    padding: 0 100px;
    margin-bottom: 15px;
  }

  .list {
    padding: 10px 40px 30px;
  }
}
