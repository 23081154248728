@import '@scss/placeholders.scss';

.message {
  font-size: 16px;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
}

.link {
  @extend %link;
  font-size: inherit;
}

.header_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  font-size: 16px;
  color: var(--red-text_2);
}

@media screen and (min-width: 1024px) {
  .icon {
    width: 24px;
    height: 24px;
  }

  .header {
    font-size: 18px;
  }

  .description {
    font-size: 15px;
    line-height: 1.3;
  }
}
