@import '@scss/placeholders';

.modalBg {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--elements-bgc);
  z-index: 149;
  overflow-y: auto;
}

.modalBlock {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 424px;
}

.icon {
  width: 300px;
  height: 300px;
}

.modalTopBlock {
  border-radius: 3px;
  padding: 63px 22px;
  width: 100%;
  max-width: 424px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 130.5%;
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  text-align: center;
}

.link {
  @extend %link;
  font-size: 18px;
  margin-top: 15px;
}

@media screen and (min-width: 768px) {
  .modalBg {
    align-items: center;
    background-image: var(--auth-lines);
    background-repeat: no-repeat;
    background-position: 50% 60%;
  }

  .modalTopBlock {
    padding: 38px 50px;
    margin: 0 30px;
    background: var(--forms_bgc_3);
    box-shadow: var(--forms_shadow);
  }
}

@media screen and (min-width: 1550px) {
  .icon {
    width: 350px;
    height: 350px;
  }
}
