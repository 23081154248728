@use '@scss/mixin.scss' as mixin;

.messageContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  &:first-child {
    margin-top: auto;
  }
  &.outcoming {
    align-self: flex-start;
  }
  &.incoming {
    align-self: flex-end;
    flex-direction: row-reverse;
  }
}

.messageBlock {
  padding: 16px;
  text-align: left;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.userInfo {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 70.9%;
  color: var(--main-text);
}

.datetime {
  margin-left: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--other-dark-50);
  text-align: right;
  flex-shrink: 0;
}

.messageHeader {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.incoming {
  .messageHeader {
    flex-direction: column;
  }
}

.messageBody {
  margin: 8px 0 16px;
}

.messageText {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
  word-break: break-word;
  white-space: pre-line;

  a {
    color: var(--primary-pink);
    &:hover {
      color: var(--primary-purple);
    }
  }
}

.fileBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.headerWithPhoto {
  display: flex;
  align-items: center;
}

// .file {
//   padding: 12px;
//   background-color: rgba(217, 208, 235, 0.2);
//   border-radius: 3px;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 120%;
//   align-items: center;
//   display: flex;
//   margin-right: 0px;
//   cursor: pointer;
//   user-select: none;
//   margin-top: 5px;
//   svg {
//     margin-left: 15px;
//     width: 30px;
//   }
// }

.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  color: var(--primary-dark);
  svg {
    width: 40px;
    height: 40px;
  }
}

.tooltip {
  max-width: 300px;
  word-break: break-all;
  text-align: center;
}

.fileItemName {
  @include mixin.truncate-text(1);
  width: 100%;
  font-size: 12px;
}

.file_img {
  background-color: transparent;
  padding: 0;
}

.rateAswText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--main-text);
}

.rateBtnsBlock {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rateBtn {
  cursor: pointer;
  color: var(--primary-dark);

  &:hover {
    color: var(--primary-pink);
  }
}

.likeAnsw {
  color: var(--primary-pink);
}

@media screen and (min-width: 768px) {
  .messageHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .incoming {
    .messageHeader {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }
  // .file {
  //   &:not(.file_img) {
  //     padding: 12px 16px;
  //     margin-right: 10px;
  //     svg {
  //       margin-left: 15px;
  //       width: auto;
  //     }
  //   }
  // }
}

.img_thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 12px;
}

@media screen and (min-width: 1550px) {
  .messageContainer {
    width: 75%;
  }
}

.footer {
  display: flex;
  gap: 24px;
}
