.desk {
  display: flex;
  flex-direction: column;

  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 768px) {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &.desktop {
    border: 1px solid var(--primary-white);
    background-color: var(--primary-white-40);
    padding: 24px;
    border-radius: 24px;
    overflow: hidden;
    margin: 0;

    &:has(.content_wrapper) {
      min-height: 500px;
    }
  }
  &.full_height {
    flex-grow: 1;
  }
}

.content_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  margin-left: -16px;
  margin-right: -16px;

  @media (min-width: 768px) {
    margin-left: -24px;
    margin-right: -24px;
  }
  .desktop & {
    margin: 0 -24px -24px;
    overflow: hidden;
    &:first-child {
      margin-top: -24px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  margin-top: 10px;
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0 24px;
  }

  .desktop & {
    padding: 0 24px;
    overflow-y: auto;
  }
}
