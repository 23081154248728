.burger {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 16, 25, 0.3);
  z-index: 110;
}

.burger_nav {
  display: flex;
  padding: 16px;
  position: sticky;
  justify-content: space-between;

  top: 0;
  background: var(--primary-light-purple);
  z-index: 111;
}

.left_wrapper {
  display: flex;
  gap: 4px;
}

.header_menuburger {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  background: var(--primary-pink);
  border-radius: 50%;

  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.15s;
  z-index: 111;

  &.hamburger_spin {
    &.opened {
      right: 20px;
    }
  }
}

.hamburger_spin {
  .hamburger_inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.opened {
    .hamburger_inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.list_item_header {
  display: flex;
  align-items: center;
  background: var(--primary-white);

  padding: 8px;
  gap: 10px;

  &.profile {
    padding: 8px 16px 8px 8px;
  }
}

$hamburger-layer-width: 15px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 10px;

.hamburger_box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-width;
  display: inline-block;
  position: relative;

  &:hover {
    .hamburger_inner {
      background: var(--accent-gradient);

      &::after {
        background: var(--accent-gradient);
      }
      &::before {
        background: var(--accent-gradient);
      }
    }
  }
}

.hamburger_inner {
  display: block;
  top: 50%;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: 1px;
    background: var(--primary-white);

    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.theme_btn_wrapper {
  margin-right: 15px;
}

.list_wrapper {
  display: grid;
  gap: 8px;
}

.list {
  display: grid;
  gap: 24px;
  background: var(--primary-white);
  border-radius: 32px;
  padding: 16px;
}

.list_item {
  .icon {
    color: var(--primary-purple);
  }

  &:hover .icon {
    color: var(--primary-pink);
  }
}

.exit_wrapper {
  display: flex;
  gap: 10px;

  .icon {
    fill: none;
    stroke: var(--primary-purple);
  }

  &:hover .icon {
    stroke: var(--primary-pink);
  }
}

.balance_text {
  padding-top: 17px;
  padding-bottom: 17px;
  color: var(--main-text);
  font-size: 17px;
  line-height: 70.9%;
}

.exit_wrapper {
  display: flex;
  align-items: center;
  background: var(--primary-white);
  border-radius: 32px;
  padding: 24px;
  cursor: pointer;
}

.burger_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px 16px;
  height: 100%;
  row-gap: 8px;

  /* set the max height to remove unnecessary scrolling from the burger menu, so that the user could see the exit button */
  max-height: calc(100vh - 80px);
}

.balance_wrapper_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .balance_text {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .header_menuburger {
    width: 56px;
    height: 56px;
  }
}
