.icon {
  color: var(--primary-purple);

  &.support {
    stroke: none;
  }

  &.active {
    color: var(--primary-pink);
  }
}

.avatar_img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid var(--primary-pink);
  display: flex;
  align-items: center;
  justify-content: center;
}


.arrow_icon {
  margin-left: auto;
  flex: none;

  stroke: var(--main-text);
  transition: transform 0.5s;

  &.closed {
    transform: rotateZ(-180deg);
  }
}

.list_items_wrapper {
  &:hover {
    .icon,
    .arrow_icon {
      stroke: var(--primary-pink);
    }
  }
}
