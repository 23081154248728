.card {
  padding: 16px;

  &.selected {
    outline: 1px solid var(--primary-pink);
    background: var(--primary-white);
    cursor: default;
    .tariff_title {
      color: var(--primary-pink);
    }

    &.cardColor {
      background: var(--primary-light-pink);
    }
  }

  &.cardColor {
    background: var(--primary-light-purple);

    &:hover {
      outline: 1px solid var(--primary-pink);
    }
  }
}

.tariff_title {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  text-align: start;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 24px;

  @media screen and (min-width: 1024px) {
    font-size: 22px;
  }
}

.tariff_price {
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-pink);
  text-align: left;

  &.freeTrial {
    line-height: 400%;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
}

.tariff_parameters {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  margin-bottom: 32px;
  text-align: start;
}

.parameter_label {
  color: var(--other-dark-50);
}

.old_price {
  color: var(--primary-purple);
  text-decoration: line-through;
}

.card_footer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info_icon {
  cursor: pointer;
}

.detail_modal_list {
  li {
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      stroke: var(--primary-pink);
    }
  }
}
