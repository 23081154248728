.item {
  // height: 62px;
  background: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  border-radius: 5px;
  height: fit-content;
  position: relative;
}

.main_ip {
  display: block;
  width: 12px;
  height: 16px;
  left: 15px;
  top: 0;
  position: absolute;
}

.item_btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 30px 0px 48px;
  gap: 75px;
  cursor: pointer;
  position: relative;
  user-select: text;
}

.active_ip .value:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-image: var(--accent-gradient);
}

.value {
  font-size: 16px;
  text-align: start;
  line-height: 130%;
  color: var(--main-text);
  padding-top: 20px;
  padding-bottom: 20px;

  &:nth-child(1) {
    flex-basis: 20%;
  }
  &:nth-child(2) {
    flex-basis: 20%;
    word-break: break-all;
  }
  &:nth-child(3) {
    flex-basis: 20%;
  }
  &:nth-child(4) {
    flex-basis: 20%;
  }
  &:nth-child(5) {
    flex-basis: 20%;
  }
}

.price {
  display: block;
  font-size: 14px;
  color: var(--transparent-color);
}

.flag_icon {
  fill: #ad9acc;

  &:hover {
    fill: url(#gradient);
  }
}

.hint_wrapper {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -25%);
}

.active {
  color: var(--system-green-dark);
}
