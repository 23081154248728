.wrapper {
  width: max-content;
}
.copy_btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-pink);
  font-variant-numeric: tabular-nums;
  &:hover,
  &:active {
    color: var(--primary-purple);
  }
}

.copy_icon {
  &.disabled {
    opacity: 0.4;
  }
}

.copy_prompt {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 214px;
  padding: 5px 10px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: var(--forms3_shadow);
  background-color: var(--forms_bgc_1);

  &.onBottom {
    bottom: -60px;
    top: unset;

    & > .prompt_pointer {
      transform: translate(-50%, 0);
      bottom: 100%;
      top: unset;
    }
  }
}

.prompt_pointer {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0) rotate(180deg);
  width: 20px;
  height: 20px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 14px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: var(--forms_bgc_1);
    transform: rotate(45deg);
    box-shadow: var(--forms3_shadow);
  }
}

@media screen and (max-width: 767px) {
  .copy_prompt {
    &.onLeftSide {
      left: -70px;

      & > .prompt_pointer {
        right: 10px;
        left: unset;
      }
    }
    &.onRightSide {
      left: 85px;

      & > .prompt_pointer {
        left: 30px;
      }
    }
  }
}
