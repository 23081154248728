@use '@scss/media.scss' as media;

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
}

.buttonBlock {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-end;
}

.tbody {
  tr {
    td {
      @include media.maxw-428 {
        font-size: 10px;
      }
    }
  }
}

.stopped {
  color: var(--red-text);
}

.amountCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.red {
  color: var(--red-text);
}

.yellow {
  color: var(--system-orange-dark);
}

.green {
  color: #45a884;
}

.item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  row-gap: 10px;
  width: 100%;
  user-select: text;
}

.label {
  font-size: 14px;
  color: var(--transparent-color);
  display: flex;
  align-items: center;
}

.value {
  font-size: 15px;
}

.value_name {
  word-break: break-word;
}

.pagination {
  margin-top: auto;
}
