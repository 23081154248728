body {
  /**  ----------------- New variables -----------------  */
  --primary-pink: #ff4ab7;
  --primary-pink-16: rgba(255, 74, 183, 0.14);
  --primary-dark: #2a1c3c;
  --primary-purple: #913ef5;
  --primary-dark-purple: #752bcd;
  --primary-light-purple: #f1ebfe;
  --primary-white: #fff;
  --primary-white-30: rgba(255, 255, 255, 0.3);
  --primary-white-35: rgba(255, 255, 255, 0.35);
  --primary-white-40: rgba(255, 255, 255, 0.4);
  --primary-white-60: rgba(255, 255, 255, 0.6);
  --other-dark-5: rgba(42, 28, 60, 0.05);
  --other-dark-10: rgba(42, 28, 60, 0.1);
  --other-dark-20: rgba(42, 28, 60, 0.2);
  --other-dark-50: rgba(42, 28, 60, 0.5);

  --system-error: #f52e52;
  --system-success: #5ce271;
  --system-green-dark: #009120;
  --system-green-light: #b5ffc1;
  --system-red-dark: #d30303;
  --system-red-light: #ffcbcb;
  --system-orange-light: #fff3e2;
  --system-orange-dark: #ff8a00;
  --system-orange: #ff9314;

  --system-blue: #3498db;
  --system-dark-blue: #00b7ff;
  --system-light-blue: #eaf9ff;

  --primary-light-pink: #ffedf8;

  /** gradients */
  --other-gradient: linear-gradient(160deg, #f9f0ed 0%, #c5c0f7 100%);
  --other-gradient-2: linear-gradient(206deg, #ff4ab7 0%, #db03df 100%);
  --other-border-gradient: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  --modal-header-gradient: linear-gradient(96deg, #FFEEF8 0%, #E3D6FF 100%);

  /** shadow */
  --new-box-shadow: 5px 11px 19px rgba(98, 65, 85, 0.08);

  --grid: repeat(auto-fill, minmax(240px, 1fr));

  /**  ----------------- Old variables -----------------  */
  --accent-text-gradient: linear-gradient(120deg, #ff3cb1 30%, #e030bd 70%);
  --accent-gradient: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
  --line-gradient: linear-gradient(270deg, #c630bb 0%, #924ee8 100%);
  --accent-color-light: #ff50bf;
  --input-border-icon: #c4c4c4;
  --forms2-border-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.09) 0%,
    #ffffff 92.92%
  );
  --red_gradient_elements: linear-gradient(180deg, #ff6f66 12.11%, #fa487d 98.37%);

  // images
  --auth-lines: url('../images/auth_lines.svg');

  // borders
  --center-border-color: #cfc1eb;
  --center-border-color-pink: rgba(250, 154, 213, 1);

  // variables that will be changed on the dark theme
  --main-bgc: #fefdff;
  --main-bgc2: #fefdff;
  --main-bgc3: #fdfcff;
  --main-text: #392955;
  --menu-text-3: #523e75;
  --link-text: #4c75a3;
  --red-text: #fa6848;
  --red-text_2: #fa6848;
  --red_text_bg: #ffede9;
  --support-title-color: #392952;
  --servers_tariff_color: #7c66a4;
  --btn-bgc: #f9f2ff;
  --elements-bgc: #fff;
  --nearby-elements-bgc: #fff;
  --sales-bgc: rgba(255, 75, 36, 0.15);
  --after-line-bgc: #5d5965;

  --success-bgc: #fcfcfc;
  // --success-color: #45a884;
  --link-color: #4c75a3;
  --auth-placeholder-color: #c4c4c4;
  --eye-color: #777;
  --icon-color: #d9d0eb;
  --icon-color_2: #ae9ccd;
  --disabled_icon_color: #d7cee6;
  --scrollbar-track-color: #ebebeb;
  --404-color: #ece7f8;
  --carousel-elements-bgc: #ffffff;
  --carousel-nearby-elements-bgc: #fef7fe;
  --carousel-dots-elements-bgc: #e4e2e8;
  --select_option_bgc: rgba(217, 208, 235, 0.19);
  --dividing_line: #e4dfec;
  --transparent-color: #b3a9c1;
  --transparent-color_2: #b3a9c1;
  --forms_bgc_1: #fff;
  --forms_bgc_2: #fff;
  --forms_bgc_3: #fff;
  --forms_bgc_4: #fff;
  --forms_bgc_5: #fff;
  --inactive_icon_color: #e1daeb;
  --popup_prompt: #ede7f4;
  --message-bg: #f6ebff;
  --border-color: #f4f4f4;
  --switchers-bg: #e1dfdf;
  --hint-background: #f3e4ff;
  --switchers-dot-bg: #ffffff;
  --code_background: #e3e3e3;
  --switchers-dot-bg-active: linear-gradient(251.29deg, #ffffff 11.47%, #fafafa 89.97%);
  --servers_price_bgc: linear-gradient(
    180deg,
    rgba(146, 78, 232, 0.03) 0%,
    rgba(198, 48, 187, 0.03) 100%
  );
  --red-bg: #fdf0f1;
  --domains_hover_bgc: #faf5fe;
  --card-line: #e1daea;
  --transparent_lines: rgba(0, 0, 0, 0.1);

  --gradient_cards_static: linear-gradient(
    90deg,
    rgba(239, 141, 248, 0.11) 0.03%,
    rgba(241, 228, 255, 0.22) 99.94%
  );

  --access_rights_bg_item_title: #f6f4fb;
  --item_bottom_splitter: #cfc4e8;
  --sublist-bgd: #f9f7fc;
  --access_rights_bg_item_title_gradient: linear-gradient(
    89.98deg,
    #feeff7 0.02%,
    #f5eafe 99.98%
  );
  --access_rights_bg_item_title_gradient_2: linear-gradient(
    89.98deg,
    #feeff7 0.02%,
    #f5eafe 99.98%
  );
  --info_alert_background: rgba(207, 226, 255, 0.6);

  // SHADOWS
  --box-shadow: 4px 4px 10px rgba(213, 201, 236, 0.25), -4px -4px 10px #fefdff;
  --burger-menu-elem-box-shadow: 0px 1px 8px rgba(65, 38, 114, 0.15);
  --elements-shadow: -4px 0px 10px rgba(65, 38, 114, 0.15);
  --line-shadow: -1px -1px 2px rgba(255, 255, 255, 0.49), 1px 1px 2px #cfc4e8;

  --shadow_cards_static: 0px 1px 8px rgba(65, 38, 114, 0.15);

  --switchers-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.15);
  --switchers-dot-shadow: -2px 1px 5px rgba(0, 0, 0, 0.25);
  --switchers-shadow-active: 1px 1px 5px rgba(0, 0, 0, 0.2);
  --switchers-dot-shadow-active: 1px 1px 10px rgba(57, 19, 40, 0.4);

  --forms2_pink_shadow: 0px 1px 13px rgba(228, 51, 190, 0.2);

  --menu_shadow: 4px 4px 10px rgba(213, 201, 236, 0.25);
  --menu_down_black_shadow: 0px -4px 10px rgba(213, 201, 236, 0.25);
  --forms_shadow: 1px 3px 5px rgba(201, 187, 230, 0.3), -1px -1px 6px #f9f8fd;
  --forms2_shadow: 0px 1px 8px 0px rgba(65, 38, 114, 0.15);
  --forms3_shadow: 0px 1px 8px 0px rgba(65, 38, 114, 0.15);
  --forms_shadow_site: 0px 1px 8px rgba(65, 38, 114, 0.15);
  --buttons_shadow: -2px -2px 4px #ffffff, 2px 2px 5px #e4def2;
  --icon_btn_shadow: -4px -4px 4px #fefdff, 2px 4px 5px #e4def2;
  --buttons_shadow_active: 2px 4px 5px rgba(98, 0, 54, 0.25),
    inset 2px 2px 6px rgba(0, 0, 0, 0.15);
  --404-text-shadow: 3px 3px 4px rgba(78, 37, 119, 0.3);
  --modal_header_shadow: 0px 2px 4px 0 rgba(65, 38, 114, 0.15);
  --modal_header_shadow2: 2px 2px 8px rgba(65, 38, 114, 0.12);
  --modal_footer_shadow: 0px -2px 4px 0 rgba(65, 38, 114, 0.15);
  --checkbox_shadow: 1px 1px 5px rgba(65, 38, 114, 0.23);
  --service_card_hover_shadow: 2px 3px 8px 2px rgb(0 0 0 / 10%),
    inset 1px 1px 5px 0px rgb(255 255 255 / 15%);

  --header_shadow: 2px 2px 8px rgba(65, 38, 114, 0.12);
  --footer_shadow: 0px -1px 8px rgba(65, 38, 114, 0.12);

  --backdrop: rgba(205, 205, 205, 0.25);

  --dedic_tariffs_gradient_hover: linear-gradient(90deg, #feeff7 0.02%, #f5eafe 99.98%);
  --dedic_vds_tariff: #fef0fc;

  --sort_svg: #392955;
}

// dark theme variables
// body.dark-theme {
//   // images
//   // --auth-lines: url('../images/auth_lines_dt.svg');

//   // borders

//   --center-border-color: #decbfe;
//   --forms2-border-gradient: linear-gradient(
//     184.22deg,
//     rgba(46, 39, 59, 0) 3.44%,
//     #2e273b 89.97%
//   );
//   --center-border-color-pink: rgba(205, 104, 166, 1);

//   --main-bgc: #201a2a;
//   --main-bgc2: #2e273b;
//   --main-bgc3: #2e273b;
//   --main-text: #fff;
//   --menu-text-3: #ffffff;
//   // to delete

//   --link-text: #8ab7fa;
//   --red-text: #d35757;
//   --red-text_2: #ff7878;
//   --red_text_bg: #743f49;
//   --support-title-color: #ffffff;
//   --servers_tariff_color: #c7aff1;
//   --btn-bgc: #3a3348;
//   --elements-bgc: #2e273b;
//   --nearby-elements-bgc: #3b3447;
//   --after-line-bgc: #ddd8e3;
//   --sales-bgc: #d35757;

//   --success-bgc: #433d4f;
//   --success-color: #38d49c;
//   --link-color: #8ab7fa;
//   --auth-placeholder-color: #6e6977;
//   --eye-color: #c4c4c4;
//   --icon-color: #75707e;
//   --icon-color_2: #decbfe;
//   --disabled_icon_color: #897d9e;
//   --scrollbar-track-color: #4b4556;
//   --404-color: #4d4954;
//   --carousel-elements-bgc: #342f3f;
//   --carousel-nearby-elements-bgc: #3e3949;
//   --carousel-dots-elements-bgc: #3e3949;
//   --select_option_bgc: #484352;
//   --dividing_line: #4d4855;
//   --transparent-color: #878290;
//   --transparent-color_2: #fff;
//   --forms_bgc_1: #2e273b;
//   --forms_bgc_2: #342f3f;
//   --forms_bgc_3: #3a3347;
//   --forms_bgc_4: #514a5f;
//   --forms_bgc_5: #383046;
//   --inactive_icon_color: #5c5865;
//   --popup_prompt: #4f4a5b;
//   --message-bg: #473957;
//   --border-color: #5a5663;
//   --hint-background: #514a5f;
//   --switchers-bg: #6f687c;
//   --switchers-dot-bg: #aaa4b6;
//   --switchers-dot-bg-active: linear-gradient(251.29deg, #ffffff 11.47%, #fafafa 89.97%);
//   --servers_price_bgc: #484452;
//   --red-bg: rgba(211, 87, 87, 0.2);
//   --domains_hover_bgc: #4a4457;
//   --card-line: #514b5c;

//   --access_rights_bg_item_title: #3f384c;
//   --item_bottom_splitter: #3a3347;
//   --sublist-bgd: #372f44;
//   --access_rights_bg_item_title_gradient: #3f384c;
//   --access_rights_bg_item_title_gradient_2: #3a3347;
//   --transparent_lines: rgba(255, 255, 255, 0.1);
//   --info_alert_background: rgba(102, 119, 145, 0.6);

//   --gradient_cards_static: linear-gradient(90.05deg, #3c354b 0.04%, #433b52 99.97%);

//   // SHADOWS
//   --box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px #000000;
//   --burger-menu-elem-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.21);
//   --elements-shadow: -4px 0px 10px rgba(0, 0, 0, 0.15);
//   --line-shadow: -1px -1px 2px #1e1a26, 1px 1px 3px #1a1720;

//   --shadow_cards_static: 0px 1px 20px rgba(0, 0, 0, 0.35);

//   --switchers-shadow: 1px 1px 4px #1b1820, inset -3px 3px 5px #514a5f;
//   --switchers-dot-shadow: -3px 2px 5px #332f3a;
//   --switchers-shadow-active: 1px 1px 4px #1b1820, inset 2px 3px 5px #ac2487;
//   --switchers-dot-shadow-active: 3px 1px 5px #931d73;

//   --forms2_pink_shadow: 0px 1px 15px #8a1a72;

//   --menu_shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
//   --menu_down_black_shadow: 0px -4px 15px rgba(0, 0, 0, 0.41);
//   --forms_shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);
//   --forms2_shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);
//   --forms3_shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
//   --forms_shadow_site: 0px 1px 20px rgba(0, 0, 0, 0.35);
//   --buttons_shadow: 0px 2px 9px rgba(0, 0, 0, 0.6);
//   --icon_btn_shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);
//   --404-text-shadow: 6px 3px 10px #000000;
//   --modal_header_shadow: 0px 2px 4px 0 rgba(0, 0, 0, 50%);
//   --modal_header_shadow2: 0px 1px 10px rgba(0, 0, 0, 0.3);
//   --modal_footer_shadow: 0px -2px 4px 0 rgba(0, 0, 0, 50%);
//   --checkbox_shadow: var(--forms_shadow);
//   --service_card_hover_shadow: 2px 3px 10px 4px rgb(0 0 0 / 60%),
//     inset 1px 1px 5px 0px rgb(255 255 255 / 15%);

//   --header_shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
//   --footer_shadow: 0px -1px 10px rgba(0, 0, 0, 0.3);

//   --dedic_tariffs_gradient_hover: linear-gradient(90deg, #463e55 1.41%, #524965 99.95%);
//   --dedic_vds_tariff: #461f44;

//   --backdrop: rgba(0, 0, 0, 0.4);
//   --sort_svg: url(#gradient);
// }
