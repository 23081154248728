.sort {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;

  @media (max-width: 1549px) {
    pointer-events: none;
    svg {
      display: none;
    }
  }
}

.sort_active {
  svg {
    fill: var(--sort_svg);
  }
}

.mobile_item__header {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 12px;
}

.mobile_item__header_actions {
  flex-shrink: 0;
  display: flex;
  gap: 5px;
}

.mobile_item__header_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
}
.mobile_item__name {
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.mobile_item__value {
  &.withIcon {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.no_vds_wrapper {
  text-align: center;
  margin: 40px auto;
  max-width: 500px;
}

.no_vds {
  display: block;
  margin: 0 auto 20px;
  filter: grayscale(1);
}

.no_vds_title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}
