.selectDropdownClass {
  z-index: 9 !important;
}

.formBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
}

.formBlockTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--main-text);
}

.formFieldsBlock {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 14px;
  column-gap: 20px;
}

.input {
  max-width: 100%;
  // margin-top: 13px;
}

.phoneInputLabel {
  margin: 0 0 5px 10px;
}

.select {
  max-width: 100%;
  // margin-top: 13px;

  span {
    font-size: 16px;
  }

  div {
    font-size: 16px;
  }
}

.phoneInput {
  width: 100%;
  max-width: 100%;
  margin: 13px 0 0 0;

  input {
    height: 46px !important;
    background-color: var(--elements-bgc) !important;
  }

  div {
    background-color: var(--elements-bgc) !important;
  }
}

.saveBtn {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  height: 55px;
}

.cancel {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  text-decoration-line: underline;
  margin-top: 22px;
  color: var(--main-text);
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.checkbox {
  margin-right: 10px;
}


@media screen and (min-width: 768px) {
  .saveBtn {
    font-size: 20px;
  }

  .formBlock {
    margin-top: 25px;
  }

  .phoneInput {
    max-width: 48%;
    margin: 13px 20px 0 0;
  }

  .input {
    // max-width: 48%;
    // margin-right: 20px;

    &.inputAddress {
      max-width: 100%;
    }
  }

  // .select {
  //   max-width: 48%;
  //   margin-right: 20px;
  // }

  .btnBlock {
    flex-direction: row;
    padding: 15px 35px;
  }

  .saveBtn {
    width: 254px;
  }

  .cancel {
    margin-top: 0px;
    margin-left: 20px;
  }

  .formFieldsBlock {
    grid-template-columns: 1fr 1fr;
  }
}
