.domainItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  background: var(--gradient_cards_static);
  box-shadow: var(--shadow_cards_static);
  padding: 15px;

  position: relative;
}

.priceList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.domainInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.domainName {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  color: var(--main-text);
}

.domainProtect {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--transparent-color);
}

.costBlock {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.cost {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70.9%;
  color: var(--main-text);
  margin-bottom: 4px;
}

.discountBlock {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  .discountPerCent {
    color: var(--system-error);
    margin-right: 5px;
  }

  .fullcost {
    text-decoration: line-through;
    color: var(--transparent-color);
  }
}

.deleteIcon {
  position: absolute;
  top: 17px;
  right: 17px;
  margin-left: 40px;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
}

.periodInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--transparent-color);
    text-align: start;

    &:first-child {
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: 768px) {
  .periodInfo {
    flex-direction: row;
  }

  .domainItem {
    flex-direction: row;
    align-items: center;
  }

  .priceList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 0;
  }

  .costBlock {
    display: flex;
    align-items: flex-end;
    margin-top: 0;
  }

  .deleteIcon {
    position: relative;
    top: 0;
    right: 0;
  }
}
