.nav_bar {
  user-select: none;
  position: relative;
  display: flex;
  align-self: stretch;

  &.modal_nav_bar {
    .scroll_area {
      padding: 0;
      &::after {
        background-color: var(--other-dark-10);
      }
    }
    .blur {
      margin: 0;
      width: 100%;
    }
  }
}

.scroll_area {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 24px;
  padding-left: 24px;
	
  @media screen and (max-width: 767px) {
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0) 32px),
      linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0) 32px);
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0) 32px),
      linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0) 32px);

    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    border-radius: 24px;
    background-color: var(--primary-white);
  }
}

.link {
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  padding-bottom: 10px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &:hover {
    color: var(--primary-pink);
  }
}

.active_link {
  position: relative;
  cursor: initial;
  pointer-events: none;
  color: var(--primary-pink);
  font-weight: 500;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 24px;
    z-index: 1;
    background: var(--primary-pink);
  }
}

.blur {
  margin: 0 -24px;
  position: relative;
  width: calc(100% + 48px);
}

@media screen and (min-width: 768px) {
  .nav_bar {
    &::after {
      right: -55px;
    }
  }

  .blur {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  .link {
    &:not(:last-child) {
      margin-right: 50px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .link {
    font-size: 20px;
  }
}

@media screen and (min-width: 1550px) {
  .nav_bar {
    &::after {
      right: -130px;
    }
  }
}

@media screen and (min-width: 1918px) {
  .nav_bar {
    &::after {
      right: -150px;
    }
  }
}
