@mixin minw-1550 {
  @media screen and (min-width: 1550px) {
    @content;
  }
}

@mixin minw-1440 {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin minw-1024 {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin maxw-1200 {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin maxw-1024 {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin maxw-1077 {
  @media screen and (max-width: 1077px) {
    @content;
  }
}

@mixin maxw-871 {
  @media screen and (max-width: 871px) {
    @content;
  }
}

@mixin minw-768-maxw-1024 {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@mixin maxw-752 {
  @media screen and (max-width: 752px) {
    @content;
  }
}

@mixin maxw-375 {
  @media screen and (max-width: 374px) {
    @content;
  }
}

@mixin maxw-428 {
  @media screen and (max-width: 427px) {
    @content;
  }
}

@mixin maxw-768 {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin minw-768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin minw-576 {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin minw-500 {
  @media screen and (min-width: 500px) {
    @content;
  }
}

@mixin minw-334 {
  @media screen and (min-width: 334px) {
    @content;
  }
}

@mixin minw-374 {
  @media screen and (min-width: 374px) {
    @content;
  }
}

@mixin minw-1918 {
  @media screen and (min-width: 1918px) {
    @content;
  }
}
