@use '@scss/media.scss' as media;

.list {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 300px));
  align-items: self-start;
}

.item {
  padding: 16px;
  position: relative;
  z-index: 0;

  &:first-child {
    z-index: 1;
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--other-dark-20);
    border-radius: 24px;
    background: var(--primary-white);
    transition: transform 0.2s;
  }

  &.selected::before {
    border: 1px solid var(--primary-pink);
  }

  &:not(.selected) {
    cursor: pointer;
    &:hover::before {
      transform: scale(1.01);
    }

    &:active::before {
      transform: scale(0.99);
    }
  }
}

.radio {
  align-items: flex-start;
  pointer-events: none;
}

.method_body {
  display: grid;
  gap: 20px;
}

.item__description {
  display: flex;
  gap: 14px;
}

.item__text_wrapper {
  flex-grow: 1;
}

.item__name {
  margin-bottom: 16px;
}

.item__text {
  text-decoration-skip: 14px;
  min-height: 36px;
  color: var(--other-dark-50);
}

.item__field {
  margin-top: 16px;
  padding-top: 24px;
  display: flex;
  gap: 4px;
  border-top: 1px solid var(--other-dark-10);
}

.dropdown_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
  text-decoration: underline;

  @include media.minw-1024 {
    font-size: 16px;
  }
}

.shevron {
  transform: rotateZ(0deg);
  transition: transform 150ms;

  &.active {
    transform: rotateZ(180deg);
  }
}

.icon_bg_circle {
  flex: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--primary-purple);
  background: var(--primary-light-purple);
}
