.modalBg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-image: url('../../../images/TY_Ellipse.avif');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  z-index: -1;

  @media screen and (min-width: 768px) {
    background-image: var(--auth-lines), url('../../../images/TY_Ellipse.avif');
    background-repeat: no-repeat, no-repeat;
    background-position: 50% 60%, center;
    background-size: auto, cover;
  }
}

.modalBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70%;
}

.successIcon {
  color: var(--system-success);
}

.modalTopBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.approved {
  @media screen and (min-width: 1024px) {
    font-size: 42px;
  }
}

.completed {
  color: var(--other-dark-50);
}

.linksBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  margin-top: 48px;
  text-align: center;
}
