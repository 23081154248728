.modalBg {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-image: url('../../../images/TY_Ellipse.avif');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  z-index: -1;

  @media screen and (min-width: 768px) {
    background-image: var(--auth-lines), url('../../../images/TY_Ellipse.avif');
    background-repeat: no-repeat, no-repeat;
    background-position: 50% 60%, center;
    background-size: auto, cover;
  }
}

.modalBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.errorIcon {
  color: var(--system-error);
}

.modalTopBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.error {
  @media screen and (min-width: 1024px) {
    font-size: 42px;
  }
}

.errorText {
  color: var(--other-dark-50);

  max-width: 530px;
}

.linksBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 16px;

  margin-top: 32px;
  text-align: center;

  @media screen and (min-width: 768px) {
    margin-top: 48px;
    gap: 40px;
  }
}
