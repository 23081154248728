.main_header {
  position: fixed;
  left: 8px;
  right: 8px;
  top: 8px;
  z-index: 9;

  padding: 0 8px;
  margin: 0 auto;

  display: grid;
  gap: 16px;
  justify-items: center;

  transition: transform 0.3s, height 0.3s, backdrop-filter 0.3s;

  &.scrolled {
    padding: 0;
  }
}

.logo_link {
  transition: transform 0.3s;
}

.logo {
  width: 84px;
  height: 32px;
}

.long_btn_wrapper,
.short_btn_wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 4px;
  width: 100%;
  transition: opacity 0.3s, backdrop-filter 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    background: var(--primary-white-35);
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    z-index: -1;
  }

  &.scrolled {
    padding: 7px;
    border-radius: 24px;
    backdrop-filter: blur(10px);
    border: 1px solid var(--primary-white);

    &::before {
      opacity: 1;
    }
  }
}

.balance_text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 70.9%;

  span {
    padding-top: 5px;
    text-decoration-line: underline;
    color: var(--link-color);
  }
}

.header__slash {
  margin-right: 24px;
  display: none;
  &::after {
    content: '';
    position: absolute;
    background: linear-gradient(90.13deg, #ff42a8 0.11%, #e030bd 98.12%);
    height: 23px;
    width: 2px;
    top: 4px;
  }
}

.link {
  display: flex;
  align-items: center;

  &.active {
    path {
      fill: url(#gradient);
    }
  }
}

.wrapper {
  position: fixed;
  top: -8px;
  left: -8px;

  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 16, 25, 0.3);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.3s ease;

  &.opened {
    opacity: 1;
    pointer-events: fill;
  }

  &.scrolled {
    top: -12px;
  }

  @media screen and (min-width: 768px) {
    top: 0;
    left: 0;

    &.scrolled {
      top: -17px;
      left: -9px;
    }
  }

  @media screen and (min-width: 1024px) {
    &.scrolled {
      top: -25px;
      left: -130px;
    }
  }
}

.profile_item {
  display: none;

  position: relative;
  border-radius: 50%;
  border: 2px solid transparent;

  & > .btn {
    cursor: auto;
  }

  &:hover {
    border-color: var(--primary-pink);

    .user_icon {
      color: var(--primary-pink);
    }
  }

  &.opened {
    z-index: 100;
  }
}

.profile_dropdown__wrapper {
  position: absolute;
  top: calc(100% - 16px);
  right: 0;
  padding-top: 20px;
  // pointer-events: none;
}

.profile_list {
  background-color: var(--primary-white);
  width: 270px;
  padding: 24px 31px 24px 20px;
  border-radius: 24px;

  border: 1px solid var(--primary-pink);

  display: grid;
  gap: 20px;
}

.profile_list_item {
  width: fit-content;
  &:hover {
    color: var(--primary-pink);
  }
}

.exit_list_item {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    background-color: var(--other-dark-10);
    left: 0;
    right: 0;
    border-radius: 5px;
  }

  & button {
    width: 100%;
    text-align: start;
    padding-top: 18px;
  }
}

.profile_list_username_item {
  position: relative;
  cursor: auto;

  &::after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    background-color: var(--other-dark-10);
    left: 0;
    right: 0;
    border-radius: 5px;
  }
}

.user_email {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: #878291;

  &_lt {
    color: #b3a9c1;
  }
}

.user_name {
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
}

.user_name,
.user_email {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.user_icon {
  color: var(--primary-dark);
  cursor: pointer;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-white);
}

.avatar_img {
  width: 48px;
  height: 48px;
}

.arrow_icon {
  stroke: var(--other-dark-10);
  transform: rotateZ(180deg);
  margin-left: 15px;
  transition: transform 0.3s;
  flex: none;
  stroke-width: 2px;
  height: 8px;
  width: 12px;

  &.active {
    transform: rotateZ(0deg);
  }

  &:hover {
    stroke: url(#gradient);
  }
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.support {
    .btn.support {
      stroke: var(--primary-dark);
    }

    &:hover {
      .btn {
        background: var(--primary-pink);
        .support {
          stroke: var(--primary-white);
        }
      }
    }
  }
}

.theme_item {
  display: none;
  width: 35px;
  height: 32px;
}

.lang_item {
  display: none;
}

.profile_btn {
  display: none;
  align-items: center;
  cursor: pointer;
}

.active_notification {
  position: relative;

  &::after {
    content: '';
    border-radius: 60px;
    border: none;
    color: #eeeeee;
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 8px;
    font-family: sans-serif;
    font-size: 20px;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    animation: glowing 1300ms infinite;
  }
}

.hovered_icon {
  fill: url('#gradient');
}

.burger_icon {
  display: inline-block;
  width: 35px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.burger_icon {
  display: inline-block;
  width: 35px;
  stroke-width: 0;
}

//burger menu

$hamburger-layer-width: 15px;
$hamburger-layer-height: 1px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: var(--primary-dark);
$hamburger-layer-border-radius: 99px;

.header_menuburger {
  width: 48px;
  height: 48px;

  background: var(--primary-white);
  border-radius: 50%;

  align-items: center;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.15s;
  z-index: 99;

  &.hamburger_spin {
    &.opened {
      right: 20px;
    }
  }

  &:hover {
    background: var(--primary-pink);

    .hamburger_inner {
      background: var(--primary-white);

      &::after {
        background: var(--primary-white);
      }
      &::before {
        background: var(--primary-white);
      }
    }
  }
}

.hamburger_spin {
  .hamburger_inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.opened {
    .hamburger_inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
          transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.hamburger_box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger_inner {
  display: block;
  top: 50%;
  margin-top: calc($hamburger-layer-height / -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.burger_menu {
  width: 100%;
  right: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 110;

  background: var(--primary-light-purple);
  transform: translateX(100%);
  transition: transform 0.4s;
  overflow-x: hidden;

  &.opened {
    transform: translateX(0px);
  }
}
.burger_item {
  position: relative;
  z-index: 120;
}

@keyframes glowing {
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
}

.button {
  animation: glowing 1300ms infinite;
}

@media screen and (min-width: 768px) {
  .burger_menu {
    width: 403px;
  }

  .header_menuburger {
    right: 60px;
    top: 23px;

    &.hamburger_spin {
      &.opened {
        right: 55px;
      }
    }
  }

  .main_header {
    top: 16px;
    right: 8px;
    left: 8px;

    padding: 8px 16px;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 24px;
      background: var(--primary-white-35);
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
      z-index: -1;
    }

    &.scrolled {
      padding: 7px 15px;
      border-radius: 24px;
      backdrop-filter: blur(10px);
      border: 1px solid var(--primary-white);

      &::before {
        opacity: 1;
      }
    }
  }

  .logo_link {
    grid-column: 1/1;
    grid-row: 1/1;
    align-self: center;
  }

  .logo {
    width: 100px;
    height: unset;
  }

  .list {
    width: unset;
    gap: 4px;

    grid-column: 2/3;
    grid-row: 1/1;

    &::before {
      content: unset;
    }

    &.scrolled {
      padding: unset;
      border-radius: unset;
      backdrop-filter: unset;
      background: unset;
      border: unset;
    }
  }
}

@media screen and (min-width: 1024px) {
  .main_header {
    justify-items: flex-end;
    top: 24px;
    right: 24px;
    left: 129px;
  }

  .list {
    gap: 40px;
  }

  .btn,
  .avatar_img {
    width: 56px;
    height: 56px;
  }

  .long_btn_wrapper {
    gap: 16px;
  }

  .short_btn_wrapper {
    gap: 8px;
  }

  .profile_item {
    display: block;
  }

  .active_notification::after {
    right: 16px;
    top: 10px;
  }

  .theme_item {
    display: block;
  }
  .lang_item {
    display: block;
  }
  .burger_item {
    display: none;
  }
  .logo_link {
    display: none;
  }
  .header_menuburger {
    display: none;
  }
}
