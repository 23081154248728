@use '@scss/media.scss' as media;

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
}

.buttonBlock {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-end;
}

.tbody {
  tr {
    td {
      @include media.maxw-428 {
        font-size: 10px;
      }
    }
  }
}

.stopped {
  color: var(--red-text);
}

.amountCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
