.container_wrapper {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  --right-padding: 16px;
  padding: 16px 0 24px;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    --right-padding: 24px;
    padding: 24px 0;
  }

  @media screen and (min-width: 1024px) {
    padding: 30px 0 24px 105px;
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 115px 16px 0;
  overflow-y: auto;
  overflow-x: hidden;

  --container-padding: calc((100vw - 1440px) / 2);

  @media screen and (min-width: 768px) {
    padding: 80px 24px 0;
  }

  @media (min-width: 1680px) {
    padding: 80px var(--container-padding) 0
      max(calc(var(--container-padding) - 105px), 16px);
  }

  &.scroll_forbidden {
    overflow: hidden;
  }
}

.title_wrapper {
  margin-bottom: 20px;
}

.pagination_wrapper {
  height: 0;
  overflow: hidden;
  flex-shrink: 0;
  transition: height 0.3s linear;
  &.show {
    height: 85px;
    padding-top: 10px;
    @media (min-width: 400px) {
      height: 48px;
    }
  }
}

.messages_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:has(*) {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: row-reverse;
  }
}
