@import '@scss/placeholders.scss';

.increment_wrapper {
  --height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  max-width: 126px;
  height: var(--height);
  pointer-events: initial;
}

.count_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--height);
  height: var(--height);
  padding: 8px;
  border-radius: 99px;
  background: var(--primary-light-purple);
  cursor: pointer;
  transition: transform 100ms;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.6;
  }
}

.count_input {
  @extend %input_type_number;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 0 5px;
  border-radius: 99px;
  border: 1px solid var(--other-dark-20);
  outline: none;
  color: var(--primary-pink);

  &:focus {
    border-color: var(--primary-pink);
  }
}

// @media screen and (min-width: px) {
//   .increment_wrapper {

//   }
// }
