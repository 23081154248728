.hint_wrapper {
  display: inline-block;
  position: relative;

  &:hover .hint_popup {
    animation: popup var(--hint_delay) forwards;
    pointer-events: auto;

    @media (max-width: 1023px) {
      animation: popup forwards;
    }
  }
}

.hint_popup {
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 20px;
  pointer-events: none;
  text-align: center;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
  border-radius: 3px;
  z-index: 9;
  width: fit-content;
  word-break: normal;

  &.bottom {
    top: calc(100% + 15px);
    bottom: inherit;
  }
}

.hint_pointer_wrapper {
  position: absolute;
  width: 100%;
  height: 20px;
  overflow: hidden;
  pointer-events: none;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) rotateZ(180deg);

  &.bottom {
    top: -20px;
    transform: translateX(-50%) rotateZ(360deg);
  }
}

.pointer {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: var(--forms2_shadow);
}

@keyframes popup {
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hint_full {
  width: 100%;
}

.hint_fit {
  width: fit-content;
  z-index: 100;
}
