.page_title {
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
  color: var(--main-text);
}
.order_id {
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
}

.focused_input {
  input {
    cursor: pointer;
  }
}

.status_wrapper {
  display: flex;
  margin-bottom: 20px;
}

.creation_date_wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.expiration_date_wrapper {
  display: flex;
  flex-direction: column;
}

.field_wrapper {
  width: 100%;
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 115%;
}

.main_title,
.additional_title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: var(--main-text);
}

.select {
  margin-bottom: 12px;
}

.input_field_wrapper {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.total_amount {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.price {
  font-weight: 600;
  font-size: 18px;
  line-height: 130.5%;
  color: var(--main-text);
}

.order_description {
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  margin-bottom: 20px;
  color: var(--main-text);
}

@media screen and (min-width: 768px) {
  .modal {
    max-width: 600px;
  }

  .buy_btn {
    width: 200px;
  }

  .page_title {
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;
  }

  .creation_date_wrapper {
    flex-direction: row;
  }
  .expiration_date_wrapper {
    flex-direction: row;
  }

  .field_wrapper {
    width: 250px;
  }

  .value {
    display: block;
    margin-left: 5px;
  }

  .main_block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .input_field_wrapper,
  .select {
    width: 250px;
  }

  .additional_block {
    display: flex;
    justify-content: space-between;
  }

  .cancel_btn {
    margin-left: 30px;
  }
}
