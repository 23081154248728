.modal {
  width: 90%;
  background-color: var(--main-bgc);

  @media screen and (min-width: 768px) {
    max-width: 625px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1077px;
  }
}

.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  max-width: 90%;
  color: var(--main-text);
}

.tableHeader {
  display: none;
  padding: 20px 20px;
  width: 99%;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--menu-text-3);
  align-items: center;
  margin-right: 20px;
  &.first_item {
    flex-basis: 20.9%;
  }
  &.second_item {
    flex-basis: 37.4%;
  }
  &.third_item {
    flex-basis: 22%;
  }
  &.fourth_item {
    flex-basis: 11.8%;
  }
}

.item {
  flex-direction: column;
  padding: 15px 17px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
  position: relative;
  cursor: pointer;
  position: relative;
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourth {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--main-text);
  word-break: break-word;
  white-space: break-spaces;
}

.dotIcons {
  display: none;
}

.tableItems {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 7px;
}

@media screen and (min-width: 768px) {
  .item {
    flex-basis: 48%;
    margin-right: 0;

    @media (max-width: 1023px) {
      &:nth-child(odd) {
        margin-right: 2%;
      }
    }
  }

  .item_text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .dotIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: var(--main-text);
    &:active {
      transform: scale(0.95);
    }
  }

  .tableHeader {
    display: flex;
    padding: 20px 60px 13px;
  }

  .item {
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;
    padding: 20px 26px;
  }

  .tableBlockFirst,
  .tableBlockSecond,
  .tableBlockThird,
  .tableBlockFourth {
    margin-top: 0px;
    margin-right: 20px;
  }

  .tableBlockFirst {
    flex-basis: 20.9%;
  }

  .tableBlockSecond {
    flex-basis: 37.4%;
  }

  .tableBlockThird {
    flex-basis: 22%;
  }

  .tableBlockFourth {
    flex-basis: 11.8%;
  }

  .item_text {
    font-size: 14px;
    align-items: center;
  }
}

@media screen and (min-width: 1550px) {
  .tableHeader {
    padding: 22px 27px;
  }

  .item {
    padding: 22px 27px;
  }

  .item_text {
    font-size: 14px;
  }
}

@media screen and (min-width: 1918px) {
  .tableHeader {
    padding: 22px 27px;
  }

  .item_text {
    font-size: 14px;
  }
}
