.cta {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.button {
  font-size: 16px;
  line-height: 1.13;
  padding: 15px 24px;
  border-radius: 100px;
  height: 48px;
  text-transform: uppercase;
  transition: transform 0.1s;

  &:active {
    transform: scale(0.95);
  }
  @media (min-width: 1024px) {
    padding: 19px 24px;
    height: 56px;
  }
}

.button_primary {
  background: var(--primary-pink);
  color: var(--primary-white);

  &:hover {
    background: var(--primary-purple);
  }
  &:active {
    background: var(--primary-dark-purple);
  }
}
.button_secondary {
  background: transparent;
  border: 1px solid currentColor;
  color: var(--primary-pink);

  &:hover {
    color: var(--primary-purple);
  }
  &:active {
    color: var(--primary-dark-purple);
  }
}
.button_third {
  background: transparent;
  border: 1px solid currentColor;
  color: var(--primary-purple);

  &:hover {
    color: var(--primary-white);
    background: var(--primary-purple);
    border-color: var(--primary-purple);
  }
  &:active {
    color: var(--primary-white);
    background: var(--primary-dark-purple);
    border-color: var(--primary-dark-purple);
  }
}
.button_fourth {
  background: var(--primary-white);
  color: var(--primary-dark);

  &:hover,
  &:active {
    color: var(--primary-white);
    background: var(--primary-pink);
  }
}

.text_primary {
  color: var(--primary-pink);

  &:hover {
    color: var(--primary-purple);
  }
  &:active {
    color: var(--primary-dark-purple);
  }
}
.text_secondary {
  color: var(--primary-pink);
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
.text_third {
  color: var(--primary-dark);
  &:hover,
  &:active {
    text-decoration: underline;
  }
}
.text_fourth {
  color: var(--primary-dark);
  &:hover,
  &:active {
    color: var(--primary-pink);
  }
}

.round-btn {
  width: 48px;
  height: 48px;
  background: var(--primary-pink);
  border-radius: 99px;
  transform: scale(1);
  transition: transform 100ms;
  color: var(--primary-white);

  &:hover {
    transform: scale(1.05);
    background: var(--primary-purple);
  }

  &:active {
    transform: scale(0.95);
    background: var(--primary-dark-purple);
  }
}

.link_primary {
  color: var(--primary-pink);

  &:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 1024px) {
  .round-btn {
    width: 56px;
    height: 56px;
  }
}

/* Temporarily created a unified style for the fifth type of btn */
.button_fifth,
.text_fifth,
.link_fifth {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  gap: 2px;
  color: var(--primary-purple);

  background: var(--primary-light-purple);
  border-radius: 100px;

  @media screen and (min-width: 768px) {
    padding: 2px 2px 2px 6px;
  }

  &:hover {
    background: var(--primary-purple);
    color: var(--primary-white);
  }
}
