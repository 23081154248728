
.text {
  font-size: 15px;
}

.warn {
  font-size: 14px;
}


.names_block {
  transition: height 150ms;
  font-size: 14px;
  color: var(--other-dark-50);
}

.btn_more {
  color: var(--link-color);
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid var(--link-color);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 18px;
  }

  .warn {
    font-size: 16px;
  }

  .cancel_btn {
    font-size: 20px;
  }

  .delete_btn {
    font-size: 20px;
  }
}
