@import 'modern-normalize';
@import './variables.scss';
@import './typography.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.04em;
  color: var(--primary-dark);
  background: var(--other-gradient);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --scrollbar-width: 5px;

  *,
  & {
    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-track {
      background: rgb(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(255, 74, 183, 0.8);
      cursor: pointer;

      &:hover {
        background: rgba(255, 74, 183, 1);
      }

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.container {
  max-width: 1600px;
  margin: 0 auto;
}

// ------- reset styles -------
ul {
  list-style: none;
  padding: 0;
  margin: 0;
  outline: none;
}
a {
  text-decoration: none;
  color: inherit;
}
button {
  border: none;
  background: transparent;
  padding: 0;
  color: inherit;
  cursor: pointer;
}
th,
td,
input {
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

svg {
  flex-shrink: 0;
}

[type='search'] {
  outline-offset: 0;
}

.notInViewport {
  opacity: 0.45;
}

.asterisk {
  color: var(--system-error);
  font-size: 18px;
  margin-right: 4px;
}

/** STORYBOOK ICONS */
.css-ngu85f {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.css-15erloe {
  height: 50px !important;
  width: 50px !important;
}

.css-87agpt {
  margin-top: 10px;
  margin-left: 0px !important;
}
/** /STORYBOOK ICONS */

.Toastify__toast-container {
  width: 90%;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
}

.Toastify__toast {
  border-radius: 16px;
  padding: 10px;
  background: var(--primary-white);
}

.Toastify__close-button {
  color: var(--primary-dark);
  opacity: 1;
}

.Toastify__progress-bar {
  left: 15px;
  width: calc(100% - 30px);
  border-radius: 3px;
  height: 3px;
}

/** If we will replace all inputs with one Input component, 
than we can move this class inside of the component  */
.input_base_styles {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  border: 1px solid var(--other-dark-20);
  font-weight: 300;
  font-size: 16px;
  background-color: var(--primary-white);
  color: inherit;

  &.pr {
    padding-right: 45px;
  }

  &.pl {
    padding-left: 36px;
  }

  &::placeholder {
    font-size: 16px;
    opacity: 0.4;
  }

  &:focus {
    outline: transparent;
    border: 1px solid var(--primary-dark);

    /** To remove this class */
    & ~ .input_border {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 4px;
      background-image: var(--accent-gradient);
    }

    & ~ .field_icon,
    & ~ .field_icon_left {
      fill: var(--primary-dark);
    }
  }
}

.error_message {
  display: block;
  margin-top: 6px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1;
  text-align: start;
}

.input_label {
  display: block;
  margin-left: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
  text-align: start;
  font-weight: 400;
  font-size: 12px;
}

.btn_base_styles {
  padding: 15px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: var(--primary-pink);
  color: var(--primary-white);
  cursor: pointer;
  transition: transform 0.1s;

  &:hover {
    background: var(--primary-purple);
  }

  &:active {
    transform: scale(0.95);
    background: var(--primary-dark-purple);
  }

  @media screen and (min-width: 1024px) {
    padding: 19px 32px;
  }
}

.box_styles {
  background: var(--primary-white-40);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid var(--primary-white-60);
}

.page_title {
  @extend .headings_h1;

  margin-bottom: 32px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 460px) {
  .Toastify__toast-container {
    left: unset;
    transform: unset;
    width: var(--toastify-toast-width);
    bottom: 1em;
    right: 1em;
  }
}

.in_row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.align_start {
  align-items: flex-start;
}

.gap-0 {
  gap: 0px;
}

.rotate_180 {
  transform: rotate(180deg);
}

.pink {
  color: var(--primary-pink);
}

.flex-grow {
  flex-grow: 1;
}

label {
  cursor: pointer;
}

.line_clamp {
  white-space: initial;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

@for $i from 1 through 5 {
  .clamp_#{$i} {
    -webkit-line-clamp: #{$i};
  }
}

.color-success {
  color: var(--system-success);
}
.color-error {
  color: var(--system-error);
}
.color-warn {
  color: var(--system-orange);
}
.color-info {
  color: var(--system-blue);
}
