.status {
  display: inline-block;
  width: fit-content;
  padding: 5px 8px;
  border-radius: 18px;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
}

.in_progress {
  background-color: var(--system-orange-light);
  color: var(--system-orange-dark);
}
.waiting_to_reply {
  background-color: var(--system-green-light);
  color: var(--system-green-dark);
}
.new_replies {
  background-color: var(--primary-pink);
  color: var(--primary-white);
}
.in_archive {
  background-color: var(--system-red-light);
  color: var(--system-red-dark);
}
.opened {
  background-color: var(--system-light-blue);
  color: var(--system-dark-blue);
}
