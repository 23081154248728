.table {
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
}

.tableHeader {
  display: none;
  align-items: center;
  padding: 20px 23px 13px;
  width: 100%;
}

.checkBoxColumn {
  flex-basis: 100%;
  margin: 0 14px;
  padding: 15px 0;
  border-bottom: 1px solid var(--select_option_bgc);
}

.columnsWithoutCheckBox {
  padding: 15px 17px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--menu-text-3);
  align-items: center;
  margin-right: 20px;
  &.first_item {
    flex-basis: 13.5%;
  }
  &.second_item {
    flex-basis: 18.3%;
  }
  &.third_item {
    flex-basis: 21.4%;
  }
  &.fourth_item {
    flex-basis: 16.7%;
  }
  &.fifth_item {
    flex-basis: 12.7%;
  }
  &.sixth_item {
    flex-basis: 17.4%;
  }
}

.item {
  flex-direction: column;
  width: 100%;
  display: flex;
  margin-bottom: 7px;
  position: relative;
  border-radius: 3px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);
}

.dots {
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourth,
.tableBlockFifth,
.tableBlockSixth {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-text);
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.line {
  border: 1px solid #e1daea;
  width: 100%;
  margin-bottom: 15px;
}

.mobileBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btnsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileBtn {
  flex-basis: 32%;
  padding: 10px 0;
  background: var(--forms_bgc_4);

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  color: var(--main-text);
  box-shadow: var(--forms2_shadow);

  svg {
    width: 16px;
    margin-bottom: 5px;
  }

  svg,
  path {
    fill: var(--transparent-color);
  }

  &:hover {
    svg,
    path {
      fill: url(#gradient);
    }
  }

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.headerColumnsWithoutCheckBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

@media screen and (min-width: 600px) {
  .item {
    max-width: calc(50% - 15px);
    flex-basis: calc(50% - 15px);
    margin-right: 15px;
  }

  .item_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1080px) {
  .item {
    max-width: calc(33% - 15px);
    flex-basis: calc(33% - 15px);
    margin-right: 15px;
  }
}

@media screen and (min-width: 1359px) {
  .item {
    max-width: calc(25% - 15px);
    flex-basis: calc(25% - 15px);
  }
}

@media screen and (min-width: 1550px) {
  .columnsWithoutCheckBox {
    max-height: 60px;
    flex-direction: row;
    padding: 21px 15px;
    border-radius: 3px;
    background-color: var(--forms_bgc_2);
    box-shadow: var(--forms_shadow);
  }

  .checkBoxColumn {
    flex-basis: 3.5%;
    margin: 0;
    padding: 0;
    margin-right: 23px;
    border-bottom: none;
  }

  .headerColumnsWithoutCheckBox {
    padding: 0 15px;
  }

  .dots {
    position: relative;
    top: 0;
    right: 0;
  }

  .table {
    display: block;
  }

  .tableHeader {
    display: flex;
    padding: 20px 0 13px;
  }

  .item {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .tableBlockFirst,
  .tableBlockSecond,
  .tableBlockThird,
  .tableBlockFourth,
  .tableBlockFifth,
  .tableBlockSixth {
    margin-top: 0px;
    margin-right: 20px;
  }

  .tableBlockFirst {
    flex-basis: 13.5%;
  }

  .tableBlockSecond {
    flex-basis: 18.3%;
  }

  .tableBlockThird {
    flex-basis: 21.4%;
  }

  .tableBlockFourth {
    flex-basis: 16.7%;
  }

  .tableBlockFifth {
    flex-basis: 12.7%;
  }

  .tableBlockSixth {
    flex-basis: 17.4%;
  }

  .item_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1918px) {
  .columnsWithoutCheckBox {
    padding: 21px 41px;
  }

  .headerColumnsWithoutCheckBox {
    padding: 0 41px;
  }

  .tableHeader {
    padding: 22px 0px 13px;
  }

  .item_text {
    font-size: 16px;
  }
}
