.offerBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.offerBlockText {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

