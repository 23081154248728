.domainsZone {
  margin-top: 40px;
}

.domainsZoneTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
}

.chooseAllBlock {
  display: flex;
  align-items: center;

  padding: 30px 10px 10px 15px;

  .chooseAllText {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 113%;
    color: var(--main-text);
    margin-left: 12px;
  }
}

.domainsBlock {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  margin-top: 10px;
  gap: 20px;
}

.domainItem {
  padding: 20px 10px 20px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  cursor: pointer;

  &.selected {
    outline: 1px solid var(--primary-pink);
    background: var(--primary-white);
  }

  &:hover {
    background-color: var(--domains_hover_bgc);
  }
}

.domainName {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 113%;
  color: var(--main-text);
  margin-left: 25px;

  &.selected {
    background: linear-gradient(270deg, #ff42a8 0%, #e030bd 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.domainPrice {
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 70.9%;
  color: var(--main-text);
}

.pricesBlock {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row-reverse;
  gap: 10px;
}

.saleEur {
  position: relative;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 70.9%;
  // text-decoration-line: line-through;
  color: var(--transparent-color);
  margin-top: 11px;

  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--transparent-color);
    position: absolute;
  }

  .year {
    font-size: 12px;
  }

  .selected & {
    color: var(--red-text);
    text-decoration-color: var(--red-text);
  }
}

.checkbox {
  background-color: var(--forms_bgc_4);
}

.prolongBlock {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: 300;

  span {
    font-size: 14px;
  }
}

.prolongPeriod {
  text-transform: lowercase;
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

.priceDescr {
  margin-top: 10px;

  font-weight: 300;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
}

@media screen and (min-width: 768px) {
  .domainsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 25px;
  }
}

@media screen and (min-width: 1400px) {
  .domainPrice {
    font-size: 17px;
    font-weight: 500;
  }

  .saleEur {
    font-size: 14px;

    .year {
      font-size: 12px;
    }
  }
}
