.filter__clouds {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 6px;
}

.filter__cloud {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 24px;
  border: 1px solid var(--other-dark-20);
  line-height: 1;

  &:hover {
    .close_icon {
      color: var(--primary-dark);
    }
  }

  &:disabled {
    pointer-events: none;
    color: var(--other-dark-50);
  }
}

.filter__cloud_name {
  color: var(--other-dark-50);
}

.close_icon {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  color: var(--other-dark-50);
}
