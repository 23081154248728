.modal {
  max-width: 990px;
  max-height: 80vh;
  &__body {
    margin: 25px 0 40px;
    padding-top: 0;
    padding-bottom: 0;
    &::-webkit-scrollbar-button:start,
    &::-webkit-scrollbar-button:end {
      height: 0;
    }
    a {
      color: var(--link-text);
      text-decoration: underline;
    }
  }
}
