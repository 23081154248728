.notification_wrapper {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 16, 25, 0.3);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.4s ease;

  &.opened {
    opacity: 1;
    pointer-events: fill;
  }
}

.container {
  border-radius: 32px;
  padding: 24px;
  top: 72px;
  position: fixed;
  overflow-y: auto;
  background-color: var(--forms_bgc_1);
  transform: translateX(200%);
  transition: transform 0.3s;
  z-index: 150;

  max-width: 90%;
  width: 100%;

  &.opened {
    left: 50%;
    transform: translateX(-50%);
  }
}

.notification_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    left: 26px;
    right: 26px;
    margin-top: 40px;
    border-radius: 5px;
    background: var(--icon-color);
  }
}

.close_btn {
  width: 20px;
  height: 20px;

  &:hover {
    color: var(--primary-pink);
  }
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .container {
    width: 442px;
    right: 104px;
    top: 88px;

    &.opened {
      transform: translateX(0px);
      left: unset;
    }
  }

  .notification_wrapper.scrolled {
    top: -17px;
    left: -25px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    top: 96px;
  }

  .notification_wrapper.scrolled {
    top: -25px;
    left: -130px;
  }
}
