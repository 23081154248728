.wrapper {
  padding: 104px 16px 24px;
  overflow-y: auto;
  max-height: 100vh;

  scrollbar-color: #e72690 rgba(161, 161, 161, 23%);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    background: #e72690;
  }
}

.logo {
  width: 90px;
  height: 40px;
  margin-top: -8px;
}

.auth_container {
  display: flex;
  min-height: 100%;
}

@media screen and (min-width: 768px) {
  .wrapper {
    // padding-top: 74px;
    background-image: var(--auth-lines);
    background-repeat: no-repeat;
    background-position: 50% 60%;
  }

  .logo {
    width: 102px;
    height: 45px;
  }
}
