@import '@scss/placeholders';

.modal__subtitle {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  &_transparent {
    font-weight: 400;
    color: var(--transparent-color);
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 768px) {
    gap: 20px;
  }
}

.body__text {
  font-size: 18px;
  word-wrap: break-word;
}
.body__text_small {
  font-size: 14px;
}

.sshForm {
  display: grid;
  @media screen and (min-width: 768px) {
    gap: 20px;
  }
}

.ssh_footer {
  @media screen and (max-width: 499px) {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 48px;
    column-gap: 16px;
    row-gap: 8px;

    .ssh_submit_btn {
      grid-column: 1/2;
      grid-row: 1/2;
      width: 100%;
    }

    .ssh_cancel_btn {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    .ssh_download_btn {
      margin-left: unset;

      grid-column: 2/3;
      grid-row: 1/2;
    }
  }
}

.privateKey_block {
  display: grid;
  gap: 20px;
}

.sshModal_headBlock {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (min-width: 768px) {
    gap: 12px;
  }
}

.sshGenerateBtn {
  margin-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  justify-self: flex-start;

  @media screen and (min-width: 768px) {
    margin-top: 0;
    // min-width: 310px;
    justify-self: start;
  }
}

.sshAreaInput {
  overflow: unset;
  // min-height: 80px;
  height: unset;
}

.privateKeyWrapper {
  position: relative;
  .sshAreaInput {
    overflow: hidden;
  }
}

.copyKeyBtn {
  margin-bottom: 6px;
}

.privateKeyCopy {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  svg {
    display: none;
  }
}

.ssh_download_btn {
  margin-left: auto;
}

.btnWrapper {
  display: flex;
  gap: 15px;
}

.filter_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.15s;
  pointer-events: none;
  opacity: 0;

  &.opened {
    pointer-events: initial;
    opacity: 1;
  }
}

.link {
  text-decoration: underline;
  color: var(--link-color);
}

.filters_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 400px;
  width: 90vw;
  box-shadow: var(--forms2_shadow);
  background: var(--forms_bgc_1);
  border-radius: 3px;
  overflow: hidden;
  z-index: 151;
  opacity: 0;
  pointer-events: none;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 150ms;

  &.opened {
    pointer-events: initial;
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }

  @media screen and (min-width: 768px) {
    & {
      position: absolute;
      right: 0;
      left: unset;
      top: calc(100% + 15px);
      overflow: initial;
      height: fit-content;
      max-width: initial;
      width: fit-content;
      padding: 10px 20px 20px;
      transform: scale(0);

      &.opened {
        transform: scale(1);
      }

      &::after {
        content: '';
        position: absolute;
        right: 13px;
        top: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--forms_bgc_1);
      }
    }
  }

  @media screen and (min-width: 1600px) {
    & {
      right: -20px;

      &::after {
        right: 34px;
      }
    }
  }

  @media screen and (min-width: 1918px) {
    & {
      padding: 26px 31px;
    }
  }

  .inputHeight {
    height: 45px !important;
    background-color: var(--forms_bgc_3);

    input {
      background-color: var(--forms_bgc_3);
    }
  }

  .inputHeightExpire {
    height: 45px !important;
    background-color: var(--forms_bgc_3);
  }
  .form {
    display: flex;
    flex-direction: column;
    min-width: 261px;
  }

  .fieldsBlock {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;

    padding: 17px 14px;
    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(161, 161, 161, 23%);
    }

    &::-webkit-scrollbar-thumb {
      background: #e72690;
    }
  }
  .input {
    max-width: 100%;
    margin-top: 20px;
    margin-right: 0px;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .select {
    max-width: 100%;
    margin-top: 20px;
    margin-right: 0px;

    button {
      height: 47px;
    }

    &.dateSelect {
      width: 195px;
      margin-right: 20px;
    }
  }
  .btnBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 14px;
    z-index: 2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--forms_bgc_2);
    box-shadow: var(--modal_footer_shadow);
  }

  .clearFilters {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-decoration-line: underline;
    margin-left: 0px;
    margin-top: 20px;
    color: var(--main-text);
    cursor: pointer;
  }

  .searchBtn {
    height: 50px;
    max-width: 100%;
    font-size: 18px;
    text-transform: uppercase;
  }

  .formHeader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 17px 14px;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    z-index: 2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--forms_bgc_2);
    box-shadow: var(--modal_header_shadow);

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 113%;
      color: var(--main-text);
    }
  }
  .crossIcon {
    fill: var(--main-text);
  }

  .input_bgc {
    background-color: var(--forms_bgc_3);
  }

  @media screen and (min-width: 768px) {
    .formHeader {
      display: none;
    }

    .searchBtn {
      max-width: 230px;
    }

    .clearFilters {
      margin-left: 40px;
      margin-top: 0px;
    }

    .btnBlock {
      flex-direction: row;
      justify-content: flex-start;

      padding: unset;
      z-index: unset;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: unset;
      box-shadow: unset;
    }

    .fieldsBlock {
      max-height: 100vh;
      overflow: visible;
      padding: 0 0 17px 0;
    }

    .form {
      min-width: 645px;
    }

    .input {
      max-width: 280px;
      margin-top: 20px;
      margin-right: 15px;
    }

    .select {
      max-width: 280px;
      margin-top: 20px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1024px) {
    .form {
      min-width: 768px;
    }

    .input {
      max-width: 228px;
      margin-top: 20px;
      margin-right: 15px;
    }

    .select {
      max-width: 228px;
      margin-top: 20px;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 1600px) {
    // .form {
    //   min-width: 1020px;
    // }

    .filterModal {
      left: -20px;

      &::after {
        left: 15px;
        top: -10px;
      }
    }

    .input {
      max-width: 240px;
      margin-top: 13px;
      margin-right: 15px;
    }

    .select {
      max-width: 240px;
      margin-top: 13px;
      margin-right: 15px;

      &.dateSelect {
        max-width: 185px;
        width: 185px;
      }
    }
  }
}

// resize

.tariff {
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0px 1px 8px 0px #41267226;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.tariff_active {
  border: 1px solid #fa4bb4;
}

.tariffs_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 10px;
}

.tariff__name {
  font-size: 25px;
  font-weight: 700;
}

.tariff__price {
  font-size: 30px;
  font-weight: 700;
  background: var(--accent-text-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tariff__params {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-items: self-start;
  gap: 6px 30px;
}

.tariff__param_name {
  color: #b3a9c1;
  font-size: 14px;
}

.rebuild_list_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.rebuild__os_list {
  display: grid;
  gap: 10px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.rebuild_modal {
  max-width: 1000px;
}

.resize_modal {
  max-width: 700px;
}

.resize_header {
  padding: 32px;
}

.rebuild_modal__body {
  padding-bottom: 180px;
  overflow-x: hidden;
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

.amount_label {
  color: var(--transparent-color);
  font-size: 14px;
  white-space: nowrap;
}

.price {
  @extend %accent-text-gradient;
  font-size: 20px;
  font-weight: 600;
}

.price_block {
  margin-right: auto;
}

.btn_confirm {
  margin: auto;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    max-width: fit-content;
  }
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.btns_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.without_margin {
  margin: 0;
}

.hint_wrapper {
  max-width: 242px;
}

.infoIcon {
  path {
    fill: var(--transparent-color);
  }
}

@media screen and (min-width: 768px) {
  .price {
    font-size: 30px;
  }

  .footer {
    grid-template-columns: 1fr 2fr;

    justify-items: flex-end;
  }

  .btns_wrapper {
    flex-direction: row;
    justify-items: flex-end;
  }
}

.infoBtnCard {
  cursor: help;

  &:hover {
    path {
      fill: var(--accent-color-light);
    }
  }
}

.rescue_pass_input {
  @media (min-width: 1024px) {
    max-width: 460px;
  }
}

.grid {
  display: grid;
  grid-template-columns: var(--grid);
  gap: 8px;
}

.btn_more {
  display: flex;
  margin-top: 5px;
  margin-left: auto;

  font-weight: 500;
  font-size: 14px;
  color: var(--primary-pink);
  text-align: right;
}
