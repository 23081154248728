.page_title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 113%;
  color: var(--main-text);
}

.ownerForm {
  max-height: 0;
  height: auto;
  overflow: hidden;
  padding-bottom: 21px;
  transition: max-height 0.15s ease-out;

  &.isOpen {
    max-height: 2225px;
    height: auto;
    transition: max-height 0.25s ease-in;
  }
}

.formBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  max-width: 720px;
  margin-left: 5px;
}

.phoneInputContainer {
  height: 55px;
}

.formBlockTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 115%;
  margin-bottom: 5px;
  color: var(--main-text);
}

.formFieldsBlock {
  display: grid;
  gap: 16px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.titleBlock {
  display: flex;
  align-items: center;
  cursor: pointer;

  .shevronIcon {
    margin-left: 16px;
    stroke: var(--main-text);
    transform: rotate(180deg);
    transition: linear 0.1s;

    &.isOpen {
      transform: rotate(0deg);
    }
  }
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.category_title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 115%;
  color: var(--main-text);
}

.useFirstCheck {
  display: flex;
  align-items: center;
  margin-top: 25px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: var(--main-text);
    margin-left: 18px;
  }
}

.selectDropDown {
  top: inherit;
  > div {
    max-height: 200px;
  }
}

@media screen and (min-width: 768px) {
  .form {
    align-items: center;
  }

  .formFieldsBlock > div:nth-child(2n) {
    margin-right: 0px;
  }

  .formBlock {
    margin-top: 25px;
  }


  .phoneInputLabel {
    margin: 0 0 10px 10px !important;
  }

  .ownerForm {
    max-height: 0;
    height: auto;
    overflow: hidden;
    padding-bottom: 21px;
    transition: max-height 0.15s ease-out;

    &.isOpen {
      max-height: 1250px;
      height: auto;
      transition: max-height 0.25s ease-in;
    }
  }
}
