.category_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 8px;
  width: 100%;
  height: 48px;
  border-radius: 99px;
  border: 1px solid var(--other-dark-20);
  background-color: var(--primary-white);
  transform: scale(1);
  transition: transform 80ms;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    filter: opacity(0.6);
  }

  &:active {
    transform: scale(0.97);
  }

  &:hover {
    border: 1px solid var(--primary-dark);
  }

  &.selected {
    color: var(--primary-pink);
    border: 1px solid var(--primary-pink);
  }
}

@media screen and (min-width: 1024px) {
  .category_btn {
    height: 56px;
  }
}
