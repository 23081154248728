.table {
  td,
  th {
    &:nth-child(1) {
      padding-left: 32px;
    }
    &:last-child {
      text-align: end;
      padding-right: 32px;
    }
    padding: 13px 10px;
  }

  td {
    font-size: 16px;
    white-space: nowrap;

    > .table_el_button {
      margin-right: 8px;
    }
  }
}

.mobile__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px 15px;
}

.mobile_item {
  max-width: 330px;
  display: grid;
  gap: 24px;
}

.mobile_item__header {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 48px;
  align-items: flex-start;
}

.mobile_item_options_wrapper {
  grid-column: 2/3;
  grid-row: 1/2;
}

.mobile_item__header_name {
  grid-column: 1/2;
  grid-row: 1/2;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobile_item__name {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 120%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.item:hover {
  cursor: pointer;

  .options {
    background-color: var(--primary-pink);
  }

  .btn {
    color: var(--primary-dark);

    &:hover {
      color: var(--primary-pink);
    }
  }
}

.options {
  background-color: var(--primary-white);
  width: fit-content;
  border-radius: 100px;
  padding: 4px;
}

.btn {
  border-radius: 50%;
  width: 32px;
  height: 32px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: var(--primary-white);
    color: var(--primary-pink);
  }
}

.mobile_item__param {
  font-size: 14px;
  color: #b3a9c1;
  white-space: nowrap;
}

.mobile_item__value {
  font-size: 14px;
  word-break: break-all;
}

.no_vds_wrapper {
  text-align: center;
  margin: 40px auto;
  max-width: 500px;
}

.no_vds {
  display: block;
  margin: 0 auto 20px;
  filter: grayscale(1);
}

.no_vds_title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}
