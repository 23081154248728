.dates_wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 14px;
}

.title {
  font-weight: 600;
  font-size: 18px;
}

.tariff_name {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.date {
  display: block;
  font-weight: 400;
}

.mb {
  margin-bottom: 12px;
  padding-bottom: 0;
}

.license_select {
  max-width: 230px;
}

.chapter_title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 15px;
}

.chapter_btn {
  margin-top: 20px;
  margin-bottom: 15px;
}

.arrow_icon {
  transform: rotateZ(0);
  transition: transform 150ms;
  margin-left: 10px;

  &.opened {
    transform: rotateZ(180deg);
  }
}

.add_ons_wrapper {
  height: 0;
  overflow: hidden;
  transition: height 150ms;
}

.total_amount {
  font-size: 16px;
  margin-bottom: 10px;
}

.price {
  font-weight: 600;
}

.description {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .date_line {
    display: flex;
    gap: 6px;
  }

  .mb {
    margin-bottom: 0;
  }

  .grid_fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .chapter_title {
    font-size: 18px;
  }

  .chapter_btn {
    font-size: 18px;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
  }

  .add_ons_wrapper {
    margin-bottom: 10px;
  }
}
