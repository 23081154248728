.server_item {
  position: relative;
  border-radius: 24px;
  padding: 16px;

  background-color: var(--primary-light-purple);
}

.priceList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.control_bts_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.domainInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.btn_delete {
  width: 20px;
  height: 20px;

  &:hover {
    color: var(--primary-pink);
  }
}

.periodInfo {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  span {
    color: var(--other-dark-50);

    &:not(:last-child) {
      padding-right: 5px;
      border-right: 1px solid var(--other-dark-20);
    }
  }
}

@media screen and (min-width: 768px) {
  .domainItem {
    flex-direction: row;
    align-items: center;
  }
}
