.form_wrapper {
  width: 100%;
  max-width: 400px;
  height: fit-content;
  padding: 32px 16px;
  margin: auto;
  text-align: center;
}

.title {
  text-align: center;
  margin-bottom: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
  margin-bottom: 16px;
}

.submit_btn {
  text-transform: uppercase;
}

.reset_pass_link {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.social_title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 32px;
  text-align: center;
  text-wrap: nowrap;
  opacity: 0.2;

  &::after,
  &::before {
    content: '';
    height: 1px;
    width: 100%;
    background: var(--primary-dark);
  }
}

.social_list {
  display: flex;
  margin: 0 auto 32px;
  justify-content: center;
  gap: 8px;
}

.recaptcha_wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  min-height: 63px;

  @media screen and (min-width: 380px) {
    min-height: 78px;
  }
}

.recaptcha {
  transform: scale(0.8);
  transform-origin: center top;
  margin: 0 auto -15px;

  @media screen and (min-width: 380px) {
    transform: scale(1);
    margin: 0 auto;
  }
}

.credentials_error {
  height: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  color: var(--system-error);
  background-color: rgba(255, 68, 102, 0.1);

  a {
    color: var(--link-color);
    text-decoration: underline;
  }
}

.success_notif {
  @extend .credentials_error;
  background-color: var(--success-bgc);
  color: var(--system-green-dark);
}

.loaderBlock {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.loader {
  position: absolute;
}

.pink_link {
  color: var(--primary-pink);

  &:hover {
    color: var(--primary-purple);
  }
}

.soc_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--primary-white);
}

.description {
  text-align: start;
}

.mail_link {
  color: var(--link-color);
  text-decoration: underline;
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Verfication modal */
@import '@scss/placeholders.scss';

.backdrop {
  @extend %backdrop;
  background: rgba(205, 205, 205, 0.65);
  border-radius: 24px;

  &.shown {
    pointer-events: initial;
    opacity: 1;

    .modalWindow {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.modalWindow {
  position: relative;
  width: calc(100% - 16px);
  max-width: 400px;
  height: fit-content;
  padding: 32px 16px;
  margin: auto;
  border-radius: 24px;
  border: 1px solid var(--primary-white-60);
  backdrop-filter: blur(10px);
  background: var(--primary-white-60);
  text-align: center;

  transform: scale(0);
  opacity: 0;
  transition: opacity 0.15s, transform 0.15s;
}

@media screen and (min-width: 768px) {
  .modalWindow {
    padding: 32px 40px;
  }
}

@media screen and (min-width: 1024px) {
  .form_wrapper {
    padding: 32px 148px;
    max-width: 602px;
  }

  .credentials_error {
    font-size: 15px;
  }
}
