.select_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  width: 72px;
  height: 48px;
  background: var(--primary-white);

  &.burger_type {
    --header-btn-width: 66px;
    --header-btn-height: 36px;
  }

  &.auth_type {
    --header-btn-width: 66px;
    --header-btn-height: 33px;
  }

  &.main_type {
    --header-btn-width: 62px;
    --header-btn-height: 33px;
  }

  &.opened {
    z-index: 100;
  }

  &:hover {
    background: var(--primary-pink);

    .current_lang {
      color: var(--primary-white);
    }

    .icon {
      transform: rotateZ(180deg);
      stroke: var(--primary-white);
    }

    // .dropdown_list {
    //   transform: translateY(0);
    // }

    // .lang_dropdown {
    //   pointer-events: initial;
    // }
  }
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background-color: rgba(19, 16, 25, 0.3);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.3s ease;

  &.opened {
    opacity: 1;
    pointer-events: fill;

    @media screen and (max-width: 1023px) {
      touch-action: none;
    }
  }

  &.scrolled {
    top: -12px;
  }

  @media screen and (min-width: 768px) {
    top: 0;
    left: 0;

    &.scrolled {
      top: -17px;
      left: -9px;
    }
  }

  @media screen and (min-width: 1024px) {
    &.scrolled {
      top: -25px;
      left: -130px;
    }
  }
}

.current_lang {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: calc(var(--header-btn-height));
  border-radius: 99px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-text);
  line-height: 1;

  img {
    margin-right: 7px;
  }
}

.icon {
  height: 10px;
  width: 10px;
  margin-left: 5px;
  transition: transform 0.2s ease-in;
}

.lang_dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding-top: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // pointer-events: none;
  // z-index: 10;
}

.dropdown_list {
  position: relative;
  max-width: calc(100% + 50px);
  width: 100%;
  height: fit-content;
  padding: 16px;
  border-radius: 24px;
  background-color: var(--primary-white);
  // transform: translateY(calc(-200% - 20px));
  // transition: transform 0.15s;

  border: 1px solid var(--primary-pink);

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shevron_wrapper {
  position: absolute;
  width: 30px;
  height: 20px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.dropdown_shevron {
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%) rotateZ(45deg);
  width: 14px;
  height: 14px;
  background-color: var(--forms_bgc_4);
  box-shadow: 3px 2px 12px rgba(57, 41, 85, 0.25);
}

.lang_btn {
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;

  img {
    margin-right: 7px;
  }

  &:hover {
    color: var(--primary-pink);
  }
}

// ADAPTIVE
@media screen and (min-width: 768px) {
  .current_lang {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .select_wrapper {
    height: 56px;
  }
}
