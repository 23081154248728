@import '@scss/typography.scss';
@import '@scss/common.scss';

.modalBg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 149;
  overflow-y: hidden;

  &.closing {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modalBlock {
  position: relative;
  max-width: 900px;

  &:not(.visible) {
    opacity: 0;
  }
}

.hide {
  display: none;
}

.closeModal {
  max-width: 360px;
}

.modalCloseBlock {
  margin: 20px auto;
  width: 100%;
}

.formBlockList {
  counter-reset: section;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .formBlock {
    position: relative;

    .formBlockTitle {
      padding-left: 32px;
      line-height: 24px;
    }

    &::before {
      @extend .body_s;
      position: absolute;
      left: 0;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--primary-dark);
      color: var(--primary-white);
      counter-increment: section;
      content: counter(section);
    }
  }
}

.formBlockTitle {
  @extend .other_btn_uppercase;
  margin-bottom: 24px;
}

.actLine {
  width: 100%;
  height: 1px;
  background: rgba(179, 169, 193, 0.51);
}

.formFieldsBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
}

.additionalPayMethodBlock {
  margin-top: 16px;
  display: grid;
  gap: 16px;
}

.blockWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;

  @media screen and (min-width: 768px) {
    gap: 14px 20px;
    align-items: flex-start;
  }
}

.closeModal__key_wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.inputPerson {
  width: 260px;
}

.inputBig {
  max-width: 100%;
  padding-bottom: 0;
}

.error_message {
  position: relative;
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 3px;
}

.notAllowPayMethod {
  font-size: 14px;
  color: var(--system-error);
  margin-bottom: 14px;
}

.sale55Promo {
  color: var(--transparent-color);
  margin-top: 11px;
  margin-left: 11px;
}

.discItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    margin-top: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600 !important;
  }
}

.cart_policy {
  margin-top: unset;
}

.priceBlock {
  display: flex;
  gap: 6px;
  align-items: center;

  span {
    text-transform: uppercase;
    color: var(--primary-pink);

    @media screen and (min-width: 768px) {
      font-size: 30px;
    }
  }
}

.footer_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 0 0 24px 24px;
  justify-items: center;
  border-top: 1px solid var(--primary-pink);
  margin: 0 0 8px;
  padding: 16px 24px;
  row-gap: 16px;

  @media screen and (min-width: 500px) {
    justify-items: start;
    justify-content: space-between;

    .totalSum {
      grid-column: 1/2;
    }

    .buttonsWrapper {
      grid-column: 2/3;
    }

    .priceBlock {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.totalSum {
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
}

.buttonsWrapper {
  grid-column: 1/3;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
}

.showMoreItemsBtn {
  margin: 20px 0 0 15px;
}

.closeText {
  text-align: center;
  margin-bottom: 20px;
}

.vds_wrapper {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.padding {
  padding: 0 22px;
}

.elements_wrapper {
  transition: max-height 0.3s ease-in-out;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 16px;

  &.opened {
    max-height: none;
  }

  @media screen and (min-width: 768px) {
    gap: 14px 20px;
    align-items: flex-start;
  }
}

.mb {
  margin-bottom: 10px;
}
.penalty_sum {
  font-size: 14px;
  font-weight: 300;
  color: var(--red-text);
  margin-top: 15px;
}

.InfoBtn {
  max-width: 250px;
  width: 100%;
}

.price_row {
  display: flex;
  gap: 5px;
  line-height: 20px;
}

.infoBtnCard {
  width: fit-content;
  color: var(--other-dark-20);
  cursor: help;

  &:hover {
    color: var(--primary-pink);
  }
}

.phoneVerificationBlock {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  background-color: var(--forms_bgc_2);
  box-shadow: var(--forms_shadow);

  margin: 22px 0;

  span {
    margin-left: 11px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    leading-trim: both;
    text-edge: cap;

    background: linear-gradient(180.08deg, #ff6f66 12.11%, #fa487d 98.37%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    max-width: 440px;
  }

  svg {
    min-width: 17px;
  }
}

@media screen and (min-width: 768px) {
  .additionalPayMethodBlock {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 14px 20px;
    align-items: flex-start;
  }

  .modalBg {
    align-items: center;
    height: 100vh;
  }

  .modalBlock {
    margin: 0.5% auto;
  }
  .headerText {
    padding: 19px 0 22px 0;
  }

  .padding {
    padding: 0 35px;
  }

  .promocodeBtn {
    width: auto;
    margin: 13px 0 0 0;
  }

  .btnBlock {
    flex-direction: row;
  }

  .btnCloseBlock {
    flex-direction: column;
  }
}

@media screen and (min-width: 1550px) {
  .modalBlock {
    margin: 3% auto;
  }
}
