@import '@scss/placeholders.scss';

.field_wrapper {
  width: 100%;
}

.label {
  display: block;
  margin-left: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.input_wrapper {
  width: 100%;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  padding-left: 16px;
  padding-right: 10px;
  border-radius: 3px;
  border: 1px solid var(--input-border-icon);
  z-index: 10;
  background-color: var(--elements-bgc);
  color: inherit;

  &.separatedFields {
    border: none !important;
    padding: 0 !important;
    .input {
      border: 1px solid var(--input-border-icon);
      border-radius: 24px;
      padding: 0 10px;
    }
  }
}

.required_star {
  color: #d93f21;
  font-size: 14px;
}

.input {
  height: 100%;
  width: 50%;
  font-size: 16px;
  color: var(--main-text);
  background-color: transparent;
  border: none;
  outline: none;

  &::placeholder {
    color: var(--transparent-color);
  }

  // &.inputAuth {
  //   &::placeholder {
  //     color: var(--auth-placeholder-color);
  //   }
  // }

  &.iconRight {
    padding-right: 40px;
  }
  &.disabled {
    color: var(--transparent-color);
  }

  &.number {
    @extend %input_type_number;
  }
}

.datesText {
  font-size: 16px;
  color: var(--main-text);
  width: 60px;

  &.placeholderDate {
    color: var(--transparent-color);
    font-size: 14px;
  }
}

.afterIputText {
  font-size: 16px;
  color: var(--transparent-color);
}

.shadow {
  border: none;
  box-shadow: var(--forms_shadow);
}

.field_bgc {
  background-color: var(--nearby-elements-bgc);
}

.line {
  width: 11px;
  height: 1.5px;
  background: var(--transparent-color);
  margin: 0 20px;
}

.calendarIcon {
  width: 25px;
}

.calendarPicker {
  cursor: pointer;
  justify-content: flex-start;

  .input {
    cursor: pointer;
  }
}

.calendarModal {
  position: absolute;
  display: none;
  top: calc(100% + 10px);
  left: 50%;
  transform: translate(-50%);

  &.expenses {
    right: -500%;
  }

  &.opened {
    display: block;
  }
}

.calendar_pointer {
  display: none;
}

@media screen and (min-width: 1024px) {
  .input_wrapper {
    height: 56px;
  }
}

@media screen and (min-width: 1918px) {
  .calendarModal {
    top: calc(100% + 15px);
  }

  .calendar_pointer {
    top: -15px;
    transform: rotate(180deg);
    left: 2%;
  }
}
