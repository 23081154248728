.aside_menu_area {
  display: none;
}

@media screen and (min-width: 768px) {
  .aside_menu_area {
    display: block;
    position: fixed;
    z-index: 15;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 24px 0 24px 24px;

    &.hover {
      .aside_menu_container {
        width: 225px;
      }

      .short_logo {
        display: none;
      }
      .logo {
        display: block;
      }

      .text {
        display: block;
        text-align: start;
      }

      .shevron {
        display: block;
        margin-left: auto;
      }
    }
  }

  .aside_menu_container {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: flex-start;
    width: 80px;
    height: 100%;
    transition: width 100ms;
    --border-radius: 24px;
    border-radius: var(--border-radius);
    overflow: hidden;
    background: var(--primary-white);
    &.right_block_shown {
      width: 457px !important;
    }
  }

  .left_block {
    position: relative;
    flex: none;
    height: 100%;
    width: 241px;
    padding: 16px 0 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: var(--primary-white);
  }

  .list_wrapper {
    position: absolute;
    top: 20%;
    bottom: 16px;
    left: 16px;
    right: 0;
    // height: 100%;
    display: flex;
    flex-direction: column;
  }

  .right_block {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    z-index: -1;
    width: 208px;
    padding: 16px;
    border-radius: 20px;
    background-color: var(--primary-light-purple);
  }

  .list {
    position: absolute;
    top: calc(48px + 24px);
    left: -16px;
    right: 0;
    height: 100%;
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .logo {
    display: none;
  }

  .logo_wrapper {
    flex: none;
    height: 64px;
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    border-radius: 100px;
    width: 48px;
    height: 48px;
    border-color: var(--primary-light-purple);
    background: var(--primary-light-purple);
    transition: all 150ms;
  }

  .img {
    color: var(--primary-purple);
  }

  .item_inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    gap: 8px;
    padding-right: 12px;
    border-radius: 99px;
  }

  .item {
    position: relative;
    width: 100%;
    padding: 0 32px 0 16px;

    &:last-child {
      height: 100%;
    }

    &.item_btn {
      width: calc(100% - 32px);
      padding: 0;
      margin-bottom: 24px;

      &:hover:not(.no_hover) .item_inner {
        background: var(--primary-light-purple);
      }
    }

    &.hovered {
      &:not(.item_btn)::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        background-image: url('../../../images/sideBarSlice.svg');
        background-repeat: no-repeat;
      }

      .item_inner {
        background: var(--primary-light-purple);
      }

      &.item_btn {
        border-radius: 99px;
      }
      .circle {
        border: 6px solid var(--primary-light-purple);
        background-color: var(--primary-white);
      }

      .shevron {
        opacity: 0;
      }
    }
  }

  .active_category:not(.hovered) {
    .img {
      color: var(--primary-white);
    }

    .circle {
      background-color: var(--primary-purple);
    }
  }

  .icon_block {
    display: block;
    width: 20px;
    height: auto;
  }

  .shevron {
    display: none;
    flex: none;
    transform: rotateZ(-90deg);
    color: var(--primary-purple);
  }

  .text {
    display: none;
  }

  .right_block_content {
    position: absolute;
    left: calc(100% + 16px);
    top: 0;
    width: 176px;
  }

  .box {
    width: 100%;
    margin-bottom: 24px;
  }

  .block_top_line {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
  }

  .block_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 6px;
  }

  .link {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.9);
      width: calc(100% + 18px);
      height: calc(100% + 8px);
      border-radius: 99px;
      background: var(--primary-white);
      z-index: -1;
      opacity: 0;
      transition: all 100ms;
    }

    &:hover {
      color: var(--primary-pink);

      &::before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &:active::before {
      transform: translate(-50%, -50%) scale(1);
    }

    &.active_link:not(:hover) .active_page_circle {
      position: relative;
      z-index: 0;
      color: var(--primary-white);

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        // margin-left: -6px;
        background: var(--primary-pink);
      }
    }
  }

  .active_page_circle {
    color: var(--primary-pink);
  }

  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: var(--primary-dark);
    pointer-events: none;
    opacity: 0;
    transition: opacity 100ms;

    &.visible {
      opacity: 0.3;
      transition: opacity 150ms;
    }
  }
}
