.bg {
  position: relative;
  z-index: var(--z-index);
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  flex: none;
  &.grey {
    filter: grayscale(1);
  }
}

.select {
  position: relative;
  z-index: 12;

  &.opened {
    border-color: var(--primary-dark);
  }

  &:hover {
    .shevron_icon {
      stroke: var(--primary-pink);
    }
  }
}

.shevron_icon {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  stroke: var(--primary-dark);
  transform: translateY(-50%);
  transition: transform 0.15s;

  &.opened {
    transform: translateY(-50%) rotate(180deg);
  }

  &.disabled {
    opacity: 0.5;
  }
}

.list_item {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 15px 20px;

  .img {
    flex: none;
  }

  &:hover {
    // background-color: var(--select_option_bgc);
    color: var(--primary-pink);

    .img {
      filter: grayscale(0);
    }
  }

  &:first-of-type {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  &:last-of-type {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
  max-height: 260px;
  border-radius: 3px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(161, 161, 161, 23%);
  }

  &::-webkit-scrollbar-thumb {
    min-height: 35px;
    background-color: #e72690;
  }
}

.name_wrapper {
  text-align: start;
}

.image_name {
  max-width: 180px;
  text-align: start;
  color: var(--transparent-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
