.card {
  overflow: hidden;
  background-color: var(--carousel-elements-bgc);
  box-shadow: var(--forms2_shadow);
  position: relative;
  z-index: 1;
  transition: opacity 0.1s;
}

.card_container {
  min-width: 237px;
  height: 289px;
}

.container {
  height: 146px;
  padding: 29px 25px 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card_numeric {
  display: block;
  font-size: 16px;
  line-height: 113%;
  color: var(--main-text);
  transition: color 0.2s;
}

.card_title {
  font-weight: 700;
  font-size: 25px;
  line-height: 113%;
  background: var(--accent-text-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--main-text);
  transition: font-size 0.1s, color 0.2s;
}

.goto {
  font-size: 18px;
  line-height: 113%;
  text-decoration: underline;
  color: #b3a9c1;
  padding-right: 13px;
  transition: color 0.2s;
}

.arrow {
  font-size: 18px;
  line-height: 113%;
  color: #b3a9c1;
  transition: color 0.2s;
}

.triangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 237px;
  height: 140px;
  background: linear-gradient(
    to bottom right,
    var(--carousel-elements-bgc) 0%,
    var(--carousel-elements-bgc) 50%,
    var(--carousel-nearby-elements-bgc) 50%,
    var(--carousel-nearby-elements-bgc) 100%
  );
  z-index: -1;
}

.icon_domains,
.icon_domains_lt,
.icon_vds,
.icon_dedicated,
.icon_dns_hosting,
.icon_dns_hosting_lt,
.icon_dns_hosting_min,
.icon_ftp_storage,
.icon_care,
.icon_forexbox,
.icon_vpn,
.icon_virtual_hosting,
.icon_wordpress,
.icon_cloud_vps {
  position: absolute;
  right: 15px;
  bottom: 20px;
}

.icon_forexbox {
  width: 85%;
  height: auto;
  right: 0;
  bottom: 5px;
}

@media screen and (min-width: 768px) {
  .card_container {
    width: 306px;
    height: 160px;
    transition: transform 0.2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);

      .card_numeric {
        color: var(--main-text);
        font-size: 18px;
      }
      .card_title {
        background: var(--accent-text-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .goto {
        color: var(--main-text);
      }
      .arrow {
        color: var(--main-text);
      }
    }
  }

  .icon_virtual_hosting {
    bottom: 10px;
    right: 10px;
  }
  .icon_ftp_storage {
    right: 10px;
  }

  .triangle {
    left: unset;
    right: 0;
    width: 206px;
    height: 160px;
  }

  .container {
    max-width: 180px;
    height: 140px;
  }
}

@media screen and (min-width: 1200px) {
  .card_container {
    width: 260px;
    height: 280px;
  }

  .container {
    max-width: 300px;
  }

  .triangle {
    left: unset;
    width: 260px;
    height: 136px;
  }

  //icons
  .icon_domains,
  .icon_domains_lt {
    width: 115;
    height: 125;
  }
  .icon_vds {
    width: 93px;
    height: 109px;
  }
  .icon_dedicated {
    width: 96px;
    height: 146px;
  }

  .icon_virtual_hosting {
    width: 128px;
    height: 98px;
    right: 20px;
    bottom: 15px;
  }

  .icon_dns_hosting,
  .icon_dns_hosting_lt {
    width: 212px;
    height: 105px;
  }

  .icon_ftp_storage {
    width: 129px;
    height: 112px;
    right: 20px;
  }
  .icon_care {
    width: 128px;
    height: 118px;
  }
  .icon_forexbox {
    width: auto;
    height: auto;
  }

  .card_title {
    font-size: 21px;
  }
  .goto {
    font-size: 18px;
  }
}

@media screen and (min-width: 1900px) {
  .card_container {
    width: 268px;
    height: 285px;
  }

  .triangle {
    left: unset;
    width: 268px;
    height: 142px;
  }

  //icons

  .icon_domains,
  .icon_domains_lt {
    width: 118;
    height: 128;
  }
  .icon_vds {
    width: 96px;
    height: 112px;
  }
  .icon_dedicated {
    width: 99px;
    height: 151px;
  }

  .icon_virtual_hosting {
    width: 132px;
    height: 101px;
  }

  .icon_dns_hosting,
  .icon_dns_hosting_lt {
    width: 219px;
    height: 108px;
  }

  .icon_ftp_storage {
    width: 133px;
    height: 116px;
  }
  .icon_care {
    width: 132px;
    height: 122px;
  }
  .icon_forexbox {
    width: 127px;
    height: 94px;
  }
}
