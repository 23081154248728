.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 50;
  padding: 0 16px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;

  &.scrolled {
    border-radius: 24px;
    backdrop-filter: blur(10px);
    border: 1px solid var(--primary-white);

    &::before {
      opacity: 1;
    }
  }

  // background: var(--primary-light-purple);
  // box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
}

.flex {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    cursor: pointer;
  }
}

.btns_wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

@media screen and (min-width: 768px) {
  .header {
    height: 74px;
  }

  .btns_wrapper {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    padding: 0 24px;
  }
}
