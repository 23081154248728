.input_range {
  margin: 25px 0;
  height: 3px;

  .range-slider__range {
    background: var(--primary-pink);
  }

  [data-upper],
  [data-lower] {
    background-color: var(--primary-white);
    border: 2px solid var(--primary-pink);
    width: 14px;
    height: 14px;
  }

  &.input_range_label {
    [data-upper],
    [data-lower] {
      &:before {
        content: attr(aria-valuenow);
        min-width: 25px;
        position: absolute;
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        color: var(--primary-pink);
      }
    }
    &.custom_label_values {
      [data-upper],
      [data-lower] {
        &:before {
          content: attr(aria-displayvalue);
        }
      }
    }
  }

  &.secondary {
    .range-slider__range {
      background: var(--primary-purple);
    }
    [data-upper],
    [data-lower] {
      border-color: var(--primary-purple);
    }
    &.input_range_label {
      [data-upper],
      [data-lower] {
        &:before {
          color: var(--primary-purple);
        }
      }
    }
  }
}

.input_range__entry_fields_wrapper {
  z-index: 1;
}

.input_range__entry_fields {
  width: 200px;
  input {
    text-align: center;
  }
}
