.formBlock {
  display: flex;
  flex-direction: column;
}

.geo_wrapper {
  align-items: end;
}

.payerBlock {
  width: 100%;
}

.payerBlock,
.geo_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 14px 20px;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
}

.formBlockTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 70.9%;
  margin-bottom: 5px;
  color: var(--main-text);
}

// hide scrollbar from payerSelect List
.additionalSelectPayer {
  & > div {
    overflow-x: hidden;
  }
}

.inputBig {
  max-width: 100%;
  padding-bottom: 0;
  text-wrap: nowrap;
}

.autoCompleteInput {
  z-index: 2;
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.nsInputBlock {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.infoBtn {
  position: absolute;
  margin-left: auto;
  top: 28px;
  right: 0px;
  z-index: 2;
  padding: 12px 12px 12px 0px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  top: 90%;
  right: 0;
  width: 232px;
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 12px;
  line-height: 1.2;
}

@media screen and (min-width: 768px) {
  .nsInputBlock {
    position: relative;
    width: 100%;
  }

  .inputBig {
    width: 100%;

    &.inputAddress {
      max-width: 100%;
    }
  }

  .geo_wrapper {
    grid-column: 1/4;
  }
}

@media screen and (min-width: 1281px) {
  .infoBtn {
    top: 31px;
  }
}
