.table {
  margin-top: 20px;
}

.tableHeader {
  display: none;
  padding: 12px 23px;
  width: 100%;
}

.item_text {
  position: relative;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  &.second_item {
    max-width: 120px;
  }
}

.openTicket {
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
}

.title_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--menu-text-3);
  align-items: center;
  &.first_item {
    flex-basis: 26.8%;
  }
  &.second_item {
    flex-basis: 31.2%;
  }
  &.third_item {
    flex-basis: 35.6%;
  }
  &.fourth_item {
    flex-basis: 6.4%;
  }
}

.mobileSettings {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: flex-end;
}

.item {
  position: relative;
}

.item_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-bottom: 10px;
  flex-basis: 35%;
  min-width: 35%;
  margin-right: 20px;
}

.item_text_time {
  color: var(--transparent-color);
}

.unread {
  g {
    fill: url(#gradient);
  }
}

.chatBtn {
  cursor: pointer;
  &:active {
    transform: scale(0.95);
  }
}

.tableBlockFirst,
.tableBlockSecond,
.tableBlockThird,
.tableBlockFourt {
  flex-direction: column;
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: baseline;
}

.tableBlockFifth {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line {
  border: 1px solid #e8e3f3;
  width: 100%;
  margin-bottom: 15px;
}

.dotIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: var(--main-text);
  &:active {
    transform: scale(0.95);
  }
}

.list {
  position: absolute;
  background-color: var(--forms_bgc_4);
  top: calc(50% + 20px);
  right: -25px;
  box-shadow: var(--forms2_shadow);
  border-radius: 5px;
  display: none;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: var(--forms_bgc_4);
    transform: rotate(45deg);
    top: -7px;
    right: 27px;
    box-shadow: -1px -1px 3px -2px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &.opened {
    display: block;
  }
}

.settings_btn {
  width: 100%;
  min-width: 135px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;

  color: var(--main-text);

  .iconContainer {
    width: 15px;
  }

  svg,
  path {
    fill: var(--icon-color);
  }

  &:hover {
    background-color: var(--select_option_bgc);

    svg,
    path {
      fill: url(#gradient);
    }
  }
  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.setting_text {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 70.9%;
}

.tableBlockFourth {
  position: absolute;
  top: 8px;
  right: 8px;
}

.modal {
  max-width: 440px;
}

.modalDeleteText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130.5%;
  text-align: center;
  color: var(--main-text);
}

@media screen and (min-width: 768px) {
  .openTicket {
    display: none;
  }

  .tableHeader {
    display: flex;
    padding: 10px 30px;
  }
  .tableBlockFirst,
  .tableBlockSecond,
  .tableBlockThird,
  .tableBlockFourth,
  .tableBlockFifth {
    margin-top: 0px;
  }
  .tableBlockFirst {
    flex-basis: 26.8%;
  }
  .tableBlockSecond {
    flex-basis: 31.2%;
  }
  .tableBlockThird {
    flex-basis: 25.6%;
  }
  .tableBlockFourth {
    position: relative;
    flex-basis: 16.4%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .tableHeader {
    padding: 10px 30px 10px 40px;
  }
}
