.items_wrapper {
  display: grid;
}

.server_item {
  display: grid;

  position: relative;
  border-radius: 24px;
  padding: 16px;
  min-height: 60px;

  background-color: var(--primary-light-purple);
}

.main_info_wrapper {
  display: flex;
  flex-direction: column;
}

.control_bts_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.shevron_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shevron {
  transition: transform 150ms;
  transform: rotateZ(0);

  &.opened {
    transform: rotateZ(180deg);
  }
}

.tariff_name {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.countItem {
  font-weight: 400;
  font-size: 18px;
  color: var(--main-text);
  margin-left: 10px;
}

.btn_delete {
  cursor: pointer;

  &:hover > svg {
    fill: #f948b6;
  }
}

.percent {
  font-size: 16px;
  color: var(--red-text);
}

.discount_wrapper {
  margin-bottom: 10px;
}

.old_price {
  font-size: 16px;
  color: var(--transparent-color);
  text-decoration: line-through;
}

.price {
  font-weight: 600;
  font-size: 18px;
}

.dropdown {
  height: 0;
  transition: height 150ms;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 8px;

  &.opened {
    margin-top: 16px;
  }
}

.label {
  color: var(--other-dark-50);

  &:not(:last-child) {
    padding-right: 16px;
  }
}

.periodInfo {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 16px;

  span {
    color: var(--other-dark-50);

    &:not(:last-child) {
      padding-right: 5px;
      border-right: 1px solid var(--other-dark-20);
    }
  }
}

@media screen and (min-width: 768px) {
  .btn_delete {
    background: var(--gradient_cards_static);
    box-shadow: var(--forms_shadow_site);
    padding: 19px 16px 18px;
    margin-left: 15px;
    border-radius: 3px;
  }

  .discount_wrapper {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    gap: 5px;
    order: 2;
  }

  .price {
    order: 1;
    // text-align: end;
    // white-space: nowrap;
  }

  .price_wrapper {
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  .period {
    font-weight: 400;
  }
  .value_item {
    width: fit-content;
  }

  .discount_wrapper {
    margin-bottom: 0;
  }

  // .countItem {
  //   margin-left: 20px;
  // }
}
