@use '@scss/mixin.scss' as mixin;

.messageContainer {
  position: relative;
}

.dragging {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 1px dashed var(--link-text);
  background-color: var(--message-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 5;
  font-size: 18px;
  color: var(--link-text);
}

.fieldsBlock {
  padding: 20px;
  background: var(--primary-white);
  border: 1px solid var(--other-dark-20);
  border-radius: 24px;

  &.error {
    border-color: var(--system-error);
  }
}

.required_star {
  color: var(--system-error);
  font-size: 16px;
  line-height: 1;
}

.label_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.label {
  display: block;
  margin: 0 0 6px 20px;
}

.messageBlock {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.filesBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  svg {
    path {
      fill: var(--transparent-color);
    }
  }
  &.notEmpty {
    svg {
      path {
        fill: var(--primary-dark);
      }
    }
  }
}

.textarea {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  resize: none;
  outline: none;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 113%;
  max-height: 200px;
  color: var(--primary-dark);
  overflow: hidden;
  overflow-y: auto;
  padding: unset;
  padding-right: 5px;

  &::placeholder {
    font-size: 16px;
    opacity: 0.4;
  }

  &:disabled {
    color: var(--transparent-color);
  }
}

.filesInfo {
  display: flex;
  flex-direction: column;
  color: var(--transparent-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  margin-left: 17.75px;
}

.fileItem {
  width: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--primary-dark);
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  &.bigfile {
    color: var(--system-error);
  }
  svg {
    width: 40px;
    height: 40px;
  }
}

.fileItemName {
  @include mixin.truncate-text(1);
  width: 100%;
  font-size: 12px;
}

.tooltip {
  max-width: 300px;
  word-break: break-all;
  text-align: center;
}

.fileIcon {
  color: var(--primary-dark);
}

.fileDeleteItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 24px;
  background-color: var(--primary-dark);
  position: relative;
  left: -8px;
  top: -8px;
  svg {
    width: 8px;
    height: 8px;
    color: var(--primary-white);
  }

  .bigfile & {
    background-color: var(--system-error);
  }
}

.filesContainer {
  padding-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .fileItem {
    margin-top: 0px;
  }
  .fileError {
    padding: 6px 0;
  }
}

.img_thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 12px;
}
