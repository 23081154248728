@import '@scss/placeholders.scss';
@import '@scss/typography.scss';

.field_wrapper {
  width: 100%;
  position: relative;
}

.label {
  display: block;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.input_wrapper {
  width: 100%;
  position: relative;
  height: 48px;

  &.opened {
    z-index: 12;

    .input {
      border-color: var(--primary-dark);
    }
  }

  &:hover {
    .shevron_icon {
      stroke: var(--primary-pink);
    }
  }
}

.input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  justify-self: center;
  caret-color: transparent;
  flex-direction: column;
  cursor: pointer;

  &.disabled {
    color: var(--transparent-color);
    cursor: initial;
  }

  > span {
    max-width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;

    @extend .body_m;

    font-weight: 300;
    line-height: 113%; /* 18.08px */
    letter-spacing: -0.64px;

    b {
      margin-left: 8px;
      color: var(--other-dark-50);
    }

    &.selected_with_icon {
      width: 100%;
      max-width: calc(100% - 30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        color: var(--other-dark-50);
      }
    }
  }
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 13px !important;
  line-height: 1.2;
}

.required_star {
  color: #d93f21;
  font-size: 16px;
  line-height: 0.5;
}

.placeholder {
  color: #ccc7d2;
}

.additionalPlaceHolder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 70.9%;
  color: var(--transparent-color);
  margin-top: 5px;
}

.shevron_icon {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  stroke: var(--primary-dark);
  transform: translateY(-50%);
  transition: transform 0.15s;

  &.opened {
    transform: translateY(-50%) rotate(180deg);
  }

  &.disabled {
    opacity: 0.5;
  }
}

.dropdown {
  position: absolute;
  display: none;
  top: calc(100% - 40px);
  padding-top: 40px;
  width: 100%;
  height: fit-content;
  border-radius: 24px;
  z-index: 2;
  background-color: var(--primary-white);
  overflow: hidden;

  &.opened {
    display: block;
    z-index: 11;
  }

  &.scrollable::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 15px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-image: linear-gradient(
      0,
      var(--forms_bgc_2) 0%,
      rgba(255, 255, 255, 0.001%)
    );
    pointer-events: none;
  }

  &.purple_background {
    background: var(--primary-light-purple);
    border: 1px solid var(--other-dark-20);
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: fit-content;
  max-height: 180px;
  border-radius: 3px;
}

.list_item {
  cursor: pointer;
  align-items: flex-start;
  text-align: start;
  padding: 6px 20px;

  color: var(--main-text);
  svg {
    color: var(--other-dark-50);
  }
  &:hover {
    color: var(--primary-pink);
    svg {
      color: var(--primary-pink);
    }

    .price {
      color: var(--primary-pink);
    }

    .additionalInfo {
      color: var(--primary-pink);
    }
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.list_item_active {
  span,
  svg {
    color: var(--primary-pink);
  }
}

.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  @extend .body_s;
}

.price {
  @extend .body_s;
  color: var(--other-dark-50);
}

.additionalInfo {
  @extend .body_xs_s;
  color: var(--other-dark-50);
}

.field_bgc {
  background-color: var(--nearby-elements-bgc);
}

@media screen and (min-width: 768px) {
  .input {
    &.iconLeft {
      padding-left: 36px;
    }
    &.iconLeft {
      padding-right: 36px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .input_wrapper {
    height: 56px;
  }
}

.label__tooltip {
  margin-left: 5px;
  display: inline-block;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
}

.hint {
  max-width: 300px;
}
