@import '@scss/placeholders.scss';

.btn_save {
  @media (min-width: 500px) {
    width: 180px;
  }
}

.warning_text {
  color: var(--system-error);
}

.names_block {
  overflow: hidden;
  transition: height 150ms;
  font-size: 14px;
  color: var(--transparent-color);
  @extend %scrollbar;

  .name_item:not(:first-child) {
    opacity: 0;
  }

  &.opened {
    overflow: auto;

    .name_item {
      opacity: 1;
    }
  }
}

.name_item {
  display: inline-block;
}

.btn_more {
  color: var(--link-color);
  line-height: 1;
  font-size: 14px;
  border-bottom: 1px solid var(--link-color);
  cursor: pointer;
}
