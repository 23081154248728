.message {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding: 8px 0;
}

.close_btn:hover {
  color: var(--primary-pink);
}
