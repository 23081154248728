/** Mobile */
.headings_h1 {
  font-weight: 400;
  font-size: 32px;
}

.headings_h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 113%;
}

.body_xs {
  font-weight: 400;
  font-size: 12px;
}

.body_s {
  font-weight: 400;
  font-size: 14px;
}

.body_xs_s {
  font-weight: 400;
  font-size: 12px;
}

.body_s_light {
  font-weight: 300;
  font-size: 14px;
}

.body_s_medium {
  font-weight: 500;
  font-size: 14px;
}

.body_s_light-body_m_light {
  font-weight: 300;
  font-size: 14px;
}

.body_m,
.body_m_l {
  font-weight: 400;
  font-size: 16px;
}

.body_m_medium {
  font-weight: 500;
  font-size: 16px;
}

.body_m_light {
  font-weight: 300;
  font-size: 16px;
}

.body_s_m {
  font-weight: 400;
  font-size: 14px;
}

.body_s {
  font-weight: 400;
  font-size: 14px;
}

.text-dark-50 {
  color: var(--other-dark-50);
}

.other_uppercase_small {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.body_l_medium {
  font-weight: 500;
  font-size: 18px;
}

.body_l_regular {
  font-weight: 400;
  font-size: 18px;
}

.body_light_s_m {
  font-weight: 300;
  font-size: 14px;
}

.headings_h3 {
  font-weight: 500;
  font-size: 24px;
}

.headings_h3_s_m {
  font-weight: 500;
  font-size: 16px;
}

.headings_h4_medium {
  font-weight: 500;
  font-size: 20px;
}

.headings_h4_regular {
  font-weight: 400;
  font-size: 20px;
}

.other_btn_uppercase {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
}

/** Desktop */
@media screen and (min-width: 1024px) {
  // .headings_h1 {
  //   font-size: 42px;
  // }

  .headings_h2 {
    font-size: 30px;
  }

  .body_s_m {
    font-size: 16px;
  }

  .body_light_s_m {
    font-size: 16px;
  }

  .headings_h3_s_m {
    font-size: 24px;
  }

  .body_xs_s {
    font-size: 14px;
  }

  .body_s_light-body_m_light {
    font-size: 16px;
  }

  .body_m_l {
    font-size: 18px;
  }
}
