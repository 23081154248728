@import '@scss/placeholders.scss';

.form {
  display: grid;
  gap: 20px;

  &.hidden {
    opacity: 0;
  }
}

.password_wrapper {
  display: grid;
  gap: 20px;
}

.submit_btn {
  width: 100%;
}
// ADAPTIVE

@media screen and (min-width: 768px) {
  .form {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .password_wrapper {
    grid-column: 1/3;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding-top: unset;
  }
}
