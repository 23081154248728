@use '@scss/media.scss' as media;

.messagesBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 12px;
  padding-top: 50px;
  gap: 16px;
  flex-grow: 1;
  padding-right: 16px;
  margin-right: -11px;
  @include media.minw-768 {
    padding-right: 24px;
    margin-right: -24px;
  }
  @include media.minw-1024 {
    padding-top: 150px;
  }
  &.smooth {
    scroll-behavior: smooth;
  }
}

.archiveTicket {
  width: 100%;
  background: #fff2f2;
  padding: 20px 24px;
  border-radius: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: var(--system-error);
  margin: 25px 0px;
  user-select: text;
}

.go_down {
  position: sticky;
  top: calc(100% - 60px);
  left: 100%;
  z-index: 1;
  &:hover {
    svg {
      stroke: var(--primary-white);
    }
  }
  &.has_new {
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: var(--accent-gradient);
    }
  }
}
