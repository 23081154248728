.ownerForm {
  height: 0px;
  overflow-y: hidden;
  transition: linear 0.2s;

  &.isOpen {
    height: fit-content;
    padding-bottom: 18px;
  }
}

.input {
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 2px;
  padding-bottom: 0;
}

.formFieldsBlock {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.category_title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130.5%;
  color: var(--main-text);

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.select {
  max-width: 100%;
  margin-top: 10px;
  margin-right: 0px;

  &.dateSelect {
    width: 195px;
    margin-right: 20px;
  }
}

.dates {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;

  color: var(--main-text);

  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
}

.formBlockTitle {
  margin: 20px 0 0 0;
}


.useFirstCheck {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-left: 5px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 70.9%;
    color: var(--main-text);
    margin-left: 18px;
  }
}

.profileWarn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--system-error);
}

.shevronIcon {
  margin-left: 16px;
  transform: rotate(0deg);
  transition: linear 0.1s;
  cursor: pointer;

  &.isOpen {
    transform: rotate(180deg);
  }

  &:hover {
    color: var(--primary-pink);
  }
}

.countrySelectItem {
  display: flex;
  align-items: center;

  img {
    margin-right: 13px;
  }
}

.description_block {
  display: grid;
  justify-items: start;
  gap: 8px;
}

.domain_name {
  color: var(--other-dark-50);
}

@media screen and (min-width: 768px) {
  .ownerForm {
    height: 0px;
    overflow-y: hidden;
    padding-left: 2px;
    transition: linear 0.2s;
  }

  .input {
    width: 47%;

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }

  .select {
    max-width: 47%;
    margin-top: 10px;
    margin-right: 15px;

    &.dateSelect {
      max-width: 220px;
      width: 220px;
      margin-right: 10px;
    }

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }

  .phoneInput {
    max-width: 47%;
    margin: 13px 15px 0 0;
  }

  .phoneInputLabel {
    margin: 0 0 4px 10px !important;
  }
}
