.modal {
  max-width: 405px;
}

.description_block,
.formFieldsBlock {
  display: grid;
  justify-items: start;
  gap: 8px;
}

.domain_name {
  color: var(--other-dark-50);
}

.warning_text {
  font-size: 14px;
  color: var(--red-text);
  margin-bottom: 14px;
}

.hidden_area {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
  margin-top: 8px;
}

.item {
  color: var(--transparent-color);
  font-size: 14px;
}

.services_names_wrapper {
  display: inline;
  transition: max-height 1s;

  &.active {
    display: block;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(161, 161, 161, 23%);
    }

    &::-webkit-scrollbar-thumb {
      background: #e72690;
    }

    max-height: 75px;
    overflow-x: hidden;
  }
}

.infoBtn {
  position: absolute;
  margin-left: auto;
  top: 35%;
  right: 0px;
  z-index: 2;
  padding: 0 12px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        opacity: 1;
        fill: url(#gradient);
      }
    }
    .descriptionBlock {
      opacity: 1;
      height: auto;
      overflow: auto;
      padding: 15px;
    }
  }
}

.descriptionBlock {
  position: absolute;
  opacity: 0;
  height: 0;
  overflow: hidden;
  box-shadow: var(--burger-menu-elem-box-shadow);
  background-color: var(--forms_bgc_4);
  padding: 0;
  top: 90%;
  right: 0;
  width: 232px;
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.5%;
  color: var(--main-text);
  text-align: start;
  transition: 0.05s linear;
  &::after {
    content: '';
    position: absolute;
    left: 89%;
    bottom: 99%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--forms_bgc_4);
    clear: both;
  }
}

.addNs {
  display: flex;
  align-items: center;
  color: var(--main-text);
  margin-top: 13.54px;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  span:first-child {
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 70.9%;
  }

  span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 70.9%;
    text-decoration-line: underline;
    margin-left: 10px;
  }
}
