.radio_container {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 8px;
}

.radio_outer_circle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;

  background-color: var(--primary-white);
  border: 1px solid var(--other-dark-20);
  border-radius: 50%;

  &.selected {
    background-color: var(--primary-pink);
    border: unset;
  }
}

.icon {
  color: var(--primary-white);
}

.radio_inner_circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--accent-gradient);
  transition: all 0.1s linear;
}

.label_text {
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: var(--primary-dark);
}
