.btn {
  padding: 10px 20px;
  border-width: 0;
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.block {
  width: auto;
}
.small {
  max-width: 220px;
}
.medium {
  max-width: 230px;
}
.large {
  max-width: 310px;
}

.loader {
  margin: 0;
  --size: 25px;
}
